import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
    InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import userSlice from "../../../store/slices/user";
import FileUploader from "../../public_components/fileUploader/FileUploader";
import item from "../../../store/slices/item";
import { Item } from "item";

const AddVendorInventoryForm = ({ inventoryItems, addInventory }: any) => {
    // Hooks
    const dispatch = useAppDispatch();
    const users = useAppSelector(userSlice.selectCurrentUser);
    const masterItems = useAppSelector(item.selectMasterItemList);
    const navigate = useNavigate();

    const [userType, setUserType] = useState("");
    const [items, setItems] = useState<Item[]>([]);
    const [selectorItems, setSelectorItems] = useState<Item[]>([]);

    useEffect(() => {
        console.log("Use Effect User Admin: ", users);
        setUserType(users.role);
    }, [users]);
    useEffect(() => {
        console.log("Use Effect User Admin: ", users);
        setItems(masterItems);
    }, [masterItems]);
    useEffect(() => {
        inventoryItems(selectorItems);
    }, [selectorItems]);

    const handleCheckItem = async (item: Item, value: boolean) => {
        if (value === true) {
            setSelectorItems([...selectorItems, item]);
        } else {
            const removeItem = selectorItems.filter((itemFromArray) => {
                return itemFromArray.id !== item.id;
            });
            setSelectorItems(removeItem);
        }
    };

    const itemBlock = () => {
        let content = [];
        for (const item of items) {
            content.push(
                <Grid item container key={item.id} spacing={0}>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    onChange={(event) => {
                                        handleCheckItem(
                                            item,
                                            event.target.checked
                                        );
                                    }}
                                />
                            }
                            label={
                                <Typography variant="h6" color={"secondary"}>
                                    {item.display_name.en}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="standard"
                            type="number"
                            value={item.unit_price.retail_price}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {item.unit_size}
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                    </Grid>
                </Grid>
            );
        }
        return content;
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& > :not(style)": { m: 1, width: "25ch" },
            }}
        >
            <Button
                variant="contained"
                type="submit"
                color="secondary"
                onClick={addInventory}
            >
                Add Inventory
            </Button>
            <Grid container spacing={0} pt={1}>
                {itemBlock()}
            </Grid>
        </Box>
    );
};

export default AddVendorInventoryForm;
