import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user/userSlice";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import item from "./slices/item/itemSlice";
import cart from "./slices/cart/cartSlice";
import order from "./slices/order/orderSlice";
import config from "./slices/config/configSlice";
import crate from "./slices/crate/crateSlice";

const persistConfig = {
    key: "root",
    storage,
};
const persistCartConfig = {
    key: "cart",
    storage: storageSession,
};
const persistOrderConfig = {
    key: "order",
    storage: storageSession,
};
const persistConfigConfig = {
    key: "config",
    storage: storageSession,
};

// Persistent Reducers
const configPersistedReducer = persistReducer(persistConfigConfig, config);
const userPersistedReducer = persistReducer(persistConfig, user);
const itemPersistedReducer = persistReducer(persistConfig, item);
const cartPersistedReducer = persistReducer(persistCartConfig, cart);
const orderPersistedReducer = persistReducer(persistOrderConfig, order);
const cratePersistedReducer = persistReducer(persistOrderConfig, crate);

const store = configureStore({
    reducer: {
        config: configPersistedReducer,
        user: userPersistedReducer,
        item: itemPersistedReducer,
        cart: cartPersistedReducer,
        order: orderPersistedReducer,
        crate: cratePersistedReducer,
    },
    devTools: process.env.REACT_APP_ENV_TYPE !== "production",

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
export default store;
