import "./CommingSoon.css";
import { Box, Divider, Grid, Typography } from "@mui/material";
import logo from "../../../assets/logo_V1_512.png";
import { useAppSelector } from "../../../hooks";
import userSlice from "../../../store/slices/user";
const NotFound = () => {
    const user = useAppSelector(userSlice.selectCurrentUser);
    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <div id="mainComingSoon">
                <div className="cms">
                    <img width={150} src={logo} style={{ paddingTop: 5 }} />
                    <h1>Coming Soon</h1>
                    <Grid container p={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6" textAlign={"left"} mb={3}>
                                Greetings {user?.display_name},
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" textAlign={"left"} mb={3}>
                                We are committed to delivering the best of the
                                best fresh produce to your doorstep.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{
                            color: "white",
                            paddingTop: 0,
                            marginTop: 0,
                            paddingBottom: 0,
                            marginBottom: 0,
                        }}
                    >
                        OR
                    </Divider>
                    <Grid container p={6}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                justifyContent={"normal"}
                                mb={3}
                                fontWeight={"bold"}
                            >
                                Are you a vendor in need of fresh produce?
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>{" "}
        </Box>
    );
};

export default NotFound;
