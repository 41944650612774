import { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Fab,
    Autocomplete,
    TextField,
    Avatar,
    Button,
} from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";
import {
    AddCircle,
    Pause,
    PlayArrow,
    QrCodeScanner,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import crateSlice from "../../store/slices/crate";
import _ from "lodash";
import { crateCodeToPrefixNumber } from "../../utils/crateUtils";

const RegisterCrate = () => {
    const navigate = useNavigate();
    const myNav = (path: string) => {
        navigate(path);
    };
    const dispatch = useAppDispatch();
    const crateList = useAppSelector(crateSlice.selectMasterCrateList);
    const [result, setResult] = useState("");
    const [cratePrefix, setCratePrefix] = useState("");
    const [crateType, setCrateType] = useState<any>(null);
    const [crateNumber, setCrateNumber] = useState<string>("");
    const [scanning, setScanning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [crateOptions, setCrateOptions] = useState<any[]>([]);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
        dispatch(crateSlice.subscribeCrateList());
        setIsPaused(false);
    }, []);

    // Unmount
    useEffect(() => {
        return () => {
            setIsPaused(true);
        };
    }, []);
    useEffect(() => {
        if (!crateList) {
            return;
        }
        const crateSearchOptions = crateList.map((crate: any) => {
            const crateWithLabel = {
                ...crate,
                label: crate?.crate_prefix,
            };
            return crateWithLabel;
        });
        if (!crateSearchOptions) {
            return;
        }
        setCrateOptions(crateSearchOptions);
    }, [crateList]);
    useEffect(() => {
        if (result === "") {
            return;
        }
        const { prefix, number } = crateCodeToPrefixNumber(result);
        setCratePrefix(prefix);
        setCrateNumber(number);
    }, [result]);

    useEffect(() => {
        if (!cratePrefix) {
            return;
        }
        const filteredCrates = _.filter(
            crateOptions,
            (crate) => crate.crate_prefix === cratePrefix
        );
        if (filteredCrates.length > 0) {
            setCrateType(filteredCrates[0]);
        } else {
            setCrateType(null);
        }
    }, [cratePrefix]);

    const handleScan = (data: any) => {
        if (data) {
            setResult(data[0].rawValue);
            setIsPaused(true);
        }
    };

    function handleSaveCrate(): void {
        const payload = {
            crateType,
            crateQRCode: result,
            crateNumber,
            cratePrefix,
        };
        dispatch(crateSlice.addCrate(payload));
    }

    return (
        <Box
            sx={{
                minHeight: "90vh",
                backgroundColor: "background.paper",
            }}
        >
            <Grid container p={5}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        color="secondary"
                        textAlign={"center"}
                    >
                        SCAN CRATE
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    {scanning ? (
                        <Box
                            sx={{
                                width: "100%",
                                backgroundColor: "background.paper",
                                minHeight: "100vh",
                            }}
                        >
                            <Scanner
                                onScan={handleScan}
                                allowMultiple
                                paused={isPaused}
                                children={
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} textAlign={"center"}>
                                            <Fab
                                                onClick={() =>
                                                    setIsPaused(!isPaused)
                                                }
                                                sx={{ marginBottom: 2 }}
                                            >
                                                {isPaused ? (
                                                    <PlayArrow />
                                                ) : (
                                                    <Pause />
                                                )}
                                            </Fab>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {result && (
                                                <Typography
                                                    variant="body1"
                                                    color="secondary"
                                                >
                                                    Scanned Result: {result}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {result && (
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    value={cratePrefix}
                                                    options={crateOptions}
                                                    sx={{ width: 300 }}
                                                    onChange={(
                                                        event,
                                                        value: any
                                                    ) =>
                                                        setCratePrefix(
                                                            value?.crate_prefix
                                                        )
                                                    }
                                                    onInputChange={(
                                                        event,
                                                        newInputValue,
                                                        reason
                                                    ) =>
                                                        setCratePrefix(
                                                            newInputValue
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Crate Type"
                                                        />
                                                    )}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => {
                                                        const {
                                                            label,
                                                            id,
                                                            image,
                                                        } = option;
                                                        return (
                                                            <Grid
                                                                container
                                                                key={id}
                                                            >
                                                                <Grid xs={2}>
                                                                    <Avatar
                                                                        src={
                                                                            image
                                                                        }
                                                                        variant="rounded"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={10}>
                                                                    <span
                                                                        {...props}
                                                                    >
                                                                        {label}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        {crateType && (
                                            <Grid container item xs={12}>
                                                <Grid item xs={6}>
                                                    <Avatar
                                                        alt="Crate"
                                                        variant="rounded"
                                                        src={crateType?.image}
                                                        sx={{
                                                            width: 100,
                                                            height: 100,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid container item xs={6}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"secondary"}
                                                        >
                                                            Vendor:{" "}
                                                            {
                                                                crateType
                                                                    ?.vendor
                                                                    ?.name
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"secondary"}
                                                        >
                                                            Size:{" "}
                                                            {
                                                                crateType?.size
                                                                    ?.name
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"secondary"}
                                                        >
                                                            Color:{" "}
                                                            {
                                                                crateType?.color
                                                                    ?.name
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"secondary"}
                                                        >
                                                            Crate Number:{" "}
                                                            {crateNumber}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    pt={3}
                                                    pb={3}
                                                    mb={9}
                                                >
                                                    <Button
                                                        onClick={
                                                            handleSaveCrate
                                                        }
                                                        variant="contained"
                                                        color="error"
                                                        fullWidth
                                                    >
                                                        Add Crate
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                }
                            />
                        </Box>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Fab
                                    sx={{ marginBottom: 2 }}
                                    size="large"
                                    variant="extended"
                                    onClick={() => myNav("test")}
                                >
                                    <AddCircle />
                                    Add
                                </Fab>
                            </Grid>
                            <Grid item xs={12}>
                                <Fab
                                    onClick={() => setScanning(true)}
                                    sx={{ marginBottom: 2 }}
                                    size="large"
                                    variant="extended"
                                >
                                    <QrCodeScanner /> Scan
                                </Fab>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default RegisterCrate;
