import ConstructionIcon from "@mui/icons-material/Construction";

import { Box, Button } from "@mui/material";
import "./UnderConstruction.css";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import { useEffect } from "react";

const UnderConstruction = () => {
    const navigate = useNavigate();
    const user = useAppSelector(userSlice.selectCurrentUser);
    const isUserVerified = useAppSelector(userSlice.selectIsUserVerified);

    useEffect(() => {
        window.sessionStorage.setItem("vendor_inventory", "1");
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "100vh",
                height: "100%",
            }}
        >
            <div id="main404">
                <div className="ucn">
                    <h1>
                        <ConstructionIcon
                            sx={{ fontSize: 100 }}
                            color="secondary"
                        />
                    </h1>
                    <h2>Underconstruction</h2>
                    <h3>Inventory</h3>
                    <Button
                        variant={"contained"}
                        sx={{ backgroundColor: "#769365" }}
                        onClick={() => {
                            isUserVerified
                                ? navigate(`/${user?.role}`)
                                : navigate("/sign-in");
                        }}
                    >
                        Go Back
                    </Button>
                </div>
            </div>{" "}
        </Box>
    );
};

export default UnderConstruction;
