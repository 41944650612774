import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CoordinationManagement = () => {
    const navigate = useNavigate();
    const myNav = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "1");
        window.sessionStorage.setItem("profile", "0");
    }, []);
    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    padding: 2,
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        width: 100,
                        height: 100,
                        borderRadius: 3,
                        borderColor: "secondary.main",
                        border: 2,
                    }}
                    onClick={() => myNav("add-master-item")}
                >
                    Item Prices
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        width: 100,
                        height: 100,
                        borderRadius: 3,
                        borderColor: "secondary.main",
                        border: 2,
                    }}
                    onClick={() => myNav("receive-crate")}
                >
                    Receive Crate
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        width: 100,
                        height: 100,
                        borderRadius: 3,
                        borderColor: "secondary.main",
                        border: 2,
                    }}
                    onClick={() => myNav("send-crate")}
                >
                    Send Crate
                </Button>
            </Box>
        </Box>
    );
};

export default CoordinationManagement;
