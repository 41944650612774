import { toast } from "react-toastify";

export function customToast(text: string, type: string) {
    const options: any = {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
    };
    switch (type) {
        case "info":
            toast.info(text, options);
            break;
        case "success":
            toast.success(text, options);
            break;
        case "error":
            toast.error(text, options);
            break;
        case "warning":
            toast.warning(text, options);
            break;
        default:
            toast.info(text, options);
            break;
    }
}
