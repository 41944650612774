import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DriverRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/driver/orders");
    });
    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    minHeight: "90vh",
                    height: "100%",
                }}
            >
                <CircularProgress />
            </Box>
        </Box>
    );
};

export default DriverRedirect;
