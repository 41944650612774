import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../hooks";
import InputAdornment from "@mui/material/InputAdornment";
import cartSlice from "../../store/slices/cart";
import { useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PaymentIcon from "@mui/icons-material/Payment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import OrderSummary from "./OrderSummary";
import Payment from "./Payment";

import done_logo from "../../assets/images/conf.png";
import { UtilContext } from "../../provider/UtilContext";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg,#ff9800 0%,#263238 50%,#c66900 100%)",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg,#ff9800 0%,#263238 50%,#c66900 100%)",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.primary.light,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.primary.light,
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage:
            "linear-gradient( 136deg, #c66900 0%,#263238 50%, #ff9800 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage:
            "linear-gradient( 136deg, #c66900 0%,#263238 50%, #ff9800 100%)",
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <ShoppingCartIcon sx={{ width: 20, height: 20 }} />,
        2: <AnalyticsIcon sx={{ width: 20, height: 20 }} />,
        3: <PaymentIcon sx={{ width: 20, height: 20 }} />,
        4: <AssignmentTurnedInIcon sx={{ width: 20, height: 20 }} />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = [
    "Order Cart",
    "Order Details",
    "Payment Details",
    "Order Conformation",
];

const CartItem = ({ item, onRemove, handleQuantity }: any) => {
    const { name, image, price, quantity } = item;

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                }}
            >
                <Grid item xs={2}>
                    <IconButton color="error" onClick={() => onRemove(item)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={4}>
                    <CardMedia component="img" image={image} alt={name} />
                </Grid>
                <Grid item xs={6}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {name}
                        </Typography>
                        <Typography
                            sx={{ pb: 2 }}
                            variant="caption"
                            color="text.secondary"
                        >
                            Unit Price: Rs {parseFloat(price).toFixed(2)}/{" "}
                            {item.unit}
                        </Typography>
                        <TextField
                            sx={{ width: { xs: "70%", md: "50%" } }}
                            type="number"
                            color="info"
                            size="small"
                            focused
                            value={item.quantity}
                            onChange={(event) => {
                                handleQuantity(item, event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {item.unit}
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Rs {(price * quantity).toFixed(2)}</strong>
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

// Todo: Add price Update on load
const RetailCart = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(cartSlice.selectCart);
    const [items, setItems] = useState<any[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const { flag, setFlag } = React.useContext(UtilContext);

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        let total = 0;
        const itemsArray = cartItems.map((item: any) => {
            const cartObject = { ...item.item, quantity: item.cartQuantity };
            total += item.item.price * item.cartQuantity;
            return cartObject;
        });
        setItems(itemsArray);
        setTotalPrice(total);
    }, [cartItems]);
    function onCheckout(): void {
        navigate("/vendor/order-summary");
    }
    function onRemove(item: any): void {
        const updatedCart = cartItems.filter((curr: any) => {
            return curr.item.id !== item.id;
        });

        dispatch(cartSlice.setCart(updatedCart));
    }
    function handleQuantity(item: any, value: any): void {
        if (value < 0) return;

        const cartItemsCopy = [...cartItems];
        const updatedItemIndex = cartItemsCopy.findIndex((curr: any) => {
            return curr.item.id === item.id;
        });

        const currentItem = cartItemsCopy[updatedItemIndex];
        const updatedItem = { item: currentItem.item, cartQuantity: +value };
        const updatedCart: any = [];
        for (const currentCartItem of cartItemsCopy) {
            if (currentCartItem.item.id === item.id) {
                updatedCart.push(updatedItem);
            } else {
                updatedCart.push(currentCartItem);
            }
        }
        dispatch(cartSlice.setCart(updatedCart));
    }

    return (
        <>
            <Box
                sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    minHeight: "90vh",
                    height: "100%",
                }}
            >
                {items.length === 0 && activeStep !== 3 ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1" color={"text.primary"}>
                            No items in cart!
                        </Typography>
                        <Button
                            color="success"
                            variant="contained"
                            onClick={() => {
                                setFlag(!flag);
                                navigate("/retail-buyer/market");
                            }}
                            sx={{ mt: 2 }}
                        >
                            Continue Shopping
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ width: "100%" }}>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            connector={<ColorlibConnector />}
                        >
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel
                                            StepIconComponent={ColorlibStepIcon}
                                        >
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === 0 && (
                            <React.Fragment>
                                <Box>
                                    <Card>
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    color={"info"}
                                                >
                                                    Cart Summary
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    color={"secondary"}
                                                >
                                                    Number of Items:{" "}
                                                    <strong>
                                                        {items.length}
                                                    </strong>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    color={"secondary"}
                                                >
                                                    Cart Total:{" "}
                                                    <strong>
                                                        Rs{" "}
                                                        {totalPrice.toFixed(2)}
                                                    </strong>
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Button
                                                    color="success"
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    disabled={
                                                        cartItems.length === 0
                                                    }
                                                    sx={{
                                                        height: "70px",
                                                    }}
                                                >
                                                    <strong>Checkout</strong>
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            m: 0,
                                        }}
                                    >
                                        {items.length === 0 ? (
                                            <Typography
                                                variant="h6"
                                                color={"secondary"}
                                            >
                                                No items in cart !
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                        {items.map((item: any) => (
                                            <Box key={item.id}>
                                                <CartItem
                                                    item={item}
                                                    handleQuantity={
                                                        handleQuantity
                                                    }
                                                    onRemove={() => {
                                                        onRemove(item);
                                                    }}
                                                />
                                                <Box sx={{ p: 1 }} />
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </React.Fragment>
                        )}
                        {activeStep === 1 && (
                            <React.Fragment>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 2,
                                        pb: 2,
                                    }}
                                >
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={handleNext}
                                    >
                                        Payment
                                    </Button>
                                </Box>

                                <OrderSummary />
                            </React.Fragment>
                        )}
                        {activeStep === 2 && (
                            <React.Fragment>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 2,
                                        pb: 2,
                                    }}
                                >
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleBack}
                                        fullWidth
                                    >
                                        Back
                                    </Button>
                                </Box>
                                <Payment handlePlaceOrder={handleNext} />
                            </React.Fragment>
                        )}
                        {activeStep === 3 && (
                            <React.Fragment>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        pt: 2,
                                        pb: 2,
                                    }}
                                >
                                    <Box>
                                        <img
                                            src={done_logo}
                                            alt="logo"
                                            style={{ width: "100%" }}
                                        />
                                    </Box>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            setFlag(!flag);
                                            navigate("/retal-buyer/market");
                                        }}
                                        fullWidth
                                    >
                                        Back to Home
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};

export default RetailCart;
