import { useLocation } from "react-router-dom";
import Receipt from "../public_components/Printables/Receipt";
import { useEffect, useState } from "react";

const DriverOrderReceipt = () => {
    const location = useLocation();
    const [order, setOrder] = useState();

    useEffect(() => {
        const orderObject = JSON.parse(location?.state?.order);
        console.log(orderObject);
        setOrder(orderObject);
    }, [location]);
    if (!order) {
        return <></>;
    } else {
        return <Receipt orderObject={order} />;
    }
};

export default DriverOrderReceipt;
