import React, { useEffect, useRef } from "react";
import {
    Box,
    TextField,
    Button,
    Link,
    Typography,
    InputAdornment,
    IconButton,
    Divider,
    CircularProgress,
} from "@mui/material";
import Logo from "../../assets/logo_V1_512.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import configSlice from "../../store/slices/config";
import GoogleButton from "../public_components/GoogleButton/GoogleButton";
import { customToast } from "../public_components/Toast/CustomToast";

const AppName = "FarmLink";
const verifyLinkTimeoutSeconds = 60;

function ExpireLinkButton({ handleVerifyLink, timer }) {
    if (timer > 0) {
        return (
            <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                style={{
                    paddingBottom: 5,
                    marginBottom: 5,
                }}
            >
                Retry in {timer} seconds
            </Button>
        );
    } else {
        return (
            <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleVerifyLink}
                style={{
                    paddingBottom: 5,
                    marginBottom: 5,
                }}
            >
                Resend Verification Link
            </Button>
        );
    }
}

export default function Signin() {
    const dispatch = useAppDispatch();
    const verifyLinkTimestamp = useAppSelector(
        configSlice.selectVerifyLinkTime
    );
    // const isInProvider = useSelector(configSlice.selectIsLoadingProvider);
    const isInProvider = useAppSelector(configSlice.selectIsLoadingProvider);
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingx, setIsLoadingx] = React.useState(false);
    const [sec, setSec] = React.useState(0);
    const intervalRef = useRef();

    const [isResendButtonEnabled, setIsResendButtonEnabled] =
        React.useState(false);

    const [timer, setTimer] = React.useState(Date.now() - verifyLinkTimestamp);
    useEffect(() => {
        if (verifyLinkTimestamp > Date.now()) {
            intervalRef.current = setInterval(function () {
                const distance = verifyLinkTimestamp - Date.now();
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                if (seconds >= 0) {
                    setTimer(seconds);
                }
                setSec(seconds);
            }, 1000);
            return () => clearInterval(intervalRef.current);
        }
    }, [verifyLinkTimestamp]);

    useEffect(() => {
        if (verifyLinkTimestamp && verifyLinkTimestamp < Date.now()) {
            clearInterval(intervalRef.current);
        }
    }, [sec, verifyLinkTimestamp]);
    useEffect(() => {
        if (verifyLinkTimestamp - Date.now() < 0) {
            setIsResendButtonEnabled(false);
        } else {
            setIsResendButtonEnabled(true);
        }
    }, [timer]);
    useEffect(() => {
        setIsLoadingx(isInProvider);
    }, [isInProvider]);

    useEffect(() => {
        const getAuthenticatedUser = async () => {
            setIsLoading(true);
            const result = await dispatch(userSlice.getRedirectedUserAuth());
            const userCred = result.payload;
            if (isLoadingx) {
                return;
            }
            if (userCred) {
                setIsAuthenticated(true);
                navigate("/user");
            } else {
                setIsAuthenticated(false);
                setIsLoading(false);
            }
        };
        getAuthenticatedUser();
    }, [isLoadingx]);

    const handleGoogleSignup = async () => {
        if (isAuthenticated) {
            await dispatch(userSlice.logoutUser());
        } else {
            window.localStorage.setItem("user_logged", "1");
            dispatch(userSlice.authenticateUserWithGoogleProvider());
            navigate("/user");
        }
    };

    const handleLogin = async () => {
        setIsLoading(true);
        const userData = {
            username,
            password,
        };
        const result = await dispatch(userSlice.loginUser(userData));
        if (!result.payload.isError) {
            setIsAuthenticated(true);
            navigate(`/user`);
        } else {
            displayToast(result?.payload);
            setIsResendButtonEnabled(false);
            if (result?.payload?.isUserVerified === false) {
                setTimer(0);
                setSec(0);
                setIsResendButtonEnabled(true);
            }
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    };

    const displayToast = (payload) => {
        if (payload?.isError == true) {
            customToast(payload?.message, "error");
        } else {
            customToast(payload?.message, "success");
        }
    };

    const handleVerifyLink = async () => {
        if (verifyLinkTimestamp && verifyLinkTimestamp > Date.now()) {
            return;
        } else {
            const endTime = Date.now() + verifyLinkTimeoutSeconds * 1000;
            dispatch(configSlice.setVerifyLinkTime(endTime));
            const result = await dispatch(userSlice.sendUserVerifyLink());
            displayToast(result?.payload);
        }
    };
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const InputBlock = () => {
        if (isLoading) {
            return <CircularProgress color="secondary" />;
        } else {
            return (
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: "background.default",
                        borderRadius: "20px",
                        mb: 5,
                    }}
                >
                    {/* <div
                        style={{
                            position: "relative",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <GoogleButton onClick={handleGoogleSignup} />
                    </div>
                    <Divider
                        style={{
                            color: "white",
                            paddingTop: 5,
                            marginTop: 5,
                            paddingBottom: 5,
                            marginBottom: 5,
                        }}
                    >
                        OR
                    </Divider> */}
                    <form>
                        {isResendButtonEnabled ? (
                            <ExpireLinkButton
                                handleVerifyLink={handleVerifyLink}
                                timer={timer}
                            />
                        ) : null}
                        <TextField
                            sx={{ mt: 2 }}
                            required
                            label="Email address"
                            type="email"
                            value={username}
                            onChange={handleUsernameChange}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            sx={{ mt: 1, mb: 1 }}
                            required
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            color="secondary"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={password}
                            onChange={handlePasswordChange}
                            variant="outlined"
                            fullWidth
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleLogin}
                            sx={{ mb: 3, mt: 3 }}
                        >
                            Login
                        </Button>
                        <Typography color={"white"}>
                            Don't have an account?{" "}
                            <Link href="/sign-up" color="secondary">
                                Register here
                            </Link>
                        </Typography>
                    </form>
                </Box>
            );
        }
    };

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "background.paper",
                height: "90vh",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: ["40%", "10%"],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mx: "auto",
                    mb: 5,
                }}
            >
                <img src={Logo} alt="logo" style={{ width: "100%" }} />
            </Box>
            {InputBlock()}

            <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "primary.dark",
                    width: "100%",
                    height: 80,
                    borderRadius: "20px 20px 0 0",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{ color: "#ffffff", fontSize: ["none", 12] }}
                >
                    © {new Date().getFullYear()} {AppName}. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
}
