import {
    Box,
    Button,
    CircularProgress,
    Fab,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { customToast } from "../public_components/Toast/CustomToast";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";
import { useLocation } from "react-router-dom";
import { Scanner } from "@yudiel/react-qr-scanner";
import { Add, Delete, Pause, PlayArrow, Upload } from "@mui/icons-material";
import { crateCodeToPrefixNumber } from "../../utils/crateUtils";
import crateSlice from "../../store/slices/crate";

const WorkerTagCrate = () => {
    const { state } = useLocation();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [crateIds, setCrateIds] = useState<any[]>([]);
    const [isPaused, setIsPaused] = useState(false);
    const [crateNumber, setCrateNumber] = useState<string>("");
    const [result, setResult] = useState("");

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
        setIsLoading(false);
    }, [state]);

    // Unmount
    useEffect(() => {
        return () => {
            setIsPaused(true);
        };
    }, []);

    useEffect(() => {
        if (result === "") {
            return;
        }
        const { number } = crateCodeToPrefixNumber(result);
        setCrateNumber(number);
    }, [result]);
    useEffect(() => {
        setIsPaused(false);
    }, [crateIds]);

    const handleScan = (data: any) => {
        if (data) {
            setResult(data[0].rawValue);
            setIsPaused(true);
        }
    };

    function handleAddCrate(): void {
        if (
            !result ||
            !crateNumber ||
            result.trim() === "" ||
            crateNumber.trim() === ""
        ) {
            // Assuming you have a function to show error messages to the user
            customToast("Cannot add crate with blank code or number.", "error");
            return;
        }
        const newCrate = { code: result, number: crateNumber };

        setCrateIds((prevCrateIds) => {
            // Check if the code already exists in the array
            const codeExists = prevCrateIds.some(
                (crate) => crate.code === newCrate.code
            );

            if (codeExists) {
                // If the code already exists, don't add it
                customToast("This crate code already exists.", "info");
                return prevCrateIds;
            } else {
                // If the code doesn't exist, add the new crate
                return [...prevCrateIds, newCrate];
            }
        });
    }

    function handleRemoveCrate(): void {
        setCrateIds([]);
    }

    async function uploadCrates() {
        const uploadPayload = {
            orderId: state,
            crateList: crateIds,
        };
        if (uploadPayload.crateList.length !== 0) {
            const result: any = await dispatch(
                crateSlice.tagOrderCrate(uploadPayload)
            );

            if (result?.payload?.isError) {
                customToast("Error Occured", "error");
                return;
            }
            customToast(result?.payload?.message, "success");
            setCrateIds([]);
            setResult("");
            setCrateNumber("");
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                Tag Crates
            </Typography>

            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                >
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            m: 2,
                        }}
                    >
                        <TextField
                            fullWidth
                            value={state || 0}
                            label="Order Id"
                            variant="outlined"
                            color="info"
                        />
                    </Box>

                    <>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"flex-start"}
                            sx={{
                                p: 2,
                            }}
                            spacing={1}
                        >
                            <Grid item xs={6}>
                                <Scanner
                                    onScan={handleScan}
                                    allowMultiple
                                    paused={isPaused}
                                    children={
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                textAlign={"center"}
                                            >
                                                <Fab
                                                    onClick={() =>
                                                        setIsPaused(!isPaused)
                                                    }
                                                    sx={{ marginBottom: 2 }}
                                                >
                                                    {isPaused ? (
                                                        <PlayArrow />
                                                    ) : (
                                                        <Pause />
                                                    )}
                                                </Fab>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {result && (
                                                    <Typography
                                                        variant="caption"
                                                        color="secondary"
                                                    >
                                                        Scanned Result: {result}
                                                    </Typography>
                                                )}
                                            </Grid>

                                            {state && (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    spacing={0.5}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"secondary"}
                                                        >
                                                            Crate Number:{" "}
                                                            {crateNumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={
                                                                handleAddCrate
                                                            }
                                                            variant="contained"
                                                            color="secondary"
                                                            fullWidth
                                                        >
                                                            <Add />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={
                                                                handleRemoveCrate
                                                            }
                                                            variant="contained"
                                                            color="error"
                                                            fullWidth
                                                        >
                                                            <Delete />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={
                                                                uploadCrates
                                                            }
                                                            variant="contained"
                                                            color="success"
                                                            fullWidth
                                                        >
                                                            <Upload />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                overflow={"scroll"}
                                container
                                height={400}
                                direction="column"
                                border={1}
                            >
                                {crateIds.map((value: any) => {
                                    return (
                                        <Grid item>
                                            <Typography
                                                variant="caption"
                                                color={"secondary"}
                                            >
                                                {value.code}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </>
                </>
            )}
        </Box>
    );
};

export default WorkerTagCrate;
