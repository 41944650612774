const reducers = {
    incrementTest: (state: any) => {
        state.test += 1;
    },
    setMasterItemList: (state: any, action: any) => {
        state.masterItemList = action.payload;
    },
    setUserInventory: (state: any, action: any) => {
        state.userInventory = action.payload;
    },
    setUserInventoryItem: (state: any, action: any) => {
        state.userInventoryItem = action.payload;
    },
};

export default { ...reducers };
