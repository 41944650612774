import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import cartSlice from "../../store/slices/cart";
import { useNavigate } from "react-router-dom";
import userSlice from "../../store/slices/user";

const OrderItem = ({ item }: any) => {
    const { name, image, price, quantity, name_si } = item;

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    ml: 2,
                }}
            >
                <Grid item xs={4}>
                    <CardMedia component="img" image={image} alt={name} />
                </Grid>
                <Grid item xs={6}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {name_si}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Rs {(price * quantity)?.toFixed(2)}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            {quantity} {item?.unit}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

// Todo: Add price Update on load
const OrderSummary = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(cartSlice.selectCart);
    const user = useAppSelector(userSlice.selectCurrentUser);
    const [items, setItems] = useState<any[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        if (cartItems.length === 0) {
            navigate("/vendor/market");
        }
        let total = 0;
        const itemsArray = cartItems.map((item: any) => {
            const cartObject = { ...item.item, quantity: item.cartQuantity };
            total += Math.round(item.item.price * item.cartQuantity);
            return cartObject;
        });
        setItems(itemsArray);
        setTotalPrice(total);
    }, [cartItems]);

    return (
        <>
            <Card>
                <CardContent>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        Delivery Address
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        {user?.registered_location?.address}
                    </Typography>
                    {/* <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Street name
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Town
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            District
          </Typography> */}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    {/* <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Sub Total: Rs {totalPrice.toFixed(2)}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            5% Service Fee: Rs {((totalPrice * 5) / 100).toFixed(2)}
          </Typography> */}
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Net Amount:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                textAlign={"right"}
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Rs {totalPrice?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    m: 0,
                    pt: 2,
                }}
            >
                {items?.length === 0 ? (
                    <Typography variant="h6" color={"secondary"}>
                        No items in cart !
                    </Typography>
                ) : (
                    ""
                )}
                {items?.map((item: any) => (
                    <Box key={item.id}>
                        <OrderItem key={item.id} item={item} />
                        <Box sx={{ p: 1 }} />
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default OrderSummary;
