const selectors = {
    selectMasterCrateList: (state: { crate: any }) => {
        return state.crate.crateList;
    },
    selectCrateStatistics: (state: { crate: any }) => {
        return state.crate.crateStatistics;
    },
};

export default selectors;
