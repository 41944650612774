import { Route, Routes } from "react-router-dom";

import routeComponents from "./RouteComponents";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import Signin from "../components/public_user_components/SigninComponent";
import Drawer from "../components/public_components/Drawer";
import SignUp from "../components/public_user_components/SignupComponent";
import userSlice from "../store/slices/user";
import { UserType } from "../utils/userUtils";
import NotFound from "../components/public_components/NotFound/NotFound";
import LoginLanding from "../components/public_user_components/LoginLanding";
import configSlice from "../store/slices/config";

const publicRoutes = [
    { path: "/sign-in", component: <Signin /> },
    { path: "/sign-up", component: <SignUp /> },
    { path: "/user", component: <LoginLanding /> },
    { path: "", component: <LoginLanding /> },
];

const retailBuyerRoutes = [
    { path: "", component: routeComponents.comingSoon() },
    // { path: "market", component: routeComponents.retailBuyerHome() },
    // { path: "cart", component: routeComponents.retailBuyerCart() },
    // { path: "orders", component: routeComponents.retailBuyerOrders() },
    // { path: "orders/:id", component: routeComponents.retailBuyerOrder() },
];
const bulkBuyerRoutes = [
    { path: "", component: routeComponents.bulkBuyerHome() },
    { path: "market", component: routeComponents.bulkBuyerHome() },
    { path: "cart", component: routeComponents.bulkBuyerCart() },
    { path: "orders", component: routeComponents.bulkBuyerOrders() },
    { path: "orders/:id", component: routeComponents.bulkBuyerOrder() },
    { path: "profile", component: routeComponents.vendorProfile() },
];
const vendorRoutes = [
    { path: "", component: routeComponents.bulkBuyerHome() },
    { path: "market", component: routeComponents.bulkBuyerHome() },
    { path: "cart", component: routeComponents.bulkBuyerCart() },
    { path: "orders", component: routeComponents.bulkBuyerOrders() },
    { path: "orders/:id", component: routeComponents.bulkBuyerOrder() },
    {
        path: "inventory",
        component: routeComponents.vendorInventory(),
    },
    { path: "profile", component: routeComponents.vendorProfile() },
];
const driverRoutes = [
    { path: "", component: routeComponents.driverRedirect() },
    { path: "orders", component: routeComponents.driverOrders() },
    { path: "orders/:id", component: routeComponents.driverOrder() },
    {
        path: "orders/:id/receipt",
        component: routeComponents.driverOrderReceipt(),
    },
    { path: "profile", component: routeComponents.driverProfile() },
];
const workerRoutes = [
    { path: "", component: routeComponents.workerOrders() },
    {
        path: "coordination-management/add-master-item",
        component: routeComponents.workerUpdatePrice(),
    },
    {
        path: "coordination-management/receive-crate",
        component: routeComponents.workerReceiveCrate(),
    },
    {
        path: "coordination-management/send-crate",
        component: routeComponents.workerSendCrate(),
    },
    {
        path: "coordination-management",
        component: routeComponents.workerCoordinationManagement(),
    },
    { path: "item-list", component: routeComponents.workerPurchaseList() },
    { path: "place-order", component: routeComponents.workerPlaceOrder() },
    { path: "orders", component: routeComponents.workerOrders() },
    {
        path: "orders/modify/:id",
        component: routeComponents.workerUpdateOrder(),
    },
    {
        path: "orders/crate/:id",
        component: routeComponents.workerTagCrate(),
    },
    { path: "orders/loading", component: routeComponents.workerLoading() },
    { path: "loading", component: routeComponents.workerLoading() },
    { path: "profile", component: routeComponents.workerProfile() },
];
const adminRoutes = [
    { path: "", component: routeComponents.adminUsermanagement() },
    { path: "orders", component: routeComponents.adminOrdermanagement() },
    { path: "statistics", component: routeComponents.adminStatistics() },
    { path: "price-sync", component: routeComponents.adminPriceSync() },
    { path: "profile", component: routeComponents.adminProfile() },
    {
        path: "inventory-management/add-master-item",
        component: routeComponents.adminAddItem(),
    },
    {
        path: "inventory-management/register-crate",
        component: routeComponents.adminCrateRegistration(),
    },
    {
        path: "inventory-management/register-crate/test",
        component: routeComponents.adminMasterCrateRegistration(),
    },
    {
        path: "inventory-management",
        component: routeComponents.adminInventoryManagement(),
    },
    {
        path: "add-vendor-inventory",
        component: routeComponents.adminAddInventory(),
    },
];

const AppRouter = () => {
    const currentUser = useAppSelector(userSlice.selectCurrentUser);
    const isUserVerified = useAppSelector(userSlice.selectIsUserVerified);
    const isMasterLoading = useAppSelector(configSlice.selectIsLoading);
    const dispatch = useAppDispatch();

    const [protectedRoutes, setProtectedRoutes] = useState<any>([]);
    const [userRole, setUserRole] = useState<UserType>(UserType.none);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Get Authenticated User on page reload
    useEffect(() => {
        const getAuthenticatedUser = async () => {
            setIsLoading(true);
            await dispatch(userSlice.getRedirectedUserAuth(null));
        };
        getAuthenticatedUser();
    }, []);

    useEffect(() => {
        setIsLoading(isMasterLoading);
    }, [isMasterLoading]);
    useEffect(() => {
        if (currentUser?.role) {
            setUserRole(currentUser.role);
            setIsLoading(true);
            initPrivateRoutes();
        }
    }, [isUserVerified, currentUser]);

    const initPrivateRoutes = async () => {
        if (!isUserVerified) {
            setProtectedRoutes([]);
            setIsLoading(false);
            return;
        }
        if (userRole === UserType.Admin) {
            setProtectedRoutes(adminRoutes);
        }
        if (userRole === UserType.Vendor) {
            setProtectedRoutes(vendorRoutes);
        }
        if (userRole === UserType.BulkBuyer) {
            setProtectedRoutes(bulkBuyerRoutes);
        }
        if (userRole === UserType.RetailBuyer) {
            setProtectedRoutes(retailBuyerRoutes);
        }
        if (userRole === UserType.Worker) {
            setProtectedRoutes(workerRoutes);
        }
        if (userRole === UserType.Driver) {
            setProtectedRoutes(driverRoutes);
        }
        if (userRole !== UserType.none) {
            setIsLoading(false);
        }
        if (!userRole) {
            setIsLoading(true);
        }
    };
    const privateRoutes = () => {
        if (isUserVerified) {
            return (
                <Route path={userRole} element={<Drawer />}>
                    <Route />
                    {protectedRoutes.map(
                        ({ path, component: Component }: any) => (
                            <Route key={path} path={path} element={Component} />
                        )
                    )}
                </Route>
            );
        }
    };
    return (
        <Routes>
            {publicRoutes.map(({ path, component: Component }: any) => (
                <Route key={path} path={path} element={Component} />
            ))}
            {privateRoutes()}
            {isLoading ? null : <Route path="*" element={<NotFound />} />}
        </Routes>
    );
};

export default AppRouter;
