const selectors = {
    selectMasterItemList: (state: { item: any }) => {
        return state.item.masterItemList;
    },
    selectUserInventory: (state: { item: any }) => {
        return state.item.userInventory;
    },
    selectUserInventoryItem: (state: { item: any }) => {
        return state.item.userInventoryItem;
    },
};

export default selectors;
