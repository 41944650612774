export const getDefaultState = () => {
    return {
        isLoading: true,
        isLoadingProvider: false,
        verifyLinkTimestamp: null,
        config: null,
        serviceConfig: null,
        inventoryConfig: null,
    };
};

const state = { ...getDefaultState() };

export default state;
