import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../hooks";
import cartSlice from "../../store/slices/cart";
import orderSlice from "../../store/slices/order";
import { useNavigate } from "react-router-dom";
import { customToast } from "../public_components/Toast/CustomToast";

const OrderItem = ({ item, onRemove, handleQuantity }: any) => {
    const { name, image, price, quantity } = item;

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    ml: 2,
                }}
            >
                <Grid item xs={4}>
                    <CardMedia component="img" image={image} alt={name} />
                </Grid>
                <Grid item xs={6}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Rs {(price * quantity).toFixed(2)}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            {quantity} {item.unit}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

// Todo: Add price Update on load
const RetailPayment = ({ handlePlaceOrder }: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(cartSlice.selectCart);
    const [items, setItems] = useState<any[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        let total = 0;
        const itemsArray = cartItems.map((item: any) => {
            const cartObject = { ...item.item, quantity: item.cartQuantity };
            total += Math.round(item.item.price * item.cartQuantity);
            return cartObject;
        });
        setItems(itemsArray);
        setTotalPrice(total);
    }, [cartItems]);
    async function onCheckout() {
        setIsLoading(true);
        const result = await dispatch(orderSlice.addBulkOrder());
        const resultData = result?.payload?.data;
        if (resultData.isError === true) {
            customToast("Error Occured! Retry", "error");
            setIsLoading(false);
            return;
        }
        dispatch(cartSlice.clearCart());
        setIsLoading(false);
        handlePlaceOrder();
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        Delivery Address
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Home name
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Street name
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Town
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        District
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    {/* <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Sub Total: Rs {totalPrice.toFixed(2)}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            5% Service Fee: Rs {((totalPrice * 5) / 100).toFixed(2)}
          </Typography> */}
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Net Amount: Rs {totalPrice}
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        Bank Account Details
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        1234567895672
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        V A S Fernando
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Commercial Bank
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Nugegoda
                    </Typography>
                </CardContent>
            </Card>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <Button
                    variant="contained"
                    color="success"
                    onClick={onCheckout}
                    fullWidth
                >
                    Place Order
                </Button>
            )}
        </>
    );
};

export default RetailPayment;
