import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userProvider } from "../../../provider/firebase";

import state from "./state";
import reducers from "./reducers";
import selectors from "./selectors";
import configSlice from "../config";

// Async action for saving user data
const asyncThunks = {
    saveUser: createAsyncThunk("user/saveUser", async (userData: any) => {
        const result = await userProvider.signUpUser(userData);
        return result;
    }),
    loginUser: createAsyncThunk(
        "user/loginUser",
        async (loginData: any, { dispatch, getState }) => {
            await userProvider.logoutUser(dispatch);
            const result = await userProvider.loginUser(loginData);
            dispatch(actions.syncUserData());
            return result;
        }
    ),
    authenticateUserWithGoogleProvider: createAsyncThunk(
        "user/authenticateUserWithGoogleProvider",
        async (loginData: any, { dispatch, getState }) => {
            dispatch(configSlice.setIsLoadingProvider(true));
            const result = await userProvider.signUpUserGoogle();
            return result;
        }
    ),
    getRedirectedUserAuth: createAsyncThunk(
        "user/getRedirectedUserAuth",
        async (loginData: any, { dispatch, getState }) => {
            const result = await userProvider.getRedirectedUserAuth();
            await dispatch(actions.syncUserData());
            return result;
        }
    ),
    logoutUser: createAsyncThunk(
        "user/logoutUser",
        async (payload, { dispatch }: any) => {
            const result = await userProvider.logoutUser(dispatch);
            return result;
        }
    ),
    syncUserData: createAsyncThunk(
        "user/syncUserData",
        async (payload, { dispatch, getState }) => {
            const userId = window.localStorage.getItem("user_id");
            if (userId) {
                userProvider.subscribeUser(userId, dispatch);
            } else {
                userProvider.unsubscribeUser();
            }
        }
    ),
    sendUserVerifyLink: createAsyncThunk(
        "user/sendUserVerifyLink",
        async (_arg: any) => {
            const result = await userProvider.sendVerifyLink();
            return result;
        }
    ),
    fetchUserVerification: createAsyncThunk(
        "user/fetchUserVerification",
        async (_arg, { dispatch }) => {
            const result: any = await userProvider.fetchUserVerification();

            dispatch(actions.setIsUserVerified(result?.isUserVerified));

            return result;
        }
    ),
    resetUserPassword: createAsyncThunk(
        "user/resetUserPassword",
        async (loginData: any, { dispatch, getState }) => {}
    ),
    fetchAllVendors: createAsyncThunk("user/fetchAllVendors", async () => {
        return await userProvider.getAllVendors();
    }),
    fetchUserByUsername: createAsyncThunk(
        "user/fetchUserByUsername",
        async (username: string, { getState }) => {
            return await userProvider.fetchUserByUsername(username, getState);
        }
    ),
    fetchUserByReference: createAsyncThunk(
        "user/fetchUserByReference",
        async (userRef: any) => {
            return await userProvider.fetchUserByReference(userRef);
        }
    ),
    updateAdminUserDetails: createAsyncThunk(
        "user/updateAdminUserDetails",
        async (saveData: any, { getState }) => {
            return await userProvider.updateAdminUserDetails(
                saveData,
                getState
            );
        }
    ),
    updateUserDetails: createAsyncThunk(
        "user/updateUserDetails",
        async (saveData: any, { getState }) => {
            const state: any = getState();

            return await userProvider.updateUserDetails(
                state?.user?.currentUser,
                saveData
            );
        }
    ),
    fetchDrivers: createAsyncThunk(
        "user/fetchDrivers",
        async (_arg, { dispatch }) => {
            return await userProvider.fetchDrivers(dispatch);
        }
    ),
};

const userSlice = createSlice({
    // Name of the slice
    name: "user",
    // State of the slice
    initialState: state,
    // Reducers of the slice
    reducers: reducers,
    selectors: selectors,
});

export const actions = { ...userSlice.actions, ...asyncThunks };
export default userSlice.reducer;
