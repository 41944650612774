import {
    MapContainer,
    TileLayer,
    Marker,
    Circle,
    useMapEvents,
} from "react-leaflet";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "leaflet/dist/leaflet.css";
import "./map.css";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { Fab } from "@mui/material";
import Control from "react-leaflet-custom-control";

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const panOptions = {
    animate: false,
};

function StaticMarker({
    position,
    togglePanToPinLocation,
    draggable = true,
}: any) {
    const markerRef = useRef(null);
    const map = useMapEvents({});
    useEffect(() => {
        panToPinLocation();
    }, [togglePanToPinLocation]);

    const panToPinLocation = () => {
        if (position) {
            const newPosition = L.latLng(position[0], position[1]);
            map.flyTo(newPosition, 16, panOptions);
        }
    };

    return (
        <Marker
            zIndexOffset={1000}
            draggable={draggable}
            position={position}
            ref={markerRef}
        />
    );
}

function LiveLocationMarker({
    position,
    accuracy,
    togglePanToMyLocation,
}: any) {
    const map = useMapEvents({});
    useEffect(() => {
        panToCurrentLocation();
    }, [togglePanToMyLocation]);

    const panToCurrentLocation = () => {
        if (position) {
            const newPosition = L.latLng(position[0], position[1]);
            map.flyTo(newPosition, 16, panOptions);
        }
    };

    const locationIcon = L.icon({
        iconUrl: "/my-location.svg",
        iconSize: [25, 25],
        iconAnchor: [12, 14],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    return position === null ? null : (
        <>
            <Marker draggable={false} position={position} icon={locationIcon} />
            <Circle
                center={position}
                pathOptions={{
                    color: "#4A89F3",
                    weight: 0.5,
                    opacity: 0.5,
                }}
                radius={accuracy}
            />
        </>
    );
}

export default function MapStatic({ lat, lng }: any) {
    let navigationInterval: any = null;
    const [togglePanToMyLocation, setTogglePanToMyLocation] =
        useState<boolean>(true);
    const [togglePanToPinLocation, setTogglePanToPinLocation] =
        useState<boolean>(true);

    const [position, setPosition] = useState<any>({
        latitude: 6.8,
        longitude: 79.9,
    });
    const [center, setCenter] = useState<any>({
        latitude: 6.8,
        longitude: 79.9,
    });
    const [markerposition, setMarkerPosition] = useState({
        lat: lat | 6.8,
        lng: lng | 79.9,
    });
    const [accuracy, setAccuracy] = useState<number>(1000);
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigationInterval = navigator.geolocation.watchPosition(
                function (position) {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setAccuracy(position.coords.accuracy);
                },
                null,
                { enableHighAccuracy: true, timeout: 10000 }
            );
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);

    useEffect(() => {
        if (!lat || !lng) {
            return;
        }
        setMarkerPosition({ lat, lng });
        setTogglePanToPinLocation(!togglePanToPinLocation);
    }, [lat, lng]);

    // Oncomponent unmount
    useEffect(
        () => () => {
            if (navigationInterval) {
                navigator.geolocation.clearWatch(navigationInterval);
            }
        },
        []
    );

    return (
        <MapContainer
            center={[center?.latitude, center?.longitude]}
            zoom={10}
            style={{ marginBottom: 0 }}
        >
            <TileLayer
                attribution="Google Maps"
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                minZoom={4}
            />
            <Control prepend position="bottomright">
                <Fab
                    color="inherit"
                    size="medium"
                    onClick={() => {
                        setTogglePanToMyLocation(!togglePanToMyLocation);
                    }}
                >
                    <MyLocationIcon
                        fontSize="medium"
                        sx={{ color: "#4A89F3" }}
                    />
                </Fab>
            </Control>
            <Control prepend position="bottomright">
                <Fab
                    size="medium"
                    onClick={() => {
                        setTogglePanToPinLocation(!togglePanToPinLocation);
                    }}
                >
                    <LocationOnIcon fontSize="large" color="inherit" />
                </Fab>
            </Control>
            <LiveLocationMarker
                position={[position?.latitude, position?.longitude]}
                accuracy={accuracy}
                togglePanToMyLocation={togglePanToMyLocation}
            />
            <StaticMarker
                position={[markerposition?.lat, markerposition?.lng]}
                draggable={false}
                togglePanToPinLocation={togglePanToPinLocation}
            />
        </MapContainer>
    );
}
