import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { useAppDispatch, useAppSelector } from "../../hooks";
import orderSlice from "../../store/slices/order";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";
import userSlice from "../../store/slices/user";
import CustomDatePicker from "../public_components/datePicker/CustomDatePicker";
import { DensitySmall, Download, FactCheck, Sync } from "@mui/icons-material";
import { format } from "date-fns";

const ItemList = ({ data, isLoading }: any) => {
    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    m: 3,
                }}
            >
                <CircularProgress color="secondary" />
            </Box>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Code</TableCell>
                        <TableCell align="left">Item</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row: any, index: any) => {
                        return (
                            <TableRow key={index}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                    title={row.id}
                                >
                                    {row.item_name.item_sku}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                    title={row.id}
                                >
                                    {row.item_name.display_name.si}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                >
                                    {row.count} {row.item_name.unit_size}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const PurchaseList = () => {
    const dispatch = useAppDispatch();
    const orders = useAppSelector(orderSlice.selectOrders);
    const [items, setItems] = useState<any[]>([]);
    const [sheetItems, setSheetItems] = useState<any[]>([]);
    const [sheetData, setSheetData] = useState<any[]>([]);
    const [sheetName, setSheetName] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmeDialog, setIsConfirmeDialog] = useState<boolean>(false);
    const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());

    useEffect(() => {
        fetchOrders();
    }, [deliveryDate]);

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    const fetchOrders = async () => {
        setIsLoading(true);
        dispatch(
            orderSlice.subscribeWorkerToOrders({ deliveryDate, tab: "ongoing" })
        );
        const result: any = await dispatch(
            orderSlice.fetchPurchaseItems(deliveryDate)
        );
        setSheetItems(result?.payload?.totalItemSheetData);
        setItems(result?.payload.result);
        setIsLoading(false);
    };
    const fetchOrdersExcel = async () => {
        setIsLoading(true);
        const csvDataDate = [
            "Date-Time",
            deliveryDate.toLocaleDateString(),
            deliveryDate.toLocaleTimeString(),
        ];
        const csvDataHeader = ["SKU", ""];
        const csvDataHeaderItems = ["Name", ""];
        const csvDataHeaderUnit = ["Unit", ""];
        const csvDataBodyTemplate = [0];

        for (const i of orders) {
            for (const itm of i.items) {
                if (!csvDataHeader.includes(itm?.item_sku)) {
                    csvDataHeader.push(itm?.item_sku);
                    csvDataHeaderItems.push(itm?.name.en + "/" + itm?.name.si);
                    csvDataHeaderUnit.push(itm?.unit);
                    csvDataBodyTemplate.push(0);
                }
            }
        }
        const csvData: any = [
            csvDataDate,
            csvDataHeader,
            csvDataHeaderItems,
            csvDataHeaderUnit,
            ["Vendors/ Sort ID", "Vendors/ Name"],
        ];
        for (const i of orders) {
            const result: any = await dispatch(
                userSlice.fetchUserByReference(i?.belongs_to)
            );

            let updatedOrderRow = [...csvDataBodyTemplate];
            updatedOrderRow[0] = i?.sort_order_id;
            updatedOrderRow[1] = result.payload.result.display_name;
            for (const itm of i.items) {
                const indexOfItem = csvDataHeader.indexOf(itm?.item_sku);

                updatedOrderRow[indexOfItem] = itm?.quantity;
            }
            csvData.push(updatedOrderRow);
        }
        setSheetName(
            `farmLink_sorting_${format(deliveryDate, "yyyy-MM-dd")}.csv`
        );
        setSheetData(csvData);
        setIsLoading(false);
        setIsConfirmeDialog(true);
    };
    const fetchTotalOrdersExcel = async () => {
        setIsLoading(true);
        const csvDataHeader = ["FL Code", "Name", "Quantity"];

        const csvData: any[] = [csvDataHeader];
        for (const i of sheetItems) {
            const row = [i?.sku, i?.name?.en, i?.totalQuantity];
            csvData.push(row);
        }
        setSheetName(
            `farmLink_total_${format(deliveryDate, "yyyy-MM-dd")}.csv`
        );
        setSheetData(csvData);
        setIsLoading(false);
        setIsConfirmeDialog(true);
    };

    const handleClose = () => {
        setIsConfirmeDialog(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <ConfirmPopup
                isOpen={isConfirmeDialog}
                handleClose={handleClose}
                text={
                    <Grid container>
                        <Grid item xs={12} textAlign={"right"} mt={3}>
                            <Typography
                                textAlign={"right"}
                                variant="body1"
                                color={"secondary"}
                            >
                                <CSVLink
                                    data={sheetData}
                                    filename={sheetName}
                                    color="white"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Get Order Sheet
                                    </Button>
                                </CSVLink>
                            </Typography>
                        </Grid>
                    </Grid>
                }
                heading={<Grid container>Click To Download</Grid>}
                handleYes={handleClose}
                isYesButtonAvailable={false}
                noText={"Close"}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    pb: 10,
                    pt: 2,
                    pr: 3,
                    pl: 3,
                }}
            >
                <CustomDatePicker
                    label={"Delivery Date"}
                    value={deliveryDate}
                    minDate={null}
                    onChange={(newValue: Date) => setDeliveryDate(newValue)}
                />
                <Typography
                    textAlign={"center"}
                    variant="h5"
                    color={"text.primary"}
                    sx={{ pb: 3 }}
                >
                    Total Items
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button
                            color="success"
                            variant="contained"
                            onClick={fetchOrders}
                            fullWidth
                        >
                            <Sync />
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color="success"
                            variant="contained"
                            disabled={isLoading}
                            onClick={fetchOrdersExcel}
                            sx={{ mt: 3 }}
                        >
                            <FactCheck /> <Download />
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color="success"
                            variant="contained"
                            disabled={isLoading}
                            onClick={fetchTotalOrdersExcel}
                            sx={{ mt: 3 }}
                        >
                            <DensitySmall /> <Download />
                        </Button>
                    </Grid>
                </Grid>
                {items?.length === 0 && !isLoading ? (
                    <Typography
                        variant="h6"
                        color={"secondary"}
                        textAlign={"center"}
                    >
                        No Items Pending!
                    </Typography>
                ) : (
                    <ItemList data={items} isLoading={isLoading} />
                )}
            </Box>
        </Box>
    );
};

export default PurchaseList;
