import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import veg_logo from "../../assets/images/veg_icon.png";
import fr_logo from "../../assets/images/fr_icon.png";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Slide from "@mui/material/Slide";
import OutlinedInput from "@mui/material/OutlinedInput";

import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import itemSlice from "../../store/slices/item";
import { UserType } from "../../utils/userUtils";
import { ItemCategory } from "../../utils/itemUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserHome() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(userSlice.selectCurrentUser);
    const masterItems = useAppSelector(itemSlice.selectMasterItemList);

    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState(null);
    const [items, setItems] = React.useState([]);
    const [total, setTotal] = React.useState(100.5);
    const [userType, setUserType] = React.useState(null);
    const [estimated_delivery, setEstimated_delivery] =
        React.useState("Jan 02 - Jan 04");

    const handleClickOpen = (item) => {
        setItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setItem(null);
    };

    useEffect(() => {
        window.sessionStorage.setItem("home", "1");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        setUserType(currentUser?.role);
    }, [currentUser]);
    useEffect(() => {
        const fetchMasterItem = async () => {
            await dispatch(itemSlice.fetchMasterInventoryItem());
        };
        if (userType === UserType.Vendor) {
            fetchMasterItem();
            navigate("/vendor");
        }
    }, [userType]);
    useEffect(() => {
        setItemList();
    }, [masterItems]);

    // Load items according to user type
    const setItemList = () => {
        let finalItems = [];
        if (userType === UserType.Vendor && masterItems?.length > 0) {
            finalItems = masterItems.map((currentItem) => {
                const itemObject = {
                    id: currentItem.id,
                    name: currentItem.display_name.en,
                    price: currentItem.unit_price.bulk_price,
                    available: currentItem.status,
                    image: currentItem.item_image_url,
                    category: currentItem.category || ItemCategory.vegetable,
                    quantity: 0,
                };
                return itemObject;
            });
            setItems(finalItems);
        }
    };

    const filter_veg = () => {
        console.log("veg");
    };
    const filter_ft = () => {
        console.log("ft");
    };

    return (
        <>
            <Dialog
                fullScreen={window.innerWidth < 600 ? true : false}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                sx={{ zIndex: 1 }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                        >
                            {item?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        mb: { xs: 10, md: 0 },
                    }}
                >
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                        }}
                    >
                        <CardMedia>
                            <img
                                src={item?.image}
                                alt={item?.name}
                                style={{ width: "100%" }}
                            />
                        </CardMedia>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Rs.{item?.price}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Price per kg
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {item?.available}Kg
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Available
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            m: 3,
                        }}
                    >
                        <Card
                            sx={{
                                backgroundColor: "success.light",
                                borderRadius: 0,
                                width: "100%",
                                minWidth: 200,
                                p: 1,
                            }}
                        >
                            <Typography
                                variant="body2"
                                color="success.dark"
                                sx={{
                                    fontWeight: 700,
                                    alignContent: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                Estimated Delivery: {estimated_delivery}
                            </Typography>
                        </Card>
                        <Box
                            sx={{
                                mt: 4,
                            }}
                        ></Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "row", md: "column" },
                                justifyContent: {
                                    xs: "space-around",
                                    md: "center",
                                },
                                alignContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography
                                                    variant="body2"
                                                    color="info.main"
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    Kg
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    color="info"
                                    size="small"
                                    variant="outlined"
                                    type="search"
                                    focused
                                    fullWidth
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: "40%",
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Total
                                </Typography>
                                <Box
                                    sx={{
                                        width: 7,
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    color="info.main"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Rs.{total}
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            sx={{
                                mt: 4,
                                width: "80%",
                                height: 50,
                                borderRadius: 5,
                            }}
                            variant="contained"
                            color="success"
                            fullWidth
                        >
                            <Typography
                                variant="body2"
                                color="text.primary"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                Add to Cart
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Box
                sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    minHeight: "90vh",
                    height: "100%",
                }}
            >
                <Box>
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton color="info">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        color="info"
                        size="small"
                        variant="outlined"
                        type="search"
                        focused
                        fullWidth
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        justifyContent: "center",
                        mt: 2,
                    }}
                >
                    <Box
                        onClick={filter_veg}
                        sx={{
                            width: { xs: "20%", md: "8%" },
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={veg_logo}
                            alt="logo"
                            style={{ width: "100%" }}
                        />
                        <Typography
                            sx={{
                                fontFamily: "monospace",
                                fontWeight: 700,
                                color: "text.primary",
                            }}
                            variant="caption"
                        >
                            Vegitables
                        </Typography>
                    </Box>
                    <Box sx={{ width: { xs: "10%", md: "8%" } }}></Box>
                    <Box
                        onClick={filter_ft}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                            width: { xs: "20%", md: "8%" },
                        }}
                    >
                        <img
                            src={fr_logo}
                            alt="logo"
                            style={{ width: "100%" }}
                        />
                        <Typography
                            sx={{
                                fontFamily: "monospace",
                                fontWeight: 700,
                                color: "text.primary",
                            }}
                            variant="caption"
                        >
                            Fruits
                        </Typography>
                    </Box>
                </Box>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, md: 6 }}
                    sx={{
                        mt: 2,
                        mb: 6,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    {items.map((item) => (
                        <Grid key={item.id} xs={6} md={2}>
                            <Card
                                sx={{
                                    backgroundColor: "background.default",
                                    boxShadow: 4,
                                    borderRadius: 2,
                                }}
                            >
                                <CardActionArea
                                    onClick={() => handleClickOpen(item)}
                                >
                                    <CardMedia
                                        component="img"
                                        image={item.image}
                                        height={165}
                                        alt={item.name}
                                    />
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            pt: 0,
                                        }}
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Price: Rs.{item.price}/kg
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Available: {item.available}Kg
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}
