import React, { useEffect, useState } from "react";
import { Typography, Box, Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { actions } from "../../../store/slices/item/itemSlice";
import UpdateItemForm from "./AddVendorInventoryForm";
import user from "../../../provider/firebase/user";
import { useNavigate } from "react-router-dom";
import { customToast } from "../../public_components/Toast/CustomToast";

// Todo: Update UI to match
const AddVendorInventory = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [vendorArray, setVendorArray] = useState<any[]>([]);
    const [itemArray, setItemArray] = useState<any[]>([]);
    const [selectedVendor, setVendor] = useState<any>(null);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "1");
        window.sessionStorage.setItem("profile", "0");
        dispatch(actions.fetchMasterInventoryItem());
        getVendors();
    }, []);

    const getVendors = async () => {
        const data = await dispatch(user.getAllVendors);
        const vendorSearchOptions = data.map((vendor) => {
            const vendorWithLabel = {
                ...vendor,
                label: vendor.username + " " + vendor.id,
            };
            return vendorWithLabel;
        });
        setVendorArray(vendorSearchOptions);
    };

    const inventoryItems = async (itemFormArray: any) => {
        setItemArray(itemFormArray);
    };

    const addInventory = async () => {
        if (itemArray.length == 0 || selectedVendor === null) {
            console.log("ERROR: Empty data");
            return;
        }
        const result: any = await dispatch(
            actions.adminAddInventory({ selectedVendor, itemArray })
        );
        if (result?.payload.isError) {
            customToast(result?.payload.message, "error");
            return;
        }
        customToast(result?.payload.message, "success");
        navigate(0);
    };

    return (
        <Box
            pt={[5, 5]}
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 10,
                }}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    color={"secondary"}
                >
                    Admin add Inventory
                </Typography>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={vendorArray}
                    sx={{ width: 300 }}
                    onChange={(event, value) => {
                        setVendor(value);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Vendor" />
                    )}
                />
                <UpdateItemForm
                    inventoryItems={inventoryItems}
                    addInventory={addInventory}
                />
            </Box>{" "}
        </Box>
    );
};

export default AddVendorInventory;
