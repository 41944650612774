import { createSlice } from "@reduxjs/toolkit";

import state from "./state";
import reducers from "./reducers";
import selectors from "./selectors";

const cartSlice = createSlice({
    // Name of the slice
    name: "cart",
    // State of the slice
    initialState: state,
    // Reducers of the slice
    reducers: reducers,
    selectors: selectors,
});

export const actions = { ...cartSlice.actions };
export default cartSlice.reducer;
