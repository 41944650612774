import {
    Badge,
    BadgeProps,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    InputAdornment,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import itemSlice from "../../store/slices/item";
import { customToast } from "../public_components/Toast/CustomToast";
import { ItemState } from "../../utils/itemUtils";

const ItemBlock = ({ currentItem }: any) => {
    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        "& .MuiBadge-badge": {
            right: -20,
            top: 20,
            padding: "0 4px",
        },
    }));
    return (
        <Card
            sx={{
                display: "flex",
                m: 2,
                backgroundColor: "background.default",
            }}
            key={currentItem?.id}
        >
            <Box
                sx={{ display: "flex", flexDirection: "column" }}
                color={"secondary"}
            >
                <CardContent sx={{ pb: 3 }}>
                    <Typography variant="h5" fontWeight="bold">
                        {currentItem?.display_name?.si || "Item name Not Valid"}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" fontWeight="bold">
                                Bulk
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Rs. {currentItem?.unit_price?.bulk_price}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" fontWeight="bold">
                                Premium
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Rs.
                                {currentItem?.unit_price?.bulk_premium_price}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            pl: 2,
                            pb: 2,
                            pr: 2,
                        }}
                    ></Box>
                </CardContent>
            </Box>
            <StyledBadge
                badgeContent={""}
                color={
                    currentItem?.available === ItemState.InStock
                        ? "success"
                        : "error"
                }
            ></StyledBadge>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={currentItem.item_image_url}
                alt="Item image"
            />
        </Card>
    );
};

const WorkerUpdateItemPrice = () => {
    const dispatch = useAppDispatch();
    const [itemsArray, setItemsArray] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "1");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            const items: any = await dispatch(
                itemSlice.fetchMasterInventoryItem()
            );
            const currentItemArray = [];
            for (const i of items.payload) {
                let sourcePrice = 0;
                const itemObject = {
                    id: i.id,
                    unit_price: i.unit_price,
                    display_name: i.display_name,
                    item_image_url: i.item_image_url,
                    available: i?.status,
                    new_price: sourcePrice,
                };
                currentItemArray.push(itemObject);
            }
            setItemsArray(currentItemArray);
            setIsLoading(false);
        };
        fetch();
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <Typography
                textAlign={"center"}
                variant="h4"
                color={"text.primary"}
            >
                Item Prices
            </Typography>
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                >
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    {itemsArray.map((currentItem: any) => {
                        return (
                            <ItemBlock
                                key={currentItem.id}
                                currentItem={currentItem}
                                startPrice={currentItem.new_price}
                            />
                        );
                    })}
                </>
            )}
        </Box>
    );
};

export default WorkerUpdateItemPrice;
