import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Grid,
    Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import cartSlice from "../../store/slices/cart";
import { useNavigate, useParams } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import orderSlice from "../../store/slices/order";
import {
    OrderStatus,
    PaymentMethod,
    PaymentStatus,
    generateSixDigitNumber,
} from "../../utils/orderUtils";
import configSlice from "../../store/slices/config";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {},
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {},
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.primary.light,
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage:
            "linear-gradient( 136deg, #c66900 0%,#263238 50%, #ff9800 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage:
            "linear-gradient( 136deg, #c66900 0%,#263238 50%, #ff9800 100%)",
    }),
}));

interface CustomStepIconProps extends StepIconProps {
    type: string;
}

function ColorlibStepIcon(props: CustomStepIconProps) {
    const { active, completed, type } = props;

    const icons: { [index: string]: React.ReactElement } = {
        pending: <ShoppingCartIcon sx={{ width: 20, height: 20 }} />,
        ready: <AssignmentTurnedInIcon sx={{ width: 20, height: 20 }} />,
        "out-for-delivery": (
            <LocalShippingIcon sx={{ width: 20, height: 20 }} />
        ),
        delivered: <InventoryIcon sx={{ width: 20, height: 20 }} />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }}>
            {icons[String(props.type)]}
        </ColorlibStepIconRoot>
    );
}

const OrderItem = ({ item }: any) => {
    const { name, image, unit_price, quantity, unit } = item;

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 1,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    ml: 2,
                }}
            >
                <Grid item xs={4}>
                    <CardMedia component="img" image={image} alt={name?.si} />
                </Grid>
                <Grid item xs={6}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {name?.si}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Rs {unit_price?.toFixed(2)}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            {quantity} {unit}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

const PastOrder = () => {
    const navigate = useNavigate();
    const urlParams = useParams();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(cartSlice.selectCart);
    const config = useAppSelector(configSlice.selectConfig);

    const [items, setItems] = useState<any[]>([]);
    const [order, setOrder] = useState<any>();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState<any>([]);
    const [bankDetails, setBankDetails] = useState<any>(null);
    const [toBePaidAmount, setToBePaidAmount] = useState<number>(0);
    const [stepsData, setStepsData] = React.useState<{
        [index: number]: { date: string; status: string; type: string };
    }>();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        fetchOrder();
    }, [urlParams]);
    useEffect(() => {
        const loadConfig = async () => {
            await dispatch(configSlice.getConfig());
        };
        loadConfig();
    }, []);

    useEffect(() => {
        if (!order) {
            return;
        }
        setOrderLog();
        setItems(order?.items);
        calcPriceToBePaid();
    }, [order]);

    useEffect(() => {
        if (cartItems.length === 0) {
            // navigate("/vendor/market");
        }
        let total = 0;
        const itemsArray = cartItems.map((item: any) => {
            const cartObject = { ...item.item, quantity: item.cartQuantity };
            total += item.item.price * item.cartQuantity;
            return cartObject;
        });
        setItems(itemsArray);
        setTotalPrice(total);
    }, [cartItems]);

    useEffect(() => {
        if (!config) {
            return;
        }
        setBankDetails(config?.bank_details);
    }, [config]);

    function calcPriceToBePaid() {
        const paid = 0;
        if (!order) {
            return;
        }
        if (order?.paid_amount) {
            if (order?.total_price - order?.paid_amount > 0) {
                setToBePaidAmount(order?.total_price - order?.paid_amount);
                return;
            }
            setToBePaidAmount(0);
        } else {
            setToBePaidAmount(order?.total_price);
        }
    }

    async function fetchOrder() {
        if (!urlParams?.id) {
            return;
        }
        const order: any = await dispatch(orderSlice.fetchOrder(urlParams?.id));
        if (order?.payload?.isError) {
            return;
        }
        setOrder(order?.payload?.result);
    }

    async function setOrderLog() {
        const logObject: any = {};
        const stepArray = [];
        for (const [index, log] of order?.order_delivery_log
            .reverse()
            .entries()) {
            logObject[index] = {
                date: log.timestamp?.toDate()?.toLocaleString(),
                status: log.text,
                type: log.status_type,
            };
            stepArray.push(log.text);
        }
        setStepsData(logObject);
        setSteps(stepArray);
    }

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Order ID
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.primary"
                                sx={{
                                    fontWeight: 500,
                                }}
                            >
                                {order?.id}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Delivery Address
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.primary"
                                sx={{
                                    fontWeight: 500,
                                }}
                            >
                                {order?.end_location?.address}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <Chip
                                label={order?.order_status}
                                size="medium"
                                color={
                                    order?.order_status ===
                                    OrderStatus.Delivered
                                        ? "success"
                                        : "secondary"
                                }
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    {/* <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Street name
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Town
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            District
          </Typography> */}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    {/* <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Sub Total: Rs {totalPrice.toFixed(2)}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            5% Service Fee: Rs {((totalPrice * 5) / 100).toFixed(2)}
          </Typography> */}
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Total Amount:
                            </Typography>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                textAlign={"left"}
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Rs {order?.total_price?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Balance Amount:
                            </Typography>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                textAlign={"left"}
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Rs {toBePaidAmount.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <Chip
                                label={order?.payment_status}
                                size="medium"
                                color={
                                    order?.payment_status === PaymentStatus.Paid
                                        ? "success"
                                        : "secondary"
                                }
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Payment ID:
                            </Typography>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                textAlign={"left"}
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                {order?.payment_order_id}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <Chip
                                label={order?.payment_method}
                                size="medium"
                                color={
                                    order?.payment_method === PaymentMethod.Cash
                                        ? "success"
                                        : "secondary"
                                }
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    {order?.payment_method === PaymentMethod.Bank &&
                    order?.payment_status !== PaymentStatus.Paid ? (
                        <>
                            <Typography
                                variant="caption"
                                color="text.primary"
                                sx={{
                                    fontWeight: 500,
                                    mb: 3,
                                }}
                            >
                                {`*Deposit Rs. ${toBePaidAmount.toFixed(
                                    2
                                )} to the following account with ${
                                    order?.payment_order_id
                                } as the beneficiary comment`}
                            </Typography>
                            <Accordion elevation={0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="info" />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    color="secondary"
                                    sx={{
                                        p: 0,
                                        m: 0,
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        Bank details
                                    </Typography>
                                </AccordionSummary>
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        alignContent: "center",
                                        p: 0,
                                        m: 0,
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            fontWeight: 600,
                                            p: 0,
                                            m: 0,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            {bankDetails?.number}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            {bankDetails?.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            {bankDetails?.bank}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            {bankDetails?.branch}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Accordion>
                        </>
                    ) : null}
                </CardContent>
            </Card>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    m: 0,
                    pt: 2,
                }}
            >
                {items.length === 0 ? (
                    <Typography variant="h6" color={"secondary"}>
                        No items in cart !
                    </Typography>
                ) : (
                    ""
                )}
                {items.map((item: any) => (
                    <Box key={generateSixDigitNumber()}>
                        <OrderItem item={item} />
                        <Box sx={{ p: 1 }} />
                    </Box>
                ))}
            </Box>
            <Stepper
                activeStep={activeStep}
                connector={<ColorlibConnector />}
                orientation="vertical"
                sx={{ pb: 7 }}
            >
                {steps.map((label: any, index: number) => {
                    if (!stepsData) {
                        return;
                    }
                    return (
                        <Step key={label + Math.random()}>
                            <StepLabel
                                //@ts-ignore
                                StepIconComponent={ColorlibStepIcon}
                                //@ts-ignore
                                StepIconProps={{ type: stepsData[index]?.type }}
                                optional={
                                    <>
                                        <Typography variant="caption">
                                            {stepsData[index]?.date}
                                        </Typography>
                                        <Typography variant="body2">
                                            {stepsData[index]?.status}
                                        </Typography>
                                    </>
                                }
                            >
                                {/* {label} */}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};

export default PastOrder;
