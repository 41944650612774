import { useEffect, useState } from "react";
import Logo from "../../assets/logo_V1_512.png";
import { Typography, Box, CircularProgress, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import { useNavigate } from "react-router-dom";
import configSlice from "../../store/slices/config";

const AppName = "FarmLink";

const LoginLanding = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSlice.selectCurrentUser);
    const isUserVerified = useAppSelector(userSlice.selectIsUserVerified);
    const isMasterLoading = useAppSelector(configSlice.selectIsLoading);
    const [userAuth, setUserAuth] = useState<any>(null);
    const [isButton, setIsButton] = useState<any>(false);

    useEffect(() => {
        const getAuthenticatedUser = async () => {
            dispatch(configSlice.setIsLoading(true));
            const result = await dispatch(
                userSlice.getRedirectedUserAuth(null)
            );
            setUserAuth(result.payload);
            dispatch(configSlice.setIsLoading(false));
            setTimeout(() => {
                setIsButton(true);
            }, 10000);
        };
        getAuthenticatedUser();
    }, [user]);
    useEffect(() => {
        if (user?.role && isUserVerified && !isMasterLoading) {
            window.localStorage.setItem("user_logged", "1");
            navigate(`/${user.role}`);
        }
        const isUserLoggedAttempted = window.localStorage.getItem("user_logged");
        if (!isUserLoggedAttempted || isUserLoggedAttempted == "0") {
            navigate(`/sign-in`);
        }
    }, [userAuth, isUserVerified, isMasterLoading]);

    return (
        <Box
            pt={[5, 5]}
            sx={{
                backgroundColor: "background.paper",
                height: "90vh",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: ["40%", "10%"],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mx: "auto",
                    mb: 5,
                }}
            >
                <img src={Logo} alt="logo" style={{ width: "100%" }} />
            </Box>
            <Box>
                <CircularProgress color="secondary" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: 80,
                    borderRadius: "20px 20px 0 0",
                }}
            >
                {isButton ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            window.localStorage.setItem("user_logged", "0");
                            navigate("/sign-in");
                        }}
                    >
                        Retry
                    </Button>
                ) : null}
            </Box>

            <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "primary.dark",
                    width: "100%",
                    height: 80,
                    borderRadius: "20px 20px 0 0",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{ color: "#ffffff", fontSize: ["none", 12] }}
                >
                    © {new Date().getFullYear()} {AppName}. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
};

export default LoginLanding;
