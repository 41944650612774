import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import state from "./state";
import reducers from "./reducers";
import selectors from "./selectors";
import { configProvider } from "../../../provider/firebase";

const asyncThunks = {
    getConfig: createAsyncThunk(
        "user/getConfig",
        async (_arg, { dispatch }) => {
            const result = await configProvider.getOrderConfig(dispatch);
            return result;
        }
    ),
    clearConfig: createAsyncThunk(
        "user/clearConfig",
        async (_arg, { dispatch }) => {
            const result = await configProvider.unsubscribeConfig(dispatch);
            return result;
        }
    ),
    getServiceConfig: createAsyncThunk(
        "user/getServiceConfig",
        async (_arg, { dispatch }) => {
            const result = await configProvider.getServiceConfig(dispatch);
            return result;
        }
    ),
    clearServiceConfig: createAsyncThunk(
        "user/clearServiceConfig",
        async (_arg, { dispatch }) => {
            const result = await configProvider.unsubscribeServiceConfig(
                dispatch
            );
            return result;
        }
    ),
    updateIsOrderRestricted: createAsyncThunk(
        "user/updateIsOrderRestricted",
        async (value: any) => {
            const result = await configProvider.updateIsOrderRestricted(value);
            return result;
        }
    ),
    updateRestrictTime: createAsyncThunk(
        "user/updateRestrictTime",
        async (value: any) => {
            const result = await configProvider.updateRestrictTime(value);
            return result;
        }
    ),
    updateBankDetails: createAsyncThunk(
        "user/updateBankDetails",
        async (value: any) => {
            const result = await configProvider.updateBankDetails(value);
            return result;
        }
    ),
    updateHelpline: createAsyncThunk(
        "user/updateHelpline",
        async (value: any) => {
            const result = await configProvider.updateHelpline(value);
            return result;
        }
    ),
};

const configSlice = createSlice({
    // Name of the slice
    name: "config",
    // State of the slice
    initialState: state,
    // Reducers of the slice
    reducers: reducers,
    selectors: selectors,
});

export const actions = { ...configSlice.actions, ...asyncThunks };
export default configSlice.reducer;
