import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import userSlice from "../../../store/slices/user";
import FileUploader from "../../public_components/fileUploader/FileUploader";
import { ItemCategory, ItemUnits } from "../../../utils/itemUtils";
import { customToast } from "../../public_components/Toast/CustomToast";

const AddItemForm = ({ addItem }: any) => {
    // Hooks
    const dispatch = useAppDispatch();
    const users = useAppSelector(userSlice.selectCurrentUser);
    const navigate = useNavigate();

    const [file, setFile] = useState<File[]>([]);
    const [userType, setUserType] = useState("");
    const [itemSKU, setItemSKU] = useState("");
    const [itemNameSearchTerm, setItemNameSearchTerm] = useState("");
    const [itemNameEn, setItemName] = useState("");
    const [itemBillName, setItemBillName] = useState("");
    const [itemNameSi, setItemNameSi] = useState("");
    const [itemNameTa, setItemNameTa] = useState("");
    const [itemSourcePrice, setItemSourcePrice] = useState(0);
    const [itemBulkPrice, setItemBulkPrice] = useState(0);
    const [itemPremiumBulkPrice, setItemPremiumBulkPrice] = useState(0);
    const [itemRetailPrice, setItemRetailPrice] = useState(0);
    const [itemUnit, setItemUnit] = useState<string>(ItemUnits.kg);
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionSi, setDescriptionSi] = useState("");
    const [descriptionTa, setDescriptionTa] = useState("");
    const [category, setCategory] = React.useState("");

    useEffect(() => {
        console.log("Use Effect User Admin: ", users);
        setUserType(users.role);
    }, [users]);

    const handleChange = (event: SelectChangeEvent) => {
        setCategory(event.target.value as string);
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        // Form validation can be added here before submitting
        if (!itemNameEn || !itemSourcePrice || !itemUnit || category === "") {
            customToast("Please fill in all fields", "error");
            return;
        }

        const item = {
            name_search_term: itemNameSearchTerm,
            display_name: {
                en: itemNameEn,
                si: itemNameSi,
                ta: itemNameTa,
            },
            description: {
                en: descriptionEn,
                si: descriptionSi,
                ta: descriptionTa,
            },
            unit_price: {
                source_price: itemSourcePrice,
                retail_price: itemRetailPrice,
                bulk_price: itemBulkPrice,
                bulk_premium_price: itemPremiumBulkPrice,
            },
            category: category,
            unit_size: itemUnit,
            item_image: file[0],
            item_sku: itemSKU,
            item_bill_name: itemBillName,
            grade: 0,
            status: undefined,
        };

        const result = await addItem(item);
        if (result?.isError) {
            console.log(result?.message);
            customToast(result?.message, "success");
            return;
        }
        customToast("Item Added !", "success");
        // setFile([]);
        // setItemSKU("");
        // setItemNameSearchTerm("");
        // setItemName("");
        // setItemNameSi("");
        // setItemNameTa("");
        // setItemBulkPrice(0);
        // setItemPremiumBulkPrice(0);
        // setItemSourcePrice(0);
        // setItemRetailPrice(0);
        // setDescriptionEn("");
        // setDescriptionSi("");
        // setDescriptionTa("");
    };

    const categorySelect = () => {
        const values = Object.values(ItemCategory);
        const select = (
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleChange}
            >
                {values.map((currentItem) => {
                    return (
                        <MenuItem key={currentItem} value={currentItem}>
                            {currentItem}
                        </MenuItem>
                    );
                })}
                ;
            </Select>
        );

        return select;
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "& > :not(style)": { m: 1, width: "25ch" },
                }}
            >
                <Typography variant="h6" gutterBottom color={"secondary"}>
                    {userType}
                </Typography>
                <FileUploader
                    buttonText="Upload Image"
                    images={file}
                    setImages={setFile}
                ></FileUploader>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        category
                    </InputLabel>
                    {categorySelect()}
                </FormControl>
                <TextField
                    label="Item SKU"
                    value={itemSKU}
                    onChange={(e) => setItemSKU(e.target.value)}
                />
                <TextField
                    label="Item Bill Name"
                    value={itemBillName}
                    onChange={(e) => setItemBillName(e.target.value)}
                />
                <TextField
                    label="Item Name Search Terms"
                    value={itemNameSearchTerm}
                    onChange={(e) => setItemNameSearchTerm(e.target.value)}
                />
                <TextField
                    label="Item Name (English)"
                    value={itemNameEn}
                    onChange={(e) => setItemName(e.target.value)}
                />
                <TextField
                    label="Item Name (Sinhala)"
                    value={itemNameSi}
                    onChange={(e) => setItemNameSi(e.target.value)}
                    InputProps={{ lang: "si" }}
                />
                <TextField
                    label="Item Name (Tamil)"
                    value={itemNameTa}
                    onChange={(e) => setItemNameTa(e.target.value)}
                />
                <TextField
                    label="Item Source Price"
                    type="number"
                    value={itemSourcePrice}
                    onChange={(e) => setItemSourcePrice(+e.target.value)}
                />
                <TextField
                    label="Item Bulk Price"
                    type="number"
                    value={itemBulkPrice}
                    onChange={(e) => setItemBulkPrice(+e.target.value)}
                />
                <TextField
                    label="Item Retail Price"
                    type="number"
                    value={itemRetailPrice}
                    onChange={(e) => setItemRetailPrice(+e.target.value)}
                />
                <TextField
                    label="Item Premium Bulk Price"
                    type="number"
                    value={itemPremiumBulkPrice}
                    onChange={(e) => setItemPremiumBulkPrice(+e.target.value)}
                />
                <TextField
                    label="Item Unit"
                    value={itemUnit}
                    onChange={(e) => setItemUnit(e.target.value)}
                />
                <TextField
                    label="Description (English)"
                    multiline
                    rows={4}
                    value={descriptionEn}
                    onChange={(e) => setDescriptionEn(e.target.value)}
                />
                <TextField
                    label="Description (Sinhala)"
                    multiline
                    rows={4}
                    value={descriptionSi}
                    onChange={(e) => setDescriptionSi(e.target.value)}
                />
                <TextField
                    label="Description (Tamil)"
                    multiline
                    rows={4}
                    value={descriptionTa}
                    onChange={(e) => setDescriptionTa(e.target.value)}
                />
                <Button variant="contained" type="submit" color="secondary">
                    Add Item
                </Button>
            </Box>
        </form>
    );
};

export default AddItemForm;
