export const getDefaultState = () => {
    return {
        crateList: [],
        crateStatistics: null,
    };
};

const state = getDefaultState();

export default {
    ...state,
};
