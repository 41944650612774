import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { ListItemIcon } from "@mui/material";
import Container from "@mui/material/Container";
import Footer from "./Footer";
import logo from "../../assets/logo.svg";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import { actions } from "../../store/slices/user/userSlice";
import { UtilContext } from "../../provider/UtilContext";
import configSlice from "../../store/slices/config";

const appName = "FarmLink";

function Drawer() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSlice.selectCurrentUser);

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [profileUrl, setProfileUrl] = React.useState(null);
    const [fullname, setFullname] = React.useState("");
    const [flag, setFlag] = React.useState(false);

    React.useEffect(() => {
        const getAuthenticatedUser = async () => {
            await dispatch(userSlice.getRedirectedUserAuth());
        };
        getAuthenticatedUser();
    }, []);
    React.useEffect(() => {
        setProfileUrl(user?.profile_image_url);
        setFullname(user?.display_name);
    }, [user]);

    const handleLogout = async () => {
        dispatch(configSlice.setIsLoading(true));
        const result = await dispatch(actions.logoutUser());
        if (result.error) {
            return;
        }
        navigate("/sign-in");
        dispatch(configSlice.setIsLoading(false));
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <UtilContext.Provider value={{ flag, setFlag }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <AppBar color="primary" position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box
                                sx={{ width: [50, 70] }}
                                mr={[0, 4]}
                                mt={[1, 4]}
                            >
                                <img
                                    onClick={() => {
                                        navigate("");
                                    }}
                                    src={logo}
                                    alt="logo"
                                    style={{ width: "100%" }}
                                />
                            </Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: "none", md: "flex" },
                                    fontFamily: "monospace",
                                    fontWeight: 700,
                                    letterSpacing: ".3rem",
                                    color: "inherit",
                                    textDecoration: "none",
                                }}
                            >
                                {appName}
                            </Typography>
                            <Typography
                                variant="h5"
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: { xs: "none", md: "none" },
                                    flexGrow: 1,
                                    fontFamily: "monospace",
                                    fontWeight: 700,
                                    letterSpacing: ".1rem",
                                    color: "inherit",
                                    textDecoration: "none",
                                }}
                            >
                                {appName}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="profile">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={profileUrl}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem disabled>{fullname}</MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <LogoutIcon
                                                fontSize="small"
                                                color="info"
                                            />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Outlet />
                <Footer />
            </Box>
        </UtilContext.Provider>
    );
}
export default Drawer;
