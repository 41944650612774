const reducers = {
    setOrders: (state: any, action: any) => {
        state.orders = action.payload;
    },
    clearOrders: (state: any) => {
        state.orders = null;
    },
    setWorkerSelectedOrderObject: (state: any, action: any) => {
        state.workerSelectedOrderObject = action.payload;
    },
    clearWorkerSelectedOrderObject: (state: any) => {
        state.workerSelectedOrderObject = null;
    },
    setPaymentMethod: (state: any, action: any) => {
        state.paymentMethod = action.payload;
    },
};

export default reducers;
