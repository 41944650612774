import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CallIcon from "@mui/icons-material/Call";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import BarChartIcon from "@mui/icons-material/BarChart";
import StoreIcon from "@mui/icons-material/Store";
import ExtensionIcon from "@mui/icons-material/Extension";
import PersonIcon from "@mui/icons-material/Person";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import Badge from "@mui/material/Badge";
import { useAppDispatch, useAppSelector } from "../../hooks";
import cartSlice from "../../store/slices/cart";
import userSlice from "../../store/slices/user";
import configSlice from "../../store/slices/config";
import { UtilContext } from "../../provider/UtilContext";
import { UserType } from "../../utils/userUtils";
import { Typography } from "@mui/material";

const StyledBox = styled(Box)({
    zIndex: 100,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "1rem",
    textAlign: "center",
    py: 3,
});

const StyledFab = styled(Fab)({
    position: "absolute",
    zIndex: 101,
    top: -10,
    left: 0,
    right: 0,
    margin: "0 auto",
});

const Footer = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cartInStore = useAppSelector(cartSlice.selectCart);
    const currentUser = useAppSelector(userSlice.selectCurrentUser);
    const config = useAppSelector(configSlice.selectServiceConfig);

    const { flag, setFlag } = React.useContext(UtilContext);
    const [home, setHome] = React.useState(null);
    const [cart, setCart] = React.useState(null);
    const [statistics, setStatistics] = React.useState(null);
    const [priceUpdate, setPriceUpdate] = React.useState(null);
    const [orders, setOrders] = React.useState(null);
    const [profile, setProfile] = React.useState(null);
    const [masterItem, setMasterItem] = React.useState(null);
    const [userManagement, setUserManagement] = React.useState(null);
    const [vendorInventory, setVendorInventory] = React.useState(null);

    const myNav = (path) => {
        setFlag(!flag);
        navigate(path);
    };

    useEffect(() => {
        setHome(window.sessionStorage.getItem("market"));
        setCart(window.sessionStorage.getItem("cart"));
        setOrders(window.sessionStorage.getItem("orders"));
        setProfile(window.sessionStorage.getItem("profile"));
        setStatistics(window.sessionStorage.getItem("statistics"));
        setPriceUpdate(window.sessionStorage.getItem("price-update"));
        setUserManagement(
            window.sessionStorage.getItem("user_management", "1")
        );
        setMasterItem(window.sessionStorage.getItem("master_item", "0"));
        setVendorInventory(
            window.sessionStorage.getItem("vendor_inventory", "0")
        );
    }, [flag]);

    useEffect(() => {
        if (currentUser?.role === UserType.RetailBuyer) {
            dispatch(configSlice.getServiceConfig());
        }
    }, []);

    return (
        <StyledBox sx={{ alignContent: "flex-end", zIndex: 1000 }}>
            {currentUser && currentUser.role === "vendor" && (
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0 }}
                >
                    <Toolbar>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("market")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <HomeIcon
                                    fontSize="large"
                                    color={home == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("cart")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <Badge
                                    badgeContent={cartInStore?.length}
                                    color="secondary"
                                >
                                    <ShoppingCartIcon
                                        fontSize="large"
                                        color={
                                            cart == "1" ? "secondary" : "info"
                                        }
                                    />
                                </Badge>
                            </IconButton>
                        </Box>
                        <StyledFab
                            color="secondary"
                            aria-label="add"
                        ></StyledFab>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("orders")}
                                color="inherit"
                            >
                                <LocalMallIcon
                                    fontSize="large"
                                    color={orders == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("profile")}
                                color="inherit"
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color={
                                        profile == "1" ? "secondary" : "info"
                                    }
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}

            {currentUser && currentUser.role === "retail-buyer" && (
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0 }}
                >
                    <Toolbar>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <Typography variant="h6">Call Us</Typography>
                            {/* <IconButton
                                onClick={() => {
                                    window.sessionStorage.setItem(
                                        "market",
                                        "1"
                                    );
                                    window.sessionStorage.setItem("cart", "0");
                                    window.sessionStorage.setItem(
                                        "orders",
                                        "0"
                                    );
                                    window.sessionStorage.setItem(
                                        "profile",
                                        "0"
                                    );
                                    myNav("");
                                }}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <HomeIcon
                                    fontSize="large"
                                    color={home == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.sessionStorage.setItem(
                                        "market",
                                        "0"
                                    );
                                    window.sessionStorage.setItem("cart", "1");
                                    window.sessionStorage.setItem(
                                        "orders",
                                        "0"
                                    );
                                    window.sessionStorage.setItem(
                                        "profile",
                                        "0"
                                    );
                                    myNav("");
                                }}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <Badge
                                    badgeContent={cartInStore?.length}
                                    color="secondary"
                                >
                                    <ShoppingCartIcon
                                        fontSize="large"
                                        color={
                                            cart == "1" ? "secondary" : "info"
                                        }
                                    />
                                </Badge>
                            </IconButton> */}
                        </Box>
                        <StyledFab
                            color="secondary"
                            aria-label="add"
                            onClick={() => {
                                window.open(`tel:${config?.helpline}`);
                            }}
                        >
                            <CallIcon />
                        </StyledFab>
                        <Box sx={{ flexGrow: 1 }} />
                        <Typography variant="h6">{config?.helpline}</Typography>
                        {/* <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    window.sessionStorage.setItem(
                                        "market",
                                        "0"
                                    );
                                    window.sessionStorage.setItem("cart", "0");
                                    window.sessionStorage.setItem(
                                        "orders",
                                        "1"
                                    );
                                    window.sessionStorage.setItem(
                                        "profile",
                                        "0"
                                    );
                                    myNav("");
                                }}
                                color="inherit"
                            >
                                <LocalMallIcon
                                    fontSize="large"
                                    color={orders == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.sessionStorage.setItem(
                                        "market",
                                        "0"
                                    );
                                    window.sessionStorage.setItem("cart", "0");
                                    window.sessionStorage.setItem(
                                        "orders",
                                        "0"
                                    );
                                    window.sessionStorage.setItem(
                                        "profile",
                                        "1"
                                    );
                                    myNav("");
                                }}
                                color="inherit"
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color={
                                        profile == "1" ? "secondary" : "info"
                                    }
                                />
                            </IconButton>
                        </Box> */}
                    </Toolbar>
                </AppBar>
            )}
            {currentUser && currentUser.role === "worker" && (
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0 }}
                >
                    <Toolbar>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("orders")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <HomeIcon
                                    fontSize="large"
                                    color={home == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("item-list")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <ExtensionIcon
                                    fontSize="large"
                                    color={cart == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                        </Box>
                        <StyledFab
                            color="secondary"
                            aria-label="add"
                            onClick={() => myNav("place-order")}
                        >
                            <AddShoppingCartIcon fontSize="large" />
                        </StyledFab>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("coordination-management")}
                                color="inherit"
                            >
                                <StoreIcon
                                    fontSize="large"
                                    color={orders == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("profile")}
                                color="inherit"
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color={
                                        profile == "1" ? "secondary" : "info"
                                    }
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            {currentUser && currentUser.role === "driver" && (
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0 }}
                >
                    <Toolbar>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("orders")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <HomeIcon
                                    fontSize="large"
                                    color={home == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                        </Box>
                        <StyledFab
                            color="secondary"
                            aria-label="add"
                        ></StyledFab>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("profile")}
                                color="inherit"
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color={
                                        profile == "1" ? "secondary" : "info"
                                    }
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            {currentUser && currentUser.role === "admin" && (
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0 }}
                >
                    <Toolbar>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <ManageAccountsIcon
                                    fontSize="large"
                                    color={
                                        userManagement == "1"
                                            ? "secondary"
                                            : "info"
                                    }
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("inventory-management")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <Badge
                                    badgeContent={cartInStore?.length}
                                    color="secondary"
                                >
                                    <AddShoppingCartIcon
                                        fontSize="large"
                                        color={
                                            masterItem == "1"
                                                ? "secondary"
                                                : "info"
                                        }
                                    />
                                </Badge>
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("add-vendor-inventory")}
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <Badge
                                    badgeContent={cartInStore?.length}
                                    color="secondary"
                                >
                                    <AddBusinessIcon
                                        fontSize="large"
                                        color={
                                            vendorInventory == "1"
                                                ? "secondary"
                                                : "info"
                                        }
                                    />
                                </Badge>
                            </IconButton>
                        </Box>
                        <StyledFab
                            color={priceUpdate == "1" ? "secondary" : "info"}
                            aria-label="add"
                            onClick={() => {
                                myNav("price-sync");
                            }}
                        >
                            <PriceChangeIcon fontSize="large" />
                        </StyledFab>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "40%",
                            }}
                        >
                            <IconButton
                                onClick={() => myNav("statistics")}
                                color="inherit"
                            >
                                <BarChartIcon
                                    fontSize="large"
                                    color={
                                        statistics == "1" ? "secondary" : "info"
                                    }
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("orders")}
                                color="inherit"
                            >
                                <LocalMallIcon
                                    fontSize="large"
                                    color={orders == "1" ? "secondary" : "info"}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => myNav("profile")}
                                color="inherit"
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color={
                                        profile == "1" ? "secondary" : "info"
                                    }
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
        </StyledBox>
    );
};

export default Footer;
