import {
    Autocomplete,
    Box,
    Button,
    Card,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { customToast } from "../public_components/Toast/CustomToast";
import { IconButton } from "@mui/material";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ClearIcon from "@mui/icons-material/Clear";
import orderSlice from "../../store/slices/order";
import { OrderStatus, PaymentStatus } from "../../utils/orderUtils";

const defaultProps = {
    options: Object.values(OrderStatus).filter((v) => isNaN(Number(v))),
    getOptionLabel: (option: OrderStatus) => option,
};
const defaultPropsPayment = {
    options: Object.values(PaymentStatus).filter((v) => isNaN(Number(v))),
    getOptionLabel: (option: PaymentStatus) => option,
};

export default function OrderManagement() {
    const dispatch = useAppDispatch();
    const [orderId, setOrderId] = useState<string>("");
    const [currentOrder, setCurrentOrder] = useState<any>(null);
    const [orderStatus, setOrderStatus] = useState<any>("");
    const [orderPaymentStatus, setOrderPaymentStatus] = useState<any>("");
    const [paidAmount, setPaidAmount] = useState<number>(0);
    const [orderUsername, setOrderUsername] = useState<any>("");
    const [orderUserContact, setOrderUserContact] = useState<any>("");
    const [advanceJSONText, setAdvanceJSONText] = useState<string>("");
    const [isAdvanceMode, setIsAdvanceMode] = useState<boolean>(false);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("orders", "1");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("master_item", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
    }, []);

    useEffect(() => {
        clearAll();
        if (currentOrder?.userData?.display_name) {
            setOrderUsername(currentOrder?.userData?.display_name);
        }
        if (currentOrder?.userData?.contact_number) {
            setOrderUserContact(currentOrder?.userData?.contact_number);
        }
        if (currentOrder?.order_status) {
            setOrderStatus(currentOrder?.order_status);
        }
        if (currentOrder?.payment_status) {
            setOrderPaymentStatus(currentOrder?.payment_status);
        }
        if (currentOrder) {
            const o = currentOrder;
            delete o?.userData;
            delete o?.belongs_to;
            delete o?.delivery_driver_ref;
            delete o?.items;
            setAdvanceJSONText(JSON.stringify(o));
        }
    }, [currentOrder]);

    useEffect(() => {
        if (isAdvanceMode) {
            customToast("Advanced Mode Enabled !", "error");
        }
    }, [isAdvanceMode]);

    const clearAll = () => {
        setOrderStatus("");
        setIsAdvanceMode(false);
        setAdvanceJSONText("");
    };

    const clearUser = () => {
        setOrderId("");
        clearAll();
        setCurrentOrder(null);
    };

    const handleFindOrder = async (isRefresh = false) => {
        if (orderId === "") {
            customToast("Empty input!", "error");
            return;
        }
        const result: any = await dispatch(orderSlice.fetchOrder(orderId));
        console.log(result?.payload, orderId);
        if (result?.payload?.isError) {
            customToast(result?.payload?.message, "error");
            return;
        }
        if (!isRefresh) customToast(result?.payload?.message, "info");
        setCurrentOrder(result?.payload?.result);
    };

    const toggleAdvanceMode = async () => {
        if (isAdvanceMode) {
            setIsAdvanceMode(!isAdvanceMode);
        } else {
            const x = window.prompt("Password");
            if (x === "<adm!n>") {
                setIsAdvanceMode(!isAdvanceMode);
            } else {
                setIsAdvanceMode(false);
            }
        }
    };

    const updateOrder = async () => {
        const payload = {
            orderData: currentOrder,
            updatedData: {
                orderStatus: orderStatus,
                paymentStatus: orderPaymentStatus,
                paidAmount,
            },
        };
        const result: any = await dispatch(
            orderSlice.updateAdminOrderDetails(payload)
        );
        if (result?.payload?.isError === false) {
            handleFindOrder(true);
        }
    };
    const updateAdvanceOrder = async () => {
        try {
            const orderJSONObject = JSON.parse(advanceJSONText);
            console.log("Obj: ", orderJSONObject);
            const result: any = await dispatch(
                orderSlice.updateAdminAdvanceUpdateOrderDetails({
                    orderId: currentOrder?.id,
                    orderJSONObject,
                })
            );
            if (result?.payload?.isError === false) {
                handleFindOrder(true);
            }
        } catch (error) {
            customToast("Error in JSON object", "error");
        }
    };

    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                    margin: 1,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: "center",
                        p: 1,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                            textAlign={"center"}
                        >
                            Enter Order ID
                        </Typography>
                        <TextField
                            value={orderId}
                            fullWidth
                            onChange={(event) => {
                                setOrderId(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearUser}>
                                            <ClearIcon color="secondary" />
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            onClick={() => handleFindOrder()}
                                            sx={{ borderRadius: 100 }}
                                        >
                                            <PersonSearchIcon />
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    ></Grid>
                </Grid>
            </Card>
            {currentOrder ? (
                <Card
                    sx={{
                        backgroundColor: "background.default",
                        borderRadius: 0,
                        margin: 1,
                    }}
                    elevation={0}
                >
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            p: 1,
                        }}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                                textAlign={"center"}
                            >
                                Current Order
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={orderUsername}
                                fullWidth
                                label="Username"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Contact Number"
                                value={orderUserContact}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="DB id"
                                value={currentOrder?.id}
                                fullWidth
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Autocomplete
                                {...defaultProps}
                                id="controlled-demo"
                                value={orderStatus}
                                onChange={(
                                    event: any,
                                    newValue: string | null
                                ) => {
                                    setOrderStatus(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Order Status"
                                        fullWidth
                                        helperText={
                                            orderStatus !==
                                            currentOrder?.order_status
                                                ? "*Order Status Changed"
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                {...defaultPropsPayment}
                                id="controlled-demo"
                                value={orderPaymentStatus}
                                onChange={(
                                    event: any,
                                    newValue: string | null
                                ) => {
                                    setOrderPaymentStatus(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Payment Status"
                                        fullWidth
                                        helperText={
                                            orderPaymentStatus !==
                                            currentOrder?.payment_status
                                                ? "*Role Changed"
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Paid Amount"
                                type="number"
                                value={paidAmount.toString()}
                                onChange={(event) => {
                                    setPaidAmount(+event?.target?.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            alignItems="center"
                            justifyContent={"flex-end"}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={updateOrder}
                                fullWidth
                                sx={{ mt: 1, borderRadius: 50 }}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            alignItems="center"
                            justifyContent={"flex-end"}
                            mb={2}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                onClick={toggleAdvanceMode}
                                fullWidth
                                sx={{ mt: 1, borderRadius: 50 }}
                            >
                                !
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            ) : null}
            {isAdvanceMode ? (
                <Card
                    sx={{
                        backgroundColor: "background.default",
                        borderRadius: 0,
                        margin: 1,
                        mb: 10,
                    }}
                    elevation={0}
                >
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            p: 1,
                        }}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                                textAlign={"center"}
                            >
                                Advanced Edit
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            alignItems="center"
                            justifyContent={"flex-end"}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                onClick={updateAdvanceOrder}
                                fullWidth
                                sx={{ borderRadius: 50 }}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="JSON Object"
                                value={advanceJSONText}
                                onChange={(event) => {
                                    setAdvanceJSONText(event?.target?.value);
                                }}
                                fullWidth
                                multiline
                                minRows={4}
                                maxRows={100}
                            />
                        </Grid>
                    </Grid>
                </Card>
            ) : null}
        </Box>
    );
}
