import { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MapInput from "../public_components/maps/MapInput";
import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import { MuiTelInput } from "mui-tel-input";
import { customToast } from "../public_components/Toast/CustomToast";

export default function WorkerProfile() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSlice.selectCurrentUser);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [profileImage, setProfileImage] = useState<string>("");
    const [location, setLocation] = useState<{ lat: number; lng: number }>();
    const [saveObject, setSaveObject] = useState<any>(null);

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "1");
        setName(user?.display_name);
        setEmail(user?.email);
        setPhone(user?.contact_number);
        setAddress(user?.registered_location?.address);
        setProfileImage(user?.profile_image_url);
        setLocation({
            lat: user?.registered_location.lat,
            lng: user?.registered_location.lng,
        });
    }, [user]);

    const handleMapChange = (location: any) => {
        if (!location) {
            return;
        }
        setSaveObject({ ...saveObject, location });
    };
    const handleUpdate = async () => {
        if (!saveObject) {
            return;
        }
        const result: any = await dispatch(
            userSlice.updateUserDetails(saveObject)
        );
        if (result?.payload.isError === true) {
            customToast(result?.payload?.message, "error");
            return;
        }
        customToast(result?.payload?.message, "success");
    };

    return (
        <>
            <Box
                sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    minHeight: "100vh",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={profileImage}
                        sx={{ width: 80, height: 80 }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        mb: 2,
                    }}
                >
                    <TextField
                        color="info"
                        variant="standard"
                        focused
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                            setSaveObject({
                                ...saveObject,
                                display_name: event.target.value,
                            });
                        }}
                        label="Name"
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        color="info"
                        variant="standard"
                        focused
                        disabled
                        value={email}
                        label="Email"
                        sx={{ mb: 2 }}
                    />
                    <MuiTelInput
                        value={phone}
                        onChange={(newValue) => {
                            setPhone(newValue);
                            setSaveObject({
                                ...saveObject,
                                contact_number: newValue,
                            });
                        }}
                        color="info"
                        variant="standard"
                        label="Phone"
                        focused
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        color="info"
                        variant="standard"
                        focused
                        value={address}
                        onChange={(event) => {
                            setAddress(event.target.value);
                            setSaveObject({
                                ...saveObject,
                                address: event.target.value,
                            });
                        }}
                        label="Address"
                        sx={{ mb: 2 }}
                        maxRows={4}
                        multiline
                    />
                    <MapInput
                        lat={location?.lat}
                        lng={location?.lng}
                        onChange={handleMapChange}
                    />
                </Box>
            </Box>
        </>
    );
}
