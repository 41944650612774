import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    Chip,
    Container,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch, useAppSelector } from "../../hooks";
import orderSlice from "../../store/slices/order";
import { useNavigate } from "react-router-dom";
import ongoing_logo from "../../assets/images/ongoing.png";
import completed_logo from "../../assets/images/completed.png";
import MapIcon from "@mui/icons-material/Map";
import _ from "lodash";
import { customToast } from "../public_components/Toast/CustomToast";
import { OrderStatus } from "../../utils/orderUtils";

let navigationInterval: any = null;
let navigationAccuracyThreshold: number = 50;

const OrderItem = ({ item, isLoading, index }: any) => {
    const navigate = useNavigate();

    if (isLoading) {
        return <CircularProgress color="secondary" />;
    }

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
            onClick={() => {
                navigate(item?.id);
            }}
        >
            <Grid
                container
                sx={{
                    m: 2,
                }}
            >
                <Grid item xs={6}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {item?.sort_order_id}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>{item?.order_status}</strong>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {item?.order_status === OrderStatus.Delivered ? (
                        <Chip
                            label={item?.delivery_order_num}
                            color={"success"}
                        />
                    ) : (
                        <Chip
                            label={item?.delivery_order_num}
                            color={index === 0 ? "secondary" : "primary"}
                        />
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    isOrderLoading: boolean;
    setIsLoading: any;
    tab: string;
}
const DriverOrderPage = () => {
    const dispatch = useAppDispatch();
    const orders = useAppSelector(orderSlice.selectOrders);
    const [items, setItems] = useState<any[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true);
    const [isLowAccuracy, setIsLowAccuracy] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [tab, setTab] = useState<string>("ongoing");
    const [value, setValue] = useState<string>("ongoing");
    const [routeLink, setRouteLink] = useState<string>("");
    const [position, setPosition] = useState<any>({
        latitude: 6.8,
        longitude: 79.9,
    });

    const [accuracy, setAccuracy] = useState<number>(1000);

    const handleChange = async (
        event: React.SyntheticEvent,
        newValue: string
    ) => {
        setIsLoading(true);
        dispatch(orderSlice.clearOrders());
        setValue(newValue);
        setTab(newValue);
    };

    useEffect(() => {
        window.sessionStorage.setItem("market", "1");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [tab]);
    useEffect(() => {
        if (!orders) {
            return;
        }
        setItems(orders);
        getRouteLink(orders);
        setIsLoading(false);
    }, [orders, accuracy]);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigationInterval = navigator.geolocation.watchPosition(
                function (position) {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setAccuracy(position.coords.accuracy);
                },
                null,
                { enableHighAccuracy: true, timeout: 10000 }
            );
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);

    // Oncomponent unmount
    useEffect(
        () => () => {
            if (navigationInterval) {
                navigator.geolocation.clearWatch(navigationInterval);
            }
        },
        []
    );

    const fetchOrders = async () => {
        setIsLoading(true);
        await dispatch(orderSlice.subscribeDriverToOrders(tab));
    };

    const handleOpen = (data: any) => {
        setIsOrderLoading(true);
        dispatch(orderSlice.syncWorkerSelectedOrder(data.id));
        setOpen(true);
    };
    const getRouteLink = (orders: any) => {
        if (accuracy > navigationAccuracyThreshold) {
            setIsLowAccuracy(true);
        } else {
            setIsLowAccuracy(false);
        }
        let finalCoords = [];
        for (const order of orders) {
            const coords = [order?.end_location?.lat, order?.end_location?.lng];
            if (!inArray(finalCoords, coords)) {
                finalCoords.push(coords);
            }
        }
        let linkRoute = `https://www.google.com/maps/dir/${position?.latitude} ${position?.longitude}/`;
        for (const fCoord of finalCoords) {
            linkRoute += `${fCoord[0]} ${fCoord[1]}/`;
        }
        setRouteLink(linkRoute);
    };

    function inArray(a: Array<number[]>, b: Array<number>): boolean {
        for (const coord of a) {
            if (coord[0] === b[0] && coord[1] === b[1]) {
                return true;
            }
        }
        return false;
    }

    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <div>
                <Box
                    sx={{
                        backgroundColor: "background.paper",
                        minHeight: "90vh",
                        height: "100%",
                    }}
                >
                    <Container>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                textColor="secondary"
                                indicatorColor="secondary"
                                scrollButtons="auto"
                            >
                                <Tab
                                    icon={
                                        <img
                                            src={ongoing_logo}
                                            alt="logo"
                                            className="responsive-img"
                                        />
                                    }
                                    label="Ongoing"
                                    value="ongoing"
                                />
                                <Tab
                                    icon={
                                        <img
                                            src={completed_logo}
                                            alt="logo"
                                            className="responsive-img"
                                        />
                                    }
                                    label="shipped"
                                    value="shipped"
                                />
                            </Tabs>
                        </Box>
                    </Container>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                            m: 3,
                            pb: 10,
                        }}
                    >
                        {tab === "ongoing" && (
                            <Card
                                sx={{
                                    backgroundColor: "background.default",
                                    borderRadius: 0,
                                    mb: 2,
                                }}
                                elevation={0}
                                onClick={() => {
                                    console.log("Open Maps");
                                    if (isLowAccuracy) {
                                        customToast(
                                            "Warning: Low GPS accuracy!",
                                            "warning"
                                        );
                                    }
                                    if (
                                        routeLink !== "" &&
                                        orders?.length > 0
                                    ) {
                                        window.open(routeLink);
                                    } else {
                                        customToast("No orders !", "info");
                                    }
                                }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        m: 2,
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            sx={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            Open Route
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            Accuracy: {accuracy?.toFixed(2)} m
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Chip
                                            label={0}
                                            icon={<MapIcon />}
                                            color={
                                                accuracy >
                                                navigationAccuracyThreshold
                                                    ? "error"
                                                    : "success"
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                        {isLoading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    m: 3,
                                }}
                            >
                                <CircularProgress color="secondary" />
                            </Box>
                        ) : items?.length === 0 && !isLoading ? (
                            <Typography
                                variant="h6"
                                color={"secondary"}
                                textAlign={"center"}
                            >
                                No Orders!
                            </Typography>
                        ) : (
                            items?.map((row: any, index: any) => {
                                return (
                                    <OrderItem
                                        key={index}
                                        index={index}
                                        item={row}
                                        isLoading={isLoading}
                                        handleOpen={handleOpen}
                                    />
                                );
                            })
                        )}
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default DriverOrderPage;
