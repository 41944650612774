import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import { MuiTelInput } from "mui-tel-input";
import { customToast } from "../public_components/Toast/CustomToast";
import configSlice from "../../store/slices/config";
import SyncIcon from "@mui/icons-material/Sync";

export default function AdminProfile() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSlice.selectCurrentUser);
    const configFromDb = useAppSelector(configSlice.selectConfig);
    const serviceonfigFromDb = useAppSelector(configSlice.selectServiceConfig);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [profileImage, setProfileImage] = useState<string>("");
    const [saveObject, setSaveObject] = useState<any>(null);
    const [isOrderRestricted, setIsOrderRestricted] = useState<boolean>();
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [config, setConfig] = useState(null);
    const [bankDetails, setBankDetails] = useState<any>(null);

    useEffect(() => {
        const getConfig = async () => {
            await dispatch(configSlice.getConfig());
            await dispatch(configSlice.getServiceConfig());
        };
        getConfig();
    }, []);

    useEffect(
        () => () => {
            const clearConfig = async () => {
                await dispatch(configSlice.clearConfig());
                await dispatch(configSlice.clearServiceConfig());
            };
            clearConfig();
        },
        []
    );

    useEffect(() => {
        const getConfig = async () => {
            if (!configFromDb || isOrderRestricted === undefined) {
                return;
            }
            if (configFromDb.is_ordering_restricted === isOrderRestricted) {
                return;
            }

            await dispatch(
                configSlice.updateIsOrderRestricted(isOrderRestricted)
            );
            customToast("Restricted order changed", "info");
        };
        getConfig();
    }, [isOrderRestricted]);

    useEffect(() => {
        if (!configFromDb) {
            return;
        }
        const { is_ordering_restricted, restrict_time_range, bank_details } =
            configFromDb;
        const { start, end } = restrict_time_range;
        setConfig(configFromDb);
        setIsOrderRestricted(is_ordering_restricted);
        setStartTime(start);
        setEndTime(end);
        setBankDetails(bank_details);
    }, [configFromDb]);
    useEffect(() => {
        if (!serviceonfigFromDb) {
            return;
        }
        const { helpline } = serviceonfigFromDb;
        setPhone(helpline);
    }, [serviceonfigFromDb]);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "1");
        window.sessionStorage.setItem("price-update", "0");
        setName(user?.display_name);
        setEmail(user?.email);
        setAddress(user?.registered_location?.address);
        setProfileImage(user?.profile_image_url);
    }, [user]);

    const handleUpdate = async () => {
        if (!saveObject) {
            return;
        }
        const result: any = await dispatch(
            userSlice.updateUserDetails(saveObject)
        );
        if (result?.payload.isError === true) {
            customToast(result?.payload?.message, "error");
            return;
        }
        customToast(result?.payload?.message, "success");
    };

    const handleTime = async () => {
        await dispatch(
            configSlice.updateRestrictTime({
                start: startTime,
                end: endTime,
            })
        );
        customToast("Restricted Time  Updated", "info");
    };

    const handleBankDetailsChange = async (data: string, value: string) => {
        let newBankDetails = { ...bankDetails };
        if (data === "number") {
            newBankDetails = { ...newBankDetails, number: value };
        }
        if (data === "name") {
            newBankDetails = { ...newBankDetails, name: value };
        }
        if (data === "bank") {
            newBankDetails = { ...newBankDetails, bank: value };
        }
        if (data === "branch") {
            newBankDetails = { ...newBankDetails, branch: value };
        }
        setBankDetails(newBankDetails);
    };

    return (
        <>
            <Box
                sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={profileImage}
                        sx={{ width: 80, height: 80 }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                    }}
                >
                    <TextField
                        color="info"
                        variant="standard"
                        focused
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                            setSaveObject({
                                ...saveObject,
                                display_name: event.target.value,
                            });
                        }}
                        label="Name"
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        color="info"
                        variant="standard"
                        focused
                        disabled
                        value={email}
                        label="Email"
                        sx={{ mb: 2 }}
                    />
                    <MuiTelInput
                        value={phone}
                        onChange={(newValue) => {
                            setPhone(newValue);
                        }}
                        color="info"
                        variant="standard"
                        label="Helpline Number"
                        focused
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        color="secondary"
                                        sx={{ p: "10px" }}
                                        aria-label="directions"
                                        onClick={async () => {
                                            await dispatch(
                                                configSlice.updateHelpline(
                                                    phone
                                                )
                                            );
                                        }}
                                    >
                                        <SyncIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        color="info"
                        variant="standard"
                        focused
                        value={address}
                        onChange={(event) => {
                            setAddress(event.target.value);
                            setSaveObject({
                                ...saveObject,
                                address: event.target.value,
                            });
                        }}
                        sx={{ mb: 2 }}
                        label="Address"
                        maxRows={4}
                        multiline
                    />
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                            borderRadius: 0,
                        }}
                        elevation={0}
                    >
                        <Grid
                            container
                            sx={{
                                justifyContent: "center",
                                p: 1,
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color="text.primary"
                                    sx={{
                                        fontWeight: 600,
                                    }}
                                    textAlign={"center"}
                                >
                                    Update Config
                                </Typography>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        onChange={(event) => {
                                            setStartTime(event?.target?.value);
                                        }}
                                        onBlur={handleTime}
                                        color="info"
                                        focused
                                        value={startTime}
                                        label="Start"
                                        sx={{ mb: 2 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        onChange={(event) => {
                                            setEndTime(event?.target?.value);
                                        }}
                                        onBlur={handleTime}
                                        color="info"
                                        focused
                                        value={endTime}
                                        label="End"
                                        sx={{ mb: 2 }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {isOrderRestricted !== undefined ? (
                                    <Switch
                                        checked={isOrderRestricted}
                                        color="secondary"
                                        onChange={(event) => {
                                            setIsOrderRestricted(
                                                event?.target?.checked
                                            );
                                        }}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Card>
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                            borderRadius: 0,
                            mb: 10,
                        }}
                        elevation={0}
                    >
                        {bankDetails ? (
                            <Grid
                                container
                                sx={{
                                    justifyContent: "center",
                                    p: 1,
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                        textAlign={"center"}
                                    >
                                        Update Bank Details
                                    </Typography>
                                </Grid>
                                <Grid container spacing={1} mt={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            onChange={(event) => {
                                                handleBankDetailsChange(
                                                    "number",
                                                    event?.target?.value
                                                );
                                            }}
                                            color="info"
                                            focused
                                            value={bankDetails?.number}
                                            label="Start"
                                            sx={{ mb: 2 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            onChange={(event) => {
                                                handleBankDetailsChange(
                                                    "name",
                                                    event?.target?.value
                                                );
                                            }}
                                            color="info"
                                            focused
                                            value={bankDetails?.name}
                                            label="End"
                                            sx={{ mb: 2 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            onChange={(event) => {
                                                handleBankDetailsChange(
                                                    "bank",
                                                    event?.target?.value
                                                );
                                            }}
                                            color="info"
                                            focused
                                            value={bankDetails?.bank}
                                            label="End"
                                            sx={{ mb: 2 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            onChange={(event) => {
                                                handleBankDetailsChange(
                                                    "branch",
                                                    event?.target?.value
                                                );
                                            }}
                                            color="info"
                                            focused
                                            value={bankDetails?.branch}
                                            label="End"
                                            sx={{ mb: 2 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign={"center"}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={async () => {
                                                await dispatch(
                                                    configSlice.updateBankDetails(
                                                        bankDetails
                                                    )
                                                );
                                                customToast(
                                                    "Bank Details Updated",
                                                    "info"
                                                );
                                            }}
                                        >
                                            Update Bank Details
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Card>
                </Box>
            </Box>
        </>
    );
}
