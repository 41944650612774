import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useAppDispatch, useAppSelector } from "../../hooks";
import cartSlice from "../../store/slices/cart";
import orderSlice from "../../store/slices/order";
import { useNavigate } from "react-router-dom";
import userSlice from "../../store/slices/user";
import { customToast } from "../public_components/Toast/CustomToast";
import configSlice from "../../store/slices/config";
import { PaymentMethod } from "../../utils/orderUtils";

const Payment = ({ handlePlaceOrder, setActiveStep }: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(cartSlice.selectCart);
    const user = useAppSelector(userSlice.selectCurrentUser);
    const config = useAppSelector(configSlice.selectConfig);
    const paymentMethodInStore = useAppSelector(orderSlice.selectPaymentMethod);

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bankDetails, setBankDetails] = useState<any>(null);
    const [paymentMethod, setPaymentMethod] = React.useState<string>(
        PaymentMethod.Cash
    );
    const [paymentMethodChipText, setPaymentMethodChipText] =
        React.useState<string>(PaymentMethod.Cash);

    useEffect(() => {
        const getConfig = async () => {
            await dispatch(configSlice.getConfig());
        };
        getConfig();
    }, []);

    useEffect(() => {
        let total = 0;
        const itemsArray = cartItems.map((item: any) => {
            const cartObject = { ...item.item, quantity: item.cartQuantity };
            total += Math.round(item.item.price * item.cartQuantity);
            return cartObject;
        });
        setTotalPrice(total);
    }, [cartItems]);

    useEffect(() => {
        if (!config) {
            return;
        }
        setBankDetails(config?.bank_details);
    }, [config]);

    useEffect(() => {
        if (!paymentMethod) {
            return;
        }
        const paymentType: any = paymentMethod;
        dispatch(orderSlice.setPaymentMethod(paymentType));
    }, [paymentMethod]);

    useEffect(() => {
        if (!paymentMethodInStore) {
            return;
        }
        if (paymentMethodInStore === PaymentMethod.Bank) {
            setPaymentMethodChipText("BANK TRANSFER");
        }
        if (paymentMethodInStore === PaymentMethod.Cash) {
            setPaymentMethodChipText("CASH");
        }
    }, [paymentMethodInStore]);

    async function onCheckout() {
        setIsLoading(true);
        const result = await dispatch(orderSlice.addBulkOrder());
        const resultData = result?.payload;
        if (resultData.isError === true) {
            customToast(
                `Error Occured! ${resultData?.message?.message || ""}`,
                "error"
            );
            if (
                resultData?.message?.message?.startsWith(
                    "Price Mismatch Reorder the item"
                )
            ) {
                updateCart(resultData?.message);
                setActiveStep(0);
            }
            setIsLoading(false);
            return;
        }
        dispatch(cartSlice.clearCart());
        setIsLoading(false);
        customToast(result?.payload?.message?.text, "success");
        navigate(`/${user?.role}/orders/${result?.payload?.message?.order_id}`);
        handlePlaceOrder();
    }

    const updateCart = (updatedItems: any) => {
        let newCart = cartItems.map((item: any) => {
            if (item?.item?.id === updatedItems.item) {
                const updatedItem = {
                    ...item?.item,
                    price: updatedItems?.item_bulk_price,
                };
                customToast(`Price Updated for ${item?.item?.name}`, "warning");
                const updatedCartItem = { ...item, item: updatedItem };
                return updatedCartItem;
            }
            return item;
        });
        dispatch(cartSlice.setCart(newCart));
    };

    const handleDevices = (
        event: React.MouseEvent<HTMLElement>,
        paymentType: string
    ) => {
        if (paymentType !== null) {
            setPaymentMethod(paymentType);
        }
    };

    return (
        <>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <Button
                    variant="contained"
                    color="success"
                    onClick={onCheckout}
                    fullWidth
                >
                    Place Order
                </Button>
            )}
            <Card>
                <CardContent>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        Delivery Address
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        {user?.registered_location?.address}
                    </Typography>
                    {/* <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Street name
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Town
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            District
          </Typography> */}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    {/* <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Sub Total: Rs {totalPrice.toFixed(2)}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            5% Service Fee: Rs {((totalPrice * 5) / 100).toFixed(2)}
          </Typography> */}
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Net Amount:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                textAlign={"right"}
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                Rs {totalPrice?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <ToggleButtonGroup
                                value={paymentMethod}
                                onChange={handleDevices}
                                exclusive
                            >
                                <ToggleButton
                                    value={PaymentMethod.Cash}
                                    aria-label={PaymentMethod.Cash}
                                >
                                    <MoneyIcon color="secondary" />
                                </ToggleButton>
                                <ToggleButton
                                    value={PaymentMethod.Bank}
                                    aria-label={PaymentMethod.Bank}
                                >
                                    <AccountBalanceIcon color="secondary" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <Chip
                                label={paymentMethodChipText}
                                variant="outlined"
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                {paymentMethod === PaymentMethod.Bank ? (
                    <CardContent>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Bank Account Details
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            {bankDetails?.number}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            {bankDetails?.name}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            {bankDetails?.bank}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            {bankDetails?.branch}
                        </Typography>
                    </CardContent>
                ) : null}
            </Card>
        </>
    );
};

export default Payment;
