import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import cartSlice from "../../store/slices/cart";
import { useNavigate, useParams } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PaymentIcon from "@mui/icons-material/Payment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import orderSlice from "../../store/slices/order";
import { generateSixDigitNumber } from "../../utils/orderUtils";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {},
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {},
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.primary.light,
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage:
            "linear-gradient( 136deg, #c66900 0%,#263238 50%, #ff9800 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage:
            "linear-gradient( 136deg, #c66900 0%,#263238 50%, #ff9800 100%)",
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        pending: <ShoppingCartIcon sx={{ width: 20, height: 20 }} />,
        processing: <AnalyticsIcon sx={{ width: 20, height: 20 }} />,
        ready: <PaymentIcon sx={{ width: 20, height: 20 }} />,
        shipped: <AssignmentTurnedInIcon sx={{ width: 20, height: 20 }} />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = [
    "Recieved to the Facility",
    "packed and ready to ship",
    "Handed Over to Delivery Partner",
    "Delivered",
];

const OrderItem = ({ item }: any) => {
    const { name, image, unit_price, quantity, unit } = item;

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    ml: 2,
                }}
            >
                <Grid item xs={4}>
                    <CardMedia component="img" image={image} alt={name.en} />
                </Grid>
                <Grid item xs={6}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {name.en}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Rs {unit_price.toFixed(2)}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            {quantity} {unit}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

// Todo: Add price Update on load
const RetailPastOrder = () => {
    const navigate = useNavigate();
    const urlParams = useParams();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(cartSlice.selectCart);
    const [items, setItems] = useState<any[]>([]);
    const [order, setOrder] = useState<any>();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [activeStep, setActiveStep] = React.useState(0);
    // const [stepsData, setStepsData] = React.useState<{ [index: number]: { date: string; status: string } }>();
    const stepsData: { [index: number]: { date: string; status: string } } = {
        0: {
            date: "dd/mm/yyyy hh:mm",
            status: "Recieved to the Facility and being processed to ship",
        },
        1: {
            date: "dd/mm/yyyy hh:mm",
            status: "Your order is packed and ready to ship",
        },
        2: {
            date: "dd/mm/yyyy hh:mm",
            status: "Your order is handed over to delivery person. He will contact you soon",
        },
        3: {
            date: "dd/mm/yyyy hh:mm",
            status: "Order successfully delivered",
        },
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        fetchOrder();
    }, [urlParams]);
    useEffect(() => {
        if (!order) {
            return;
        }
        setItems(order?.items);
    }, [order]);
    useEffect(() => {
        if (cartItems.length === 0) {
            // navigate("/vendor/market");
        }
        let total = 0;
        const itemsArray = cartItems.map((item: any) => {
            const cartObject = { ...item.item, quantity: item.cartQuantity };
            total += Math.round(item.item.price * item.cartQuantit);
            return cartObject;
        });
        setItems(itemsArray);
        setTotalPrice(total);
    }, [cartItems]);

    async function fetchOrder() {
        if (!urlParams?.id) {
            return;
        }
        const order: any = await dispatch(orderSlice.fetchOrder(urlParams?.id));
        if (order?.payload?.isError) {
            return;
        }
        setOrder(order?.payload?.result);
    }

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Stepper
                activeStep={activeStep}
                connector={<ColorlibConnector />}
                orientation="vertical"
            >
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel
                                StepIconComponent={ColorlibStepIcon}
                                optional={
                                    <>
                                        <Typography variant="caption">
                                            {stepsData[index].date}
                                        </Typography>
                                        <Typography variant="body2">
                                            {stepsData[index].status}
                                        </Typography>
                                    </>
                                }
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Card>
                <CardContent>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        Delivery Address
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        {order?.end_location?.address}
                    </Typography>
                    {/* <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Street name
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Town
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            District
          </Typography> */}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    {/* <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            Sub Total: Rs {totalPrice.toFixed(2)}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              fontWeight: 500,
            }}
          >
            5% Service Fee: Rs {((totalPrice * 5) / 100).toFixed(2)}
          </Typography> */}
                    <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        Net Amount: Rs {order?.total_price}
                    </Typography>
                </CardContent>
            </Card>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    m: 0,
                    pt: 2,
                }}
            >
                {items.length === 0 ? (
                    <Typography variant="h6" color={"secondary"}>
                        No items in cart !
                    </Typography>
                ) : (
                    ""
                )}
                {items.map((item: any) => (
                    <Box key={generateSixDigitNumber()}>
                        <OrderItem item={item} />
                        <Box sx={{ p: 1 }} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default RetailPastOrder;
