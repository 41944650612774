import selectors from "./selectors";
import state from "./state";
import { actions } from "./crateSlice";

const crateSlice = {
    ...selectors,
    ...actions,
    ...state,
};
export default crateSlice;
