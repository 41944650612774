export const getDefaultState = () => {
    return {
        currentUser: null,
        test: 0,
        isUserVerified: false,
        driverArray: [],
    };
};

const state = { ...getDefaultState() };

export default state;
