import { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FileUploader from "../public_components/fileUploader/FileUploader";
import { useAppDispatch, useAppSelector } from "../../hooks";
import configSlice from "../../store/slices/config";
import crateSlice from "../../store/slices/crate";
import { Dimensions, Prefix } from "inventory-item";

const RegisterMasterCrate = () => {
    const navigate = useNavigate();
    const myNav = (path: string) => {
        navigate(path);
    };
    const dispatch = useAppDispatch();
    const configFromDb = useAppSelector(configSlice.selectInventoryConfig);
    const [crateVendorList, setCrateVendorList] = useState<any>([]);
    const [crateSizeList, setCrateSizeList] = useState<any>([]);
    const [crateColorList, setCrateColorList] = useState<any>([]);

    const [file, setFile] = useState<File[]>([]);
    const [crateVendor, setCrateVendor] = useState<Prefix>({
        name: "",
        prefix: "",
    });
    const [crateSize, setCrateSize] = useState<Prefix>({
        name: "",
        prefix: "",
    });
    const [crateColor, setCrateColor] = useState<Prefix>({
        name: "",
        prefix: "",
    });
    const [model, setModel] = useState<string>("");

    const [weightCapacity, setWeightCapacity] = useState<number>(0);
    const [spaceCapacity, setSpaceCapacity] = useState<number>(0);
    const [crateWeight, setCrateWeight] = useState<number>(0);
    const [stackSize, setStackSize] = useState<number>(0);
    const [isNestable, setIsNestable] = useState<boolean>(false);
    const [cratePrefix, setCratePrefix] = useState<string>("");
    const [outerDimensions, setOuterDimensions] = useState<Dimensions>({
        l: 0,
        w: 0,
        h: 0,
    });
    const [innerDimensions, setInnerDimensions] = useState<Dimensions>({
        l: 0,
        w: 0,
        h: 0,
    });

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
        const getConfig = async () => {
            await dispatch(configSlice.getConfig());
        };
        getConfig();
    }, []);
    useEffect(() => {
        setCratePrefix(
            `FLCR${crateVendor?.prefix}${model}${crateSize?.prefix}${crateColor?.prefix}`
        );
    }, [crateVendor, model, crateSize, crateColor]);
    useEffect(() => {
        if (!configFromDb) {
            return;
        }
        const vendorSearchOptions = configFromDb.crate_vendors?.map(
            (vendor: any) => {
                const vendorWithLabel = {
                    ...vendor,
                    label: vendor?.name,
                };
                return vendorWithLabel;
            }
        );
        const sizeSearchOptions = configFromDb.crate_size_list?.map(
            (size: any) => {
                const sizeWithLabel = {
                    ...size,
                    label: size?.name,
                };
                return sizeWithLabel;
            }
        );
        const colorSearchOptions = configFromDb.crate_color_list?.map(
            (color: any) => {
                const sizeWithLabel = {
                    ...color,
                    label: color?.name,
                };
                return sizeWithLabel;
            }
        );
        setCrateSizeList(sizeSearchOptions);
        setCrateVendorList(vendorSearchOptions);
        setCrateColorList(colorSearchOptions);
    }, [configFromDb]);

    async function handleSubmit() {
        const final = {
            file: file,
            vendor: crateVendor,
            size: crateSize,
            color: crateColor,
            model: model,
            crate_prefix: cratePrefix,
            crate_weight: crateWeight,
            crate_weight_capacity: weightCapacity,
            crate_space_capacity: spaceCapacity,
            stack_size: stackSize,
            is_nestable: isNestable,
            outer_dimensions: outerDimensions,
            inner_dimensions: innerDimensions,
        };
        console.log("Click Item", final);
        await dispatch(crateSlice.addMasterCrate(final));
    }

    return (
        <Box
            pt={[5, 5]}
            sx={{
                backgroundColor: "background.paper",
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 10,
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            gutterBottom
                            color="secondary"
                            textAlign={"center"}
                        >
                            Register Crate Type
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                "& > :not(style)": { m: 1, width: "25ch" },
                            }}
                        >
                            <FileUploader
                                buttonText="Upload Image"
                                images={file}
                                setImages={setFile}
                            ></FileUploader>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={crateVendorList}
                                sx={{ width: 300 }}
                                onChange={(event, value: any) => {
                                    setCrateVendor({
                                        name: value?.name,
                                        prefix: value?.prefix,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Vendor"
                                    />
                                )}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={crateSizeList}
                                sx={{ width: 300 }}
                                onChange={(event, value: any) => {
                                    setCrateSize({
                                        name: value?.name,
                                        prefix: value?.prefix,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Size" />
                                )}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={crateColorList}
                                sx={{ width: 300 }}
                                onChange={(event, value: any) => {
                                    setCrateColor({
                                        name: value?.name,
                                        prefix: value?.prefix,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Color" />
                                )}
                            />
                            <TextField
                                label="Crate Model"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                            />

                            <TextField
                                label="Crate Prefix"
                                disabled
                                value={cratePrefix}
                            />
                            <TextField
                                label="Crate Weight"
                                type="number"
                                value={crateWeight.toString()}
                                onChange={(e) =>
                                    setCrateWeight(+e.target.value)
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            kg
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label="Max Capacity"
                                type="number"
                                value={weightCapacity.toString()}
                                onChange={(e) =>
                                    setWeightCapacity(+e.target.value)
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            kg
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label="Max Capacity"
                                type="number"
                                value={spaceCapacity.toString()}
                                onChange={(e) =>
                                    setSpaceCapacity(+e.target.value)
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            Liters
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Stack size"
                                        type="number"
                                        value={stackSize.toString()}
                                        onChange={(e) =>
                                            setStackSize(+e.target.value)
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    Crates
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isNestable}
                                                color="secondary"
                                                onChange={(event) =>
                                                    setIsNestable(
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Nestable"
                                        sx={{ color: "white" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography color="secondary">
                                        Outer Dimensions
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Length"
                                        type="number"
                                        value={outerDimensions.l.toString()}
                                        onChange={(e) =>
                                            setOuterDimensions({
                                                ...outerDimensions,
                                                l: +e.target.value,
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mm
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Width"
                                        type="number"
                                        value={outerDimensions.w.toString()}
                                        onChange={(e) =>
                                            setOuterDimensions({
                                                ...outerDimensions,
                                                w: +e.target.value,
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mm
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Height"
                                        type="number"
                                        value={outerDimensions.h.toString()}
                                        onChange={(e) =>
                                            setOuterDimensions({
                                                ...outerDimensions,
                                                h: +e.target.value,
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mm
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography color="secondary">
                                        Inner Dimensions
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Length"
                                        type="number"
                                        value={innerDimensions.l.toString()}
                                        onChange={(e) =>
                                            setInnerDimensions({
                                                ...innerDimensions,
                                                l: +e.target.value,
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mm
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Width"
                                        type="number"
                                        value={innerDimensions.w.toString()}
                                        onChange={(e) =>
                                            setInnerDimensions({
                                                ...innerDimensions,
                                                w: +e.target.value,
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mm
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Height"
                                        type="number"
                                        value={innerDimensions.h.toString()}
                                        onChange={(e) =>
                                            setInnerDimensions({
                                                ...innerDimensions,
                                                h: +e.target.value,
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mm
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                variant="contained"
                                type="submit"
                                color="secondary"
                                onClick={handleSubmit}
                            >
                                Add Crate Type
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default RegisterMasterCrate;
