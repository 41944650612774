import React, { useEffect } from "react";
import {
    Box,
    TextField,
    Button,
    Link,
    Typography,
    InputAdornment,
    IconButton,
    CircularProgress,
} from "@mui/material";
import Logo from "../../assets/logo_V1_512.png";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch } from "../../hooks";
import userSlice from "../../store/slices/user";
import { customToast } from "../public_components/Toast/CustomToast";

export default function SignUp() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [displayName, setDisplayName] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        const getAuthenticatedUser = async () => {
            setIsLoading(true);
            const userCred = (await dispatch(userSlice.getRedirectedUserAuth()))
                .payload;
            if (userCred) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        };
        getAuthenticatedUser();
    }, [dispatch]);
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        navigate("/sign-in");
    }, [isAuthenticated]);

    const handleSubmit = async (event) => {
        setIsLoading(true);
        const userData = {
            displayName,
            username,
            password,
        };
        if (displayName === "" || username === "" || password === "") {
            customToast("Empty fields!", "error");
            setIsLoading(false);
            return;
        }
        const result = await dispatch(userSlice.saveUser(userData));
        setIsLoading(false);
        if (!result?.payload?.isError) {
            customToast(result?.payload?.message, "success");
            navigate("/sign-in");
        } else {
            customToast(result?.payload?.message, "error");
        }
        event.preventDefault();
    };

    const handleDisplaynameChange = (event) => {
        setDisplayName(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const InputBlock = () => {
        if (isLoading) {
            return (
                <>
                    <CircularProgress color="secondary" />
                </>
            );
        } else {
            return (
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: "background.default",
                        borderRadius: "20px",
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            label="Display Name"
                            type="text"
                            value={displayName}
                            onChange={handleDisplaynameChange}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            required
                            label="username"
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            sx={{ mt: 1, mb: 1 }}
                            required
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                            color="secondary"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={password}
                            onChange={handlePasswordChange}
                            variant="outlined"
                            fullWidth
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                            sx={{ mb: 3, mt: 3 }}
                        >
                            Signup
                        </Button>
                        <Link href="/sign-in" color="secondary" sx={{ mt: 5 }}>
                            Already have an Account ?
                        </Link>
                    </form>
                </Box>
            );
        }
    };

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "background.paper",
                height: "90vh",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: ["40%", "10%"],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mx: "auto",
                    mb: 5,
                }}
            >
                <img src={Logo} alt="logo" style={{ width: "100%" }} />
            </Box>
            {InputBlock()}

            <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "primary.dark",
                    width: "100%",
                    height: 80,
                    borderRadius: "20px 20px 0 0",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{ color: "#ffffff", fontSize: ["none", 12] }}
                >
                    © {new Date().getFullYear()} App Name. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
}
