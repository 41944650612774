import { onAuthStateChanged } from "firebase/auth";
import { auth } from "..";
import store from "../../../store";
import userSlice from "../../../store/slices/user";
import configSlice from "../../../store/slices/config";

export const onUserAuthChange = () => {
    onAuthStateChanged(auth, (user: any) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            const uid = user.uid;
            const isUserVerified = user.emailVerified;
            window.localStorage.setItem("user_id", uid);
            store.dispatch(userSlice.setIsUserVerified(isUserVerified));
            store.dispatch(configSlice.setIsLoadingProvider(false));
            // ...
        } else {
            // User is signed out
            store.dispatch(userSlice.clearUserVerified());
            window.localStorage.removeItem("user_id");
            store.dispatch(configSlice.setIsLoadingProvider(false));
            // ...
        }
    });
};
