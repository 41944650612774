import selectors from "./selectors";
import state from "./state";
import { actions } from "./cartSlice";

const cartSlice = {
    ...selectors,
    ...actions,
    ...state,
};
export default cartSlice;
