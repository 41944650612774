import selectors from "./selectors";
import state from "./state";
import { actions } from "./itemSlice";

const itemSlice = {
    ...selectors,
    ...actions,
    ...state,
};
export default itemSlice;
