import selectors from "./selectors";
import state from "./state";
import { actions } from "./userSlice";

const userSlice = {
    ...selectors,
    ...actions,
    ...state,
};
export default userSlice;
