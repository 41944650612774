import {
    DocumentReference,
    collection,
    deleteField,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";

import {
    browserLocalPersistence,
    createUserWithEmailAndPassword,
    getRedirectResult,
    sendEmailVerification,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signOut,
} from "firebase/auth";
import { auth, db, provider, userCollection } from "..";
import { RetailBuyer } from "user";
import { UserType } from "../../../utils/userUtils";
import userSlice from "../../../store/slices/user";
import cartSlice from "../../../store/slices/cart";
import orderSlice from "../../../store/slices/order";
import configSlice from "../../../store/slices/config";
import { customToast } from "../../../components/public_components/Toast/CustomToast";

let userListener: any = null;

const authHost = process.env.REACT_APP_AUTH_DOMAIN;
const authUrl = `https://${authHost}`;
const actionCodeSettings = {
    url: authUrl,
    handleCodeInApp: false,
};

/* 
Google Provider flow: go to auth link -> 
login with google account -> 
call redirect Url -> 
fetches logged in user -> 
sync local user states
 */
const user = {
    async signUpUserGoogle() {
        await setPersistence(auth, browserLocalPersistence);
        await signInWithRedirect(auth, provider);
    },
    async getRedirectedUserAuth() {
        const result = await getRedirectResult(auth);
        if (result) {
            const userDocData: RetailBuyer = {
                id: result.user.uid,
                role: UserType.RetailBuyer,
                display_name: result.user.displayName || "",
                email: result.user.email || "",
                username: result.user.email || "",
                contact_number: result.user.phoneNumber || "",
                created_at: serverTimestamp(),
                registered_location: {
                    address: "",
                    lat: 0,
                    lng: 0,
                },
                profile_image_url: result.user.photoURL || "",
            };
            const docRef = doc(db, "user", result.user.uid);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                await setDoc(docRef, userDocData);
            }
        }
        return result;
    },
    async signUpUser(userData: any) {
        try {
            const result = await createUserWithEmailAndPassword(
                auth,
                userData.username,
                userData.password
            );

            await sendEmailVerification(result.user, actionCodeSettings);

            const userDocData: RetailBuyer = {
                id: result.user.uid,
                role: UserType.RetailBuyer,
                display_name: userData.displayName || "",
                email: userData.username || "",
                username: userData.username || "",
                contact_number: result.user.phoneNumber || "",
                created_at: serverTimestamp(),
                registered_location: {
                    address: "",
                    lat: 0,
                    lng: 0,
                },
                profile_image_url: "",
            };

            const docRef = doc(db, "user", result.user.uid);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                await setDoc(docRef, userDocData);
                return {
                    isError: false,
                    message: "Success! Account verification Email is sent",
                };
            }
        } catch (error: any) {
            let errorMessage = "Error creating user";
            if (error.code === "auth/email-already-in-use") {
                errorMessage = "User already Exists";
            }
            return { isError: true, message: errorMessage };
        }
    },
    async sendVerifyLink() {
        try {
            if (!auth?.currentUser) {
                return {
                    isError: true,
                    message: "Error: No user with provided details",
                };
            }
            await sendEmailVerification(auth?.currentUser, actionCodeSettings);
            return {
                isError: false,
                message: "Account verification Email is sent.",
            };
        } catch (error: any) {
            let errorMessage = "Error occured. Try again later";
            if (error.code === "auth/too-many-requests") {
                errorMessage = "Too many requests. Please try again later";
            }
            return { isError: true, message: errorMessage };
        }
    },
    async fetchUserVerification() {
        try {
            if (!auth?.currentUser) {
                return { isError: true, message: "No User" };
            }
            return {
                isError: false,
                message: "SuccessFull",
                isUserVerified: auth?.currentUser?.emailVerified,
            };
        } catch (error) {
            return { isError: true, message: error };
        }
    },
    async loginUser(loginData: any) {
        try {
            await setPersistence(auth, browserLocalPersistence);
            if (!loginData.username && !loginData.password) {
                return { isError: true, message: "Error logging in user" };
            }
            const res = await signInWithEmailAndPassword(
                auth,
                loginData.username,
                loginData.password
            );

            if (!auth?.currentUser?.emailVerified) {
                const isVerified = auth?.currentUser?.emailVerified;
                return {
                    isError: true,
                    message: "Please check your Email and Verify",
                    isUserVerified: isVerified,
                };
            }
            window.localStorage.setItem("user_logged", "1");
            return { isError: false, message: res };
        } catch (error: any) {
            let errorMsg = "Error logging in user";
            if (error.code === "auth/wrong-password") {
                errorMsg = "Incorrect Email or Password";
            }
            if (error.code === "auth/invalid-email") {
                errorMsg = "Incorrect Email or Password";
            }
            if (error.code === "auth/invalid-credential") {
                errorMsg = "Invalid Email or Password";
            }
            return { isError: true, message: errorMsg };
        }
    },
    async logoutUser(dispatch: any) {
        try {
            await dispatch(configSlice.setIsLoading(true));
            // Clear Carts and Orders
            await dispatch(cartSlice.clearCart());
            await dispatch(orderSlice.clearOrders());
            await dispatch(orderSlice.clearWorkerSelectedOrderObject());

            // Clear Configs
            await dispatch(configSlice.clearVerifyLinkTime());

            // Clear User auth
            await this.unsubscribeUser();
            await signOut(auth);
            await dispatch(
                userSlice.clearUser(),
                userSlice.clearUserVerified(),
                configSlice.setIsLoading(false)
            );
            window.localStorage.setItem("user_logged", "0");
            window.localStorage.removeItem("persist:root");
            return { isError: false, message: auth?.currentUser };
        } catch (error) {
            console.error(error);
            return { isError: true, message: error };
        }
    },
    async subscribeUser(uid: string, dispatch: any) {
        dispatch(configSlice.setIsLoading(true));
        await user.unsubscribeUser();
        const docRef = doc(db, userCollection.path, uid);
        userListener = onSnapshot(
            docRef,
            async (document) => {
                const docData: any = document?.data();
                await dispatch(userSlice.setUser(docData));
                await dispatch(configSlice.setIsLoading(false));
            },
            (error) => {
                console.error(error);
                dispatch(configSlice.setIsLoading(false));
            }
        );
    },
    async unsubscribeUser() {
        if (userListener) {
            userListener();
        }
    },
    async getAllVendors() {
        const result = [];
        const q = query(userCollection, where("role", "==", UserType.Vendor));
        const querySnapshot = await getDocs(q);
        for (const vendorData of querySnapshot.docs) {
            result.push(vendorData.data());
        }
        return result;
    },
    async fetchUserByUsername(username: string, getState: any) {
        try {
            const { user } = getState();

            const q = query(userCollection, where("username", "==", username));
            const { docs }: any = await getDocs(q);
            const docData = docs[0].data();
            if (docs?.length > 0) {
                if (user?.currentUser?.id === docData?.id) {
                    return {
                        isError: true,
                        message: "No permission to change self",
                    };
                }
                const itemCodeArray: any[] = [];
                try {
                    const codeDocRef = collection(
                        docs[0]?.ref,
                        "item_code_map"
                    );
                    const subCollectionDocuments = await getDocs(codeDocRef);
                    const subcollectionDocumentData =
                        subCollectionDocuments?.docs[0]?.data();
                    for (const itemCode of subcollectionDocumentData?.item_doc_array) {
                        const { fl_code, name, vendor_code } = itemCode;
                        itemCodeArray.push({
                            id: fl_code,
                            fl_code,
                            name: { en: name },
                            vendor_code,
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
                const userData = {
                    ...docData,
                    item_codes: itemCodeArray,
                };
                return {
                    isError: false,
                    message: "User fetched",
                    result: userData,
                };
            } else {
                return {
                    isError: false,
                    message: "No User",
                    result: docData,
                };
            }
        } catch (error) {
            return {
                isError: true,
                message: "Error occured",
            };
        }
    },
    async fetchUserByReference(userRef: DocumentReference) {
        try {
            const docRef = await getDoc(userRef);
            return {
                isError: false,
                message: "Fetched User",
                result: docRef.data(),
            };
        } catch (error) {
            return {
                isError: true,
                message: "Error occured",
            };
        }
    },
    async updateAdminUserDetails(saveObject: any, getState: any) {
        try {
            let firestoreDataObject = {};
            const { user } = getState();
            if (user?.currentUser?.role !== "admin") {
                customToast("No permission", "error");
                return { isError: false, message: "No permission" };
            }
            const { updatedData, userData } = saveObject;
            const { role, vendorId, plate, isPremium } = updatedData;

            switch (role) {
                case UserType.Vendor:
                    if (vendorId === "") {
                        customToast("Empty vendor order sort id", "warning");
                        return {
                            isError: true,
                            message: "Empty vendor order sort id",
                        };
                    }

                    const userQuery = query(
                        userCollection,
                        where("sort_order_id", "==", vendorId)
                    );
                    const docs = await getDocs(userQuery);
                    if (
                        docs.docs.length > 0 &&
                        docs.docs[0].id !== userData?.id
                    ) {
                        customToast("Vendor order sort id Exists !", "warning");
                        return {
                            isError: true,
                            message: "Vendor order sort id Exists !",
                        };
                    }
                    firestoreDataObject = {
                        ...firestoreDataObject,
                        is_ready: deleteField(),
                        vehicle_number: deleteField(),
                        sort_order_id: vendorId,
                        role: UserType.Vendor,
                        is_premium: isPremium,
                    };
                    break;
                case UserType.Driver:
                    if (plate === "") {
                        customToast("Empty Vehicle Plate Number", "warning");
                        return {
                            isError: true,
                            message: "Empty Vehicle Plate Number",
                        };
                    }
                    const driverQuery = query(
                        userCollection,
                        where("vehicle_number", "==", plate)
                    );
                    const driverDocs = await getDocs(driverQuery);
                    if (driverDocs.docs.length > 0) {
                        customToast("Driver plate number Exists !", "warning");
                        return {
                            isError: true,
                            message: "Driver plate number Exists !",
                        };
                    }
                    firestoreDataObject = {
                        ...firestoreDataObject,
                        is_ready: true,
                        sort_order_id: deleteField(),
                        is_premium: deleteField(),
                        vehicle_number: plate,
                        role: UserType.Driver,
                    };
                    break;
                default:
                    firestoreDataObject = {
                        ...firestoreDataObject,
                        is_ready: deleteField(),
                        sort_order_id: deleteField(),
                        vehicle_number: deleteField(),
                        is_premium: deleteField(),
                        role: role,
                    };
                    break;
            }

            const userDoc = doc(userCollection, userData?.id);
            const itemDoc = [];
            for (const itemRow of saveObject?.updatedData?.vendorItemCodes) {
                itemDoc.push({
                    fl_code: itemRow?.fl_code,
                    name: itemRow?.name?.en,
                    vendor_code: itemRow?.vendor_code,
                });
            }
            const userDocItemMapSubcollection = collection(
                userDoc,
                "item_code_map"
            );
            const userCurrentItemMapDocRef = doc(
                userDocItemMapSubcollection,
                "current"
            );
            await updateDoc(userDoc, firestoreDataObject);
            if (role === UserType.Vendor) {
                try {
                    await setDoc(userCurrentItemMapDocRef, {
                        item_doc_array: itemDoc,
                    });
                } catch (error) {
                    console.error(error);
                }
                customToast("User Item Codes Updated", "success");
            }
            customToast("User Role Updated", "success");
            return { isError: false, message: "User Updated" };
        } catch (e) {
            return { isError: true, message: "Error Occured" };
        }
    },
    async updateUserDetails(user: any, saveObject: any) {
        try {
            let updateObject: any = {};
            let registered_location: any = user?.registered_location;
            if (saveObject?.display_name) {
                updateObject = {
                    ...updateObject,
                    display_name: saveObject?.display_name,
                };
            }
            if (saveObject?.address) {
                registered_location = {
                    ...registered_location,
                    address: saveObject?.address,
                };
                updateObject = {
                    ...updateObject,
                    registered_location,
                };
            }
            if (saveObject?.contact_number) {
                updateObject = {
                    ...updateObject,
                    contact_number: saveObject?.contact_number,
                };
            }
            if (saveObject?.vehicle_number) {
                updateObject = {
                    ...updateObject,
                    vehicle_number: saveObject?.vehicle_number,
                };
            }
            if (saveObject?.location) {
                registered_location = {
                    ...registered_location,
                    lat: saveObject?.location?.lat,
                    lng: saveObject?.location?.lng,
                };
                updateObject = {
                    ...updateObject,
                    registered_location,
                };
            }
            const userDoc = doc(userCollection, user?.id);
            await updateDoc(userDoc, updateObject);
            return { isError: false, message: "User Updated" };
        } catch (e) {
            return { isError: true, message: "Error Occured" };
        }
    },
    async fetchDrivers(dispatch: any) {
        try {
            const driverQuery = query(
                userCollection,
                where("role", "==", UserType.Driver)
            );
            const driverRefArray = await getDocs(driverQuery);
            const driverDocArray: any = driverRefArray?.docs?.map((doc) => {
                return doc?.data();
            });
            dispatch(userSlice.setDriverArray(driverDocArray));
            return { isError: false, message: "Success" };
        } catch (error) {
            return { isError: true, message: "Error occured!" };
        }
    },
};

export default user;
