import {
    Autocomplete,
    Box,
    Button,
    Card,
    Grid,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import userSlice from "../../store/slices/user";
import { customToast } from "../public_components/Toast/CustomToast";
import { UserType } from "../../utils/userUtils";
import { IconButton } from "@mui/material";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ClearIcon from "@mui/icons-material/Clear";
import { Delete, UploadFile } from "@mui/icons-material";
import readXlsxFile from "read-excel-file";

const defaultProps = {
    options: Object.values(UserType).filter((v) => isNaN(Number(v))),
    getOptionLabel: (option: UserType) => option,
};

export default function UserManagement() {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState<string>("");
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [currentUserRole, setCurrentUserRole] = useState<any>("");
    const [currentSortId, setCurrentSortId] = useState<string>("");
    const [currentPlate, setCurrentPlate] = useState<string>("");
    const [vendorItemCodes, setVendorItemCodes] = useState<any[]>([]);
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [orderRows, setOrderRows] = useState<any[]>([]);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "1");
        window.sessionStorage.setItem("master_item", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
    }, []);

    useEffect(() => {
        clearAll();
        if (currentUser?.role) {
            setCurrentUserRole(currentUser?.role);
        }
        if (currentUser?.sort_order_id) {
            setCurrentSortId(currentUser?.sort_order_id);
        }
        if (currentUser?.vehicle_number) {
            setCurrentPlate(currentUser?.vehicle_number);
        }
        if (currentUser?.is_premium) {
            setIsPremium(currentUser?.is_premium);
        }
        if (currentUser?.item_codes) {
            setVendorItemCodes(currentUser?.item_codes);
        }
    }, [currentUser]);

    const clearAll = () => {
        setCurrentUserRole("");
        setCurrentSortId("");
        setCurrentPlate("");
    };
    const clearUser = () => {
        clearAll();
        setCurrentUser(null);
    };

    const handleFindUser = async (isRefresh = false) => {
        if (username === "") {
            customToast("Empty input!", "error");
            return;
        }
        const result: any = await dispatch(
            userSlice.fetchUserByUsername(username)
        );
        if (result?.payload?.isError) {
            customToast(result?.payload?.message, "error");
            return;
        }
        if (!isRefresh) customToast(result?.payload?.message, "info");
        setCurrentUser(result?.payload?.result);
    };

    const updateUser = async () => {
        const payload = {
            userData: currentUser,
            updatedData: {
                role: currentUserRole,
                vendorId: currentSortId,
                plate: currentPlate,
                isPremium,
                vendorItemCodes,
            },
        };
        const result: any = await dispatch(
            userSlice.updateAdminUserDetails(payload)
        );
        if (result?.payload?.isError === false) {
            handleFindUser(true);
        }
    };

    const mapCodes = () => {
        let i = 0;
        const itemArray = [];
        for (const row of orderRows.slice(1)) {
            if (!row[0]) {
                continue;
            }
            const rowEntry = {
                id: i,
                fl_code: row[0],
                name: { en: row[1] },
                vendor_code: row[2],
            };
            itemArray.push(rowEntry);
            i++;
        }
        setVendorItemCodes(itemArray);
    };

    const uploadPrices = async () => {
        if (orderRows.length > 0) {
            mapCodes();
        }
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            readXlsxFile(file)
                .then((rows) => {
                    setOrderRows(rows);
                })
                .catch((error) => {
                    console.error("Error reading file:", error);
                });
        }
    };

    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                    margin: 1,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: "center",
                        p: 1,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                            textAlign={"center"}
                        >
                            Enter Username
                        </Typography>
                        <TextField
                            value={username}
                            fullWidth
                            onChange={(event) => {
                                setUsername(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearUser}>
                                            <ClearIcon color="secondary" />
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            onClick={() => handleFindUser()}
                                            sx={{ borderRadius: 100 }}
                                        >
                                            <PersonSearchIcon />
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    ></Grid>
                </Grid>
            </Card>
            {currentUser ? (
                <Card
                    sx={{
                        backgroundColor: "background.default",
                        borderRadius: 0,
                        margin: 1,
                    }}
                    elevation={0}
                >
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            p: 1,
                        }}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                color="text.primary"
                                sx={{
                                    fontWeight: 600,
                                }}
                                textAlign={"center"}
                            >
                                Current User
                            </Typography>
                            <TextField
                                value={currentUser?.username}
                                fullWidth
                                label="Username"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="DB id"
                                value={currentUser?.id}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Vendor Sort Id"
                                disabled={currentUserRole !== UserType.Vendor}
                                value={currentSortId}
                                onChange={(event) => {
                                    setCurrentSortId(event?.target?.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Vehicle Plate"
                                disabled={currentUserRole !== UserType.Driver}
                                value={currentPlate}
                                onChange={(event) => {
                                    setCurrentPlate(event?.target?.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                {...defaultProps}
                                id="controlled-demo"
                                value={currentUserRole}
                                onChange={(
                                    event: any,
                                    newValue: string | null
                                ) => {
                                    setCurrentUserRole(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Role"
                                        fullWidth
                                        helperText={
                                            currentUser?.role !==
                                            currentUserRole
                                                ? "*Role Changed"
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Switch
                                checked={isPremium}
                                color="secondary"
                                onChange={(event) => {
                                    setIsPremium(event?.target?.checked);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            alignItems="center"
                            justifyContent={"flex-end"}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={updateUser}
                                fullWidth
                                sx={{ mt: 1, borderRadius: 50 }}
                            >
                                Update
                            </Button>
                        </Grid>

                        {currentUserRole === UserType.Vendor && (
                            <>
                                <Grid item xs={12} textAlign={"center"}>
                                    <TextField
                                        type="file"
                                        id="input"
                                        color="info"
                                        helperText="ORDER INPUT FILE"
                                        onChange={handleFileChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={uploadPrices}
                                                    >
                                                        <UploadFile color="secondary" />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setVendorItemCodes(
                                                                []
                                                            );
                                                        }}
                                                    >
                                                        <Delete color="secondary" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    alignItems="center"
                                    justifyContent={"flex-end"}
                                >
                                    <Typography color="secondary">
                                        VENDOR CODES
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    alignItems="center"
                                    justifyContent={"flex-end"}
                                    container
                                    mb={10}
                                >
                                    <Grid item xs={4}>
                                        <Typography
                                            color="secondary"
                                            variant="caption"
                                        >
                                            FL CODE
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            color="secondary"
                                            variant="caption"
                                        >
                                            ITEM NAME
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            color="secondary"
                                            variant="caption"
                                        >
                                            VENDOR CODE
                                        </Typography>
                                    </Grid>
                                    {vendorItemCodes.map((row) => {
                                        return (
                                            <Grid key={row.id} container>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        color="secondary"
                                                        variant="caption"
                                                    >
                                                        {row?.name?.en}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    textAlign={"center"}
                                                >
                                                    <Typography
                                                        color="secondary"
                                                        variant="caption"
                                                    >
                                                        {row?.fl_code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        color="secondary"
                                                        variant="caption"
                                                    >
                                                        {row?.vendor_code}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Card>
            ) : null}
        </Box>
    );
}
