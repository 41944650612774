export const getDefaultState = () => {
    return {
        orders: null,
        workerSelectedOrderObject: false,
        paymentMethod: null,
    };
};

const state = { ...getDefaultState() };

export default state;
