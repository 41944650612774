// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { collection, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";
import user from "./user";
import order from "./order";
import config from "./config";
import crate from "./crate";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { onUserAuthChange } from "./auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "asia-southeast1");

// For Development Use
if (process.env.REACT_APP_CONNECT_FUNCTIONS_EMULATOR === "true") {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}
const messaging = getMessaging(app);
const provider = new GoogleAuthProvider();

// Add Firestore collections here
const configCollection = collection(db, "config");
const userCollection = collection(db, "user");
const orderCollection = collection(db, "order");
const itemCollection = collection(db, "item");
const workerItemCollection = collection(db, "workerItem");
const inventoryItemCollection = collection(db, "inventoryItem");
const vendorInventoryCollection = collection(db, "vendorInventory");
const masterCrateCollection = collection(db, "masterCrate");
const crateCollection = collection(db, "crate");

// Listeners
onUserAuthChange();
// store
export { db, auth, storage, functions, messaging, ref, analytics, provider };
export {
    user as userProvider,
    order as orderProvider,
    config as configProvider,
    crate as crateProvider,
};
export {
    configCollection,
    userCollection,
    orderCollection,
    itemCollection,
    workerItemCollection,
    vendorInventoryCollection,
    inventoryItemCollection,
    masterCrateCollection,
    crateCollection,
};
