import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function ConfirmPopup({
    handleYes,
    isOpen,
    handleClose,
    heading,
    text = "Do you confirm ?",
    yesText = "YES",
    noText = "NO",
    isYesButtonAvailable = true,
}: any) {
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{heading}</DialogTitle>
            <DialogContent>
                <DialogContentText component={"span"}>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {isYesButtonAvailable ? (
                    <Button color="success" onClick={handleYes}>
                        {yesText}
                    </Button>
                ) : null}
                <Button color="error" onClick={handleClose}>
                    {noText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
