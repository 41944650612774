const selectors = {
    selectOrders: (state: any) => {
        return state.order.orders;
    },
    selectWorkerSelectedOrderObject: (state: any) => {
        return state.order.workerSelectedOrderObject;
    },
    selectPaymentMethod: (state: any) => {
        return state.order.paymentMethod;
    },
};

export default selectors;
