import { Box, Button } from "@mui/material";
import "./NotFound.css";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo_V1_512.png";
import { useAppSelector } from "../../../hooks";
import userSlice from "../../../store/slices/user";
const NotFound = () => {
    const navigate = useNavigate();
    const user = useAppSelector(userSlice.selectCurrentUser);
    const isUserVerified = useAppSelector(userSlice.selectIsUserVerified);
    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "100vh",
                height: "100%",
            }}
        >
            <div id="main404">
                <div className="fof">
                    <h1>
                        <img width={50} src={logo} />
                        Error 404
                    </h1>
                    <h2>Page not Found</h2>
                    <Button
                        variant={"contained"}
                        sx={{ backgroundColor: "#769365" }}
                        onClick={() => {
                            isUserVerified
                                ? navigate(`/${user?.role}`)
                                : navigate("/sign-in");
                        }}
                    >
                        Go Back
                    </Button>
                </div>
            </div>{" "}
        </Box>
    );
};

export default NotFound;
