import { Navigate } from "react-router-dom";
import { auth } from "../provider/firebase";

const ProtectedRoute = ({ children }: any) => {
    if (!auth) {
        return <Navigate to="/sign-in" replace />;
    }
    return children;
};
export default ProtectedRoute;
