export const getDefaultState = () => {
    return {
        userInventory: [],
        masterItemList: [],
        userInventoryItem: {
            item: null,
            listing: [],
        },
    };
};

const state = getDefaultState();

export default {
    ...state,
};
