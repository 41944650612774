import { useEffect, useRef, useState } from "react";
import logo from "../../../assets/logo_V1_512.png";
import { useReactToPrint } from "react-to-print";
import { Button, Grid } from "@mui/material";
import { customToast } from "../Toast/CustomToast";

const Receipt = ({ orderObject }: any) => {
    const componentRef = useRef(null);
    const pageStyle = `{ size: 2.5in 4in }`;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: pageStyle,
    });
    const [order, setOrder] = useState<any>([]);
    useEffect(() => {
        if (orderObject?.isError) {
            customToast(orderObject?.message, "error");
            return;
        }
        setOrder(orderObject?.textOrderObject);
    }, [orderObject]);
    function getCurrentDate(): import("react").ReactNode {
        const date = new Date(Date.now());
        return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
    }

    return (
        <Grid container justifyItems={"center"} sx={{ mb: 10, mt: 5 }}>
            <Grid item xs={12} textAlign={"center"}>
                <Button
                    onClick={() => {
                        handlePrint();
                    }}
                    variant="contained"
                    color="secondary"
                >
                    Print
                </Button>
            </Grid>
            <Grid item container ref={componentRef} xs={12}>
                <div id="invoice-POS">
                    <center id="top">
                        <div className="logo"></div>
                        <div className="info">
                            <h2>FarmLink</h2>
                        </div>
                        <div className="info">
                            <img src={logo} height={100} />
                        </div>
                    </center>

                    <div id="mid">
                        <div className="info">
                            <h2>Order ID: {order?.orderId}</h2>
                            <h5>{getCurrentDate()}</h5>
                            <p>
                                Order : {order?.orderStatus}
                                <br></br>
                                Order created at : {order?.orderCreatedAt}
                                <br></br>
                                Payment : {order?.paymentStatus}
                                <br></br>
                                Payment Method : {order?.paymentMethod}
                                <br></br>
                                Payment ID : {order?.paymentId}
                                <br></br>
                            </p>
                        </div>
                    </div>
                    <div id="mid">
                        <div className="info">
                            <h2>Contact Info</h2>
                            <p>
                                Name : {order?.recipient}
                                <br></br>
                                Address : {order?.address}
                                <br></br>
                                Email : {order?.email}
                                <br></br>
                                Phone : {order?.contact}
                                <br></br>
                            </p>
                        </div>
                    </div>

                    <div id="bot">
                        <div id="table">
                            <table>
                                <tbody>
                                    <tr className="tabletitle">
                                        <td className="item">
                                            <h2>Item</h2>
                                        </td>
                                        <td className="Hours">
                                            <h2>Unit Price</h2>
                                        </td>
                                        <td className="Hours">
                                            <h2>Qty</h2>
                                        </td>
                                        <td className="Rate">
                                            <h2>Sub Total</h2>
                                        </td>
                                    </tr>
                                    {order?.items?.map((item: any) => {
                                        return (
                                            <tr
                                                className="service"
                                                key={Math.random()}
                                            >
                                                <td className="tableitem">
                                                    <p className="itemtext">
                                                        {item?.itemName?.en}
                                                    </p>
                                                    <p className="itemtext">
                                                        {item?.itemName?.si}
                                                    </p>
                                                </td>
                                                <td className="tableitem">
                                                    <p className="itemtext">
                                                        {item?.unitPrice}
                                                    </p>
                                                </td>
                                                <td className="tableitem">
                                                    <p className="itemtext">
                                                        {item?.quantity}{" "}
                                                        {item?.unit}
                                                    </p>
                                                </td>
                                                <td className="tableitem">
                                                    <p className="itemtext">
                                                        LKR {item?.totalPrice}
                                                    </p>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    <tr className="tabletitle">
                                        <td></td>
                                        <td className="Rate">
                                            <h4>Paid</h4>
                                        </td>
                                        <td className="payment">
                                            <h4>LKR {order?.advancePayment}</h4>
                                        </td>
                                    </tr>
                                    <tr className="tabletitle">
                                        <td></td>
                                        <td className="Rate">
                                            <h2>Total</h2>
                                        </td>
                                        <td className="payment">
                                            <h2>
                                                LKR{" "}
                                                {order?.totalPrice -
                                                    order?.advancePayment >
                                                0
                                                    ? order?.totalPrice -
                                                      order?.advancePayment
                                                    : 0}
                                            </h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div id="legalcopy">
                            <p className="legal">
                                <strong>Thank you for your business!</strong>{" "}
                                Payment is expected within 7 days;
                            </p>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default Receipt;
