import React, { useEffect } from "react";
import { Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import veg_logo from "../../assets/images/veg_icon.png";
import fr_logo from "../../assets/images/fr_icon.png";
import all_logo from "../../assets/images/all_icon.png";
import fish_logo from "../../assets/images/fish_icon.png";
import sp_logo from "../../assets/images/sp_icon.png";
import meat_logo from "../../assets/images/meat_icon.png";
import other_logo from "../../assets/images/other_icon.png";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import itemSlice from "../../store/slices/item";
import { UserType } from "../../utils/userUtils";
import { ItemCategory, ItemState } from "../../utils/itemUtils";
import { Item } from "item";
import cartSlice from "../../store/slices/cart";

import "../../App.css";
import { customToast } from "../public_components/Toast/CustomToast";

const Transition: any = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            children={undefined as any}
            direction="up"
            ref={ref}
            {...props}
        />
    );
});

export default function BulkBuyerHome() {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(userSlice.selectCurrentUser);
    const masterItems = useAppSelector(itemSlice.selectMasterItemList);
    const cart: any[] = useAppSelector(cartSlice.selectCart);
    const [value, setValue] = React.useState("one");
    const [searchTerm, setSearchTerm] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState<any>(null);
    const [cartQuantity, setCartQuantity] = React.useState<any>(5);
    const [items, setItems] = React.useState<any>([]);
    const [filteredItems, setFilteredItems] = React.useState([]);
    const [searchItems, setSearchItems] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [userType, setUserType] = React.useState(null);
    const [estimated_delivery, setEstimated_delivery] =
        React.useState("Jan 02 - Jan 04");

    useEffect(() => {
        window.sessionStorage.setItem("market", "1");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        setUserType(currentUser?.role);
    }, [currentUser]);
    useEffect(() => {
        const fetchMasterItem = async () => {
            await dispatch(itemSlice.fetchMasterInventoryItem());
        };
        if (userType === UserType.Vendor) fetchMasterItem();
    }, [userType]);
    useEffect(() => {
        setItemList();
    }, [masterItems]);

    useEffect(() => {
        filter_all();
    }, [items]);
    useEffect(() => {
        if (searchTerm === "") {
            setSearchItems(filteredItems);
            return;
        }
        const filteredItemsSearch = filteredItems.filter((item: any) => {
            if (
                item.name_search_term
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ) {
                return item;
            }
        });
        setSearchItems(filteredItemsSearch);
    }, [searchTerm, filteredItems]);

    // Load Bulk items For bulk buyers
    const setItemList = () => {
        let finalItems: any[] = [];
        if (userType === UserType.Vendor && masterItems?.length > 0) {
            finalItems = masterItems.map((currentItem: Item) => {
                let price = currentItem.unit_price.bulk_price;

                // If the vendor is a super market
                if (currentUser?.is_premium) {
                    price = currentItem.unit_price.bulk_premium_price || 0;
                }
                const itemObject = {
                    id: currentItem.id,
                    name_search_term: currentItem?.name_search_term ?? "",
                    name: currentItem.display_name.en,
                    name_si: currentItem.display_name.si,
                    price: price,
                    unit: currentItem.unit_size,
                    available: currentItem.status,
                    image: currentItem.item_image_url,
                    category: currentItem.category || ItemCategory.vegetable,
                    quantity: 0,
                };
                return itemObject;
            });
            const filteredItems = finalItems.filter((item) => {
                return item?.available === ItemState.InStock;
            });
            setItems(filteredItems);
        }
    };

    const filter_all = () => {
        setFilteredItems(items);
    };

    const filter_veg = () => {
        setFilteredItems(items);
        const filteredItems = items.filter((i: Item) => {
            return i.category === ItemCategory.vegetable;
        });
        setFilteredItems(filteredItems);
    };
    const filter_fruit = () => {
        setFilteredItems(items);
        const filteredItems = items.filter((i: Item) => {
            return i.category === ItemCategory.fruit;
        });
        setFilteredItems(filteredItems);
    };
    const filter_fish = () => {
        setFilteredItems(items);
        const filteredItems = items.filter((i: Item) => {
            return i.category === ItemCategory.fish;
        });
        setFilteredItems(filteredItems);
    };
    const filter_spices = () => {
        setFilteredItems(items);
        const filteredItems = items.filter((i: Item) => {
            return i.category === ItemCategory.spices;
        });
        setFilteredItems(filteredItems);
    };
    const filter_meat = () => {
        setFilteredItems(items);
        const filteredItems = items.filter((i: Item) => {
            return i.category === ItemCategory.meat;
        });
        setFilteredItems(filteredItems);
    };
    const filter_other = () => {
        setFilteredItems(items);
        const filteredItems = items.filter((i: Item) => {
            return i.category === ItemCategory.other;
        });
        setFilteredItems(filteredItems);
    };

    const handleClickOpen = (item: any) => {
        setCartQuantity(5);
        setTotal(5 * item.price);
        setItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setItem(null);
    };

    const handleItemQuantity = (event: { target: { value: any } }) => {
        let inputValue: any = event.target.value;
        if (isNaN(parseInt(inputValue))) {
            inputValue = 0;
        }
        if (parseInt(inputValue) > 10000) {
            return;
        }
        const quantity: number = parseInt(inputValue);
        setCartQuantity(quantity);
        setTotal(quantity * item.price);
    };

    const addToCart = (newItem: any) => {
        if (cartQuantity >= 5) {
            let newCart: any = [];
            newCart = [...cart];
            const existingCartItemIndex = newCart.findIndex(
                (curr: any) => curr.item.id === newItem.id
            );

            if (existingCartItemIndex !== -1) {
                const newCartQuantity =
                    newCart[existingCartItemIndex].cartQuantity + cartQuantity;
                newCart.splice(existingCartItemIndex, 1);
                newCart.push({ item: newItem, cartQuantity: newCartQuantity });
            } else {
                newCart.push({ item: newItem, cartQuantity });
            }

            if (newCart) {
                dispatch(cartSlice.setCart(newCart));
            }
            setOpen(false);
        } else {
            customToast("Minimum order is 5 kg", "error");
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <Dialog
                fullScreen={window.innerWidth < 600 ? true : false}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                sx={{ zIndex: 1 }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                        >
                            {item?.name_si}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        mb: { xs: 10, md: 0 },
                    }}
                >
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                        }}
                    >
                        <CardMedia>
                            <img
                                src={item?.image}
                                alt={item?.name_si}
                                style={{ width: "100%" }}
                            />
                        </CardMedia>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Rs.{item?.price}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Price per kg
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                justifyContent={"center"}
                            >
                                <Typography
                                    variant="h5"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    <Chip
                                        label={
                                            item?.available ===
                                            ItemState.InStock
                                                ? "IN STOCK"
                                                : "UNAVAILABLE"
                                        }
                                        size="medium"
                                        color={
                                            item?.available ===
                                            ItemState.InStock
                                                ? "success"
                                                : "error"
                                        }
                                        variant="outlined"
                                    />
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            m: 3,
                        }}
                    >
                        {/* <Card
                            sx={{
                                backgroundColor: "success.light",
                                borderRadius: 0,
                                width: "100%",
                                minWidth: 200,
                                p: 1,
                            }}
                        >
                            <Typography
                                variant="body2"
                                color="success.dark"
                                sx={{
                                    fontWeight: 700,
                                    alignContent: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                Estimated Delivery: in 12 Hrs
                            </Typography>
                        </Card> */}
                        <Box
                            sx={{
                                mt: 2,
                            }}
                        ></Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "row", md: "column" },
                                justifyContent: {
                                    xs: "space-around",
                                    md: "center",
                                },
                                alignContent: "center",
                            }}
                        >
                            <Grid container>
                                <Grid xs={12}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <TextField
                                            value={cartQuantity.toString()}
                                            onChange={handleItemQuantity}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography
                                                            variant="body2"
                                                            color="info.main"
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            Kg
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0,
                                                    max: 10000,
                                                },
                                            }}
                                            color="info"
                                            size="small"
                                            variant="outlined"
                                            type="number"
                                            focused
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={12} mt={2}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            Total
                                        </Typography>
                                        <Box
                                            sx={{
                                                width: 7,
                                            }}
                                        />
                                        <Typography
                                            variant="h5"
                                            color="info.main"
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            Rs.{total}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* <Box
                                sx={{
                                    width: "40%",
                                }}
                            /> */}
                        </Box>
                        {item?.available === ItemState.InStock ? (
                            <Button
                                sx={{
                                    mt: 1,
                                    width: "80%",
                                    height: 50,
                                    borderRadius: 5,
                                }}
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={() => addToCart(item)}
                            >
                                <Typography
                                    variant="body2"
                                    color="text.primary"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Add to Cart
                                </Typography>
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    mt: 1,
                                    width: "80%",
                                    height: 50,
                                    borderRadius: 5,
                                }}
                                variant="contained"
                                color="error"
                                disabled
                                fullWidth
                            >
                                <Typography
                                    variant="body2"
                                    color="error"
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Unavailable
                                </Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
            </Dialog>
            <Box
                sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    minHeight: "90vh",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        mb: 2,
                    }}
                >
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton color="info">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        color="info"
                        size="small"
                        variant="outlined"
                        type="search"
                        focused
                        fullWidth
                        onChange={(event) => {
                            setSearchTerm(event?.target?.value);
                        }}
                    />
                </Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // variant="scrollable"
                    textColor="secondary"
                    indicatorColor="secondary"
                    scrollButtons="auto"
                    centered
                >
                    <Tab
                        icon={
                            <img
                                src={all_logo}
                                alt="logo"
                                className="responsive-img"
                            />
                        }
                        label="All"
                        value="one"
                        onClick={filter_all}
                    />
                    <Tab
                        icon={
                            <img
                                src={veg_logo}
                                alt="logo"
                                className="responsive-img"
                            />
                        }
                        label="Vegetables"
                        value="two"
                        onClick={filter_veg}
                    />
                    <Tab
                        icon={
                            <img
                                src={fr_logo}
                                alt="logo"
                                className="responsive-img"
                            />
                        }
                        label="Fruits"
                        value="three"
                        onClick={filter_fruit}
                    />
                    {/* <Tab
            icon={<img src={fish_logo} alt="logo" className="responsive-img" />}
            label="Fish"
            value="four"
            onClick={filter_fish}
          />
          <Tab
            icon={<img src={sp_logo} alt="logo" className="responsive-img" />}
            label="Spices"
            value="five"
            onClick={filter_spices}
          />
          <Tab
            icon={<img src={meat_logo} alt="logo" className="responsive-img" />}
            label="Meat"
            value="six"
            onClick={filter_meat}
          />
          <Tab
            icon={<img src={other_logo} alt="logo" className="responsive-img" />}
            label="Other"
            value="seven"
            onClick={filter_other}
          /> */}
                </Tabs>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, md: 6 }}
                    sx={{
                        mt: 2,
                        mb: 6,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    {searchItems.map((item: any) => (
                        <Grid key={item.id} xs={6} md={2}>
                            <Card
                                sx={{
                                    backgroundColor: "background.default",
                                    boxShadow: 4,
                                    borderRadius: 2,
                                }}
                            >
                                <CardActionArea
                                    onClick={() => handleClickOpen(item)}
                                >
                                    <CardMedia
                                        component="img"
                                        image={item.image}
                                        height={165}
                                        alt={item?.name_si}
                                    />
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            pt: 0,
                                        }}
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            {item.name_si}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Rs. {item.price} per {item.unit}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            {item.available}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}
