const selectors = {
    selectTest: (state: any) => {
        return state.user.test;
    },
    selectCurrentUser: (state: any) => {
        return state.user.currentUser;
    },
    selectCurrentUserRef: (state: any) => {
        return state.user.currentUserRef;
    },
    selectIsUserVerified: (state: any) => {
        return state.user.isUserVerified;
    },
    selectDriverArray: (state: any) => {
        return state.user.driverArray;
    },
};

export default selectors;
