import {
    MapContainer,
    TileLayer,
    Marker,
    Circle,
    useMapEvents,
} from "react-leaflet";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "leaflet/dist/leaflet.css";
import "./map.css";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { Fab } from "@mui/material";
import Control from "react-leaflet-custom-control";

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const panOptions = {
    animate: false,
};

function DraggableMarker({
    position,
    togglePanToPinLocation,
    togglePanToPinDraggedLocation,
    toggleMoveLocationPin,
    setMarkerPosition,
    handleDragEnd,
    draggable = true,
}: any) {
    const markerRef = useRef(null);
    const map = useMapEvents({});
    useEffect(() => {
        panToPinLocation();
    }, [togglePanToPinLocation]);
    useEffect(() => {
        panToPinDraggendLocation();
    }, [togglePanToPinDraggedLocation]);
    useEffect(() => {
        if (toggleMoveLocationPin?.isButton) {
            moveLocationPin();
        }
    }, [toggleMoveLocationPin]);

    const panToPinLocation = () => {
        if (position) {
            const newPosition = L.latLng(position[0], position[1]);
            map.flyTo(newPosition, 16, panOptions);
        }
    };
    const panToPinDraggendLocation = () => {
        if (position) {
            const newPosition = L.latLng(position[0], position[1]);
            map.panTo(newPosition, panOptions);
        }
    };
    const moveLocationPin = () => {
        const mapCenter = map.getCenter();
        setMarkerPosition({ lat: mapCenter?.lat, lng: mapCenter?.lng });
    };

    return (
        <Marker
            zIndexOffset={1000}
            draggable={draggable}
            position={position}
            ref={markerRef}
            eventHandlers={{
                dragend: handleDragEnd,
            }}
        />
    );
}

function LiveLocationMarker({
    position,
    accuracy,
    togglePanToMyLocation,
}: any) {
    const map = useMapEvents({});
    useEffect(() => {
        panToCurrentLocation();
    }, [togglePanToMyLocation]);

    const panToCurrentLocation = () => {
        if (position) {
            const newPosition = L.latLng(position[0], position[1]);
            map.flyTo(newPosition, 16, panOptions);
        }
    };

    const locationIcon = L.icon({
        iconUrl: "/my-location.svg",
        iconSize: [25, 25],
        iconAnchor: [12, 14],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    return position === null ? null : (
        <>
            <Marker draggable={false} position={position} icon={locationIcon} />
            <Circle
                center={position}
                pathOptions={{
                    color: "#4A89F3",
                    weight: 0.5,
                    opacity: 0.5,
                }}
                radius={accuracy}
            />
        </>
    );
}

export default function MapInput({ lat, lng, onChange }: any) {
    let navigationInterval: any = null;
    const [togglePanToMyLocation, setTogglePanToMyLocation] =
        useState<boolean>(true);
    const [togglePanToPinLocation, setTogglePanToPinLocation] =
        useState<boolean>(true);
    const [togglePanToPinDraggedLocation, setTogglePanToPinDraggedLocation] =
        useState<boolean>(true);
    const [toggleMoveLocationPin, setToggleMoveLocationPin] = useState<{
        isButton: boolean;
        toggleMoveLocationPin: boolean;
    }>({ isButton: false, toggleMoveLocationPin: true });

    const [position, setPosition] = useState<any>({
        latitude: 6.8,
        longitude: 79.9,
    });
    const [center, setCenter] = useState<any>({
        latitude: 6.8,
        longitude: 79.9,
    });
    const [markerposition, setMarkerPosition] = useState({
        lat: lat | 6.8,
        lng: lng | 79.9,
    });
    const [newMarkerposition, setNewMarkerPosition] = useState<any>();
    const [accuracy, setAccuracy] = useState<number>(1000);
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigationInterval = navigator.geolocation.watchPosition(
                function (position) {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setAccuracy(position.coords.accuracy);
                },
                null,
                { enableHighAccuracy: true, timeout: 10000 }
            );
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);

    useEffect(() => {
        if (!lat || !lng) {
            return;
        }
        setMarkerPosition({ lat, lng });
        setTogglePanToPinLocation(!togglePanToPinLocation);
    }, [lat, lng]);

    // Oncomponent unmount
    useEffect(
        () => () => {
            if (navigationInterval) {
                navigator.geolocation.clearWatch(navigationInterval);
            }
        },
        []
    );
    useEffect(() => {
        if (!newMarkerposition) {
            return;
        }
        onChange(newMarkerposition);
        setToggleMoveLocationPin({
            isButton: false,
            toggleMoveLocationPin: toggleMoveLocationPin.toggleMoveLocationPin,
        });
        setNewMarkerPosition(null);
    }, [newMarkerposition]);

    // Handle draggable marker
    const handleDragEnd = (e: any) => {
        const marker = e.target;
        const lat = marker.getLatLng().lat;
        const lng = marker.getLatLng().lng;
        const newPosition = L.latLng(lat, lng);
        setMarkerPosition(newPosition);
        setNewMarkerPosition({
            lat: newPosition.lat,
            lng: newPosition.lng,
        });
        setTogglePanToPinDraggedLocation(!togglePanToPinDraggedLocation);
        // Update your state variables here if needed
    };

    const handleNewLocationPin = (e: any) => {
        const lat = e?.lat;
        const lng = e?.lng;
        const newPosition = L.latLng(lat, lng);
        setMarkerPosition(newPosition);
        setNewMarkerPosition({
            lat: newPosition.lat,
            lng: newPosition.lng,
        });
        setTogglePanToPinDraggedLocation(!togglePanToPinDraggedLocation);
        // Update your state variables here if needed
    };

    return (
        <MapContainer center={[center?.latitude, center?.longitude]} zoom={10}>
            <TileLayer
                attribution={`Google Maps | accuracy ${accuracy.toFixed()}m`}
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                minZoom={4}
            />
            <Control prepend position="bottomright">
                <Fab
                    color="inherit"
                    size="medium"
                    onClick={() => {
                        setTogglePanToMyLocation(!togglePanToMyLocation);
                    }}
                >
                    <MyLocationIcon
                        fontSize="medium"
                        sx={{ color: "#4A89F3" }}
                    />
                </Fab>
            </Control>
            <Control prepend position="bottomright">
                <Fab
                    color="inherit"
                    size="medium"
                    onClick={() => {
                        setTogglePanToPinDraggedLocation(
                            !togglePanToPinDraggedLocation
                        );
                    }}
                >
                    <LocationOnIcon fontSize="large" />
                </Fab>
            </Control>
            <Control prepend position="topright">
                <Fab
                    color="inherit"
                    size="medium"
                    onClick={() => {
                        setToggleMoveLocationPin({
                            isButton: true,
                            toggleMoveLocationPin:
                                !toggleMoveLocationPin.toggleMoveLocationPin,
                        });
                    }}
                    style={{ backgroundColor: "primary" }}
                >
                    <EditLocationIcon fontSize="large" />
                </Fab>
            </Control>
            <LiveLocationMarker
                position={[position?.latitude, position?.longitude]}
                accuracy={accuracy}
                togglePanToMyLocation={togglePanToMyLocation}
            />
            <DraggableMarker
                position={[markerposition?.lat, markerposition?.lng]}
                draggable={true}
                handleDragEnd={handleDragEnd}
                setMarkerPosition={handleNewLocationPin}
                togglePanToPinLocation={togglePanToPinLocation}
                togglePanToPinDraggedLocation={togglePanToPinDraggedLocation}
                toggleMoveLocationPin={toggleMoveLocationPin}
            />
        </MapContainer>
    );
}
