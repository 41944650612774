const reducers = {
    incrementTest: (state: any) => {
        state.test += 1;
    },
    decrementTest: (state: any) => {
        state.test -= 1;
    },
    setUser: (state: any, action: any) => {
        state.currentUser = action.payload;
    },
    clearUser: (state: any) => {
        state.currentUser = null;
    },
    setIsUserVerified: (state: any, action: any) => {
        state.isUserVerified = action.payload;
    },
    setDriverArray: (state: any, action: any) => {
        state.driverArray = action.payload;
    },
    clearUserVerified: (state: any) => {
        state.isUserVerified = false;
    },
};

export default reducers;
