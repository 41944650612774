const reducers = {
    setCart: (state: any, action: any) => {
        state.cart = action.payload;
    },
    clearCart: (state: any) => {
        state.cart = [];
    },
};

export default reducers;
