import { useEffect, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    CircularProgress,
    Grid,
    Switch,
    Typography,
} from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import orderSlice from "../../store/slices/order";
import {
    OrderStatus,
    PaymentMethod,
    PaymentStatus,
    createOrderReceiptObject,
    generateSixDigitNumber,
} from "../../utils/orderUtils";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PaymentsIcon from "@mui/icons-material/Payments";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapStatic from "../public_components/maps/MapStatic";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckIcon from "@mui/icons-material/Check";
import MapIcon from "@mui/icons-material/Map";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { customToast } from "../public_components/Toast/CustomToast";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";

const OrderItem = ({ item }: any) => {
    const { name, image, unit_price, quantity, unit } = item;

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                borderRadius: 0,
                mt: 1,
            }}
            elevation={0}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    ml: 2,
                }}
            >
                <Grid item xs={4}>
                    <CardMedia component="img" image={image} alt={name?.en} />
                </Grid>
                <Grid item xs={6}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {name?.en}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Rs {unit_price?.toFixed(2)}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            {quantity} {unit}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

const DriverOrder = () => {
    const navigate = useNavigate();
    const urlParams = useParams();
    const dispatch = useAppDispatch();
    const [items, setItems] = useState<any[]>([]);
    const [order, setOrder] = useState<any>();
    const [minfyOrder, setMinifyOrder] = useState<any>();
    const [isLoading, setLoading] = useState<any>();
    const [isConfirmeDialog, setIsConfirmeDialog] = useState<boolean>(false);
    const [isOrderPriceOverried, setIsOrderPriceOverried] =
        useState<boolean>(false);

    useEffect(() => {
        fetchOrder();
    }, [urlParams]);
    useEffect(() => {
        if (!order) {
            return;
        }
        setItems(order?.items);
        // setIsOrderPriceOverried(order?.payment_method === PaymentMethod.Cash);
        const minifyOrder = createOrderReceiptObject(order);
        setMinifyOrder(minifyOrder);
    }, [order]);

    async function fetchOrder() {
        setLoading(true);
        if (!urlParams?.id) {
            setLoading(false);
            return;
        }
        const order: any = await dispatch(orderSlice.fetchOrder(urlParams?.id));
        if (order?.payload?.isError) {
            setLoading(false);
            return;
        }
        setOrder(order?.payload?.result);
        setLoading(false);
    }

    const handleOrderComplete = async () => {
        setLoading(true);
        if (!order) {
            customToast("Error: Invalid Order", "error");
            setLoading(false);
            setIsConfirmeDialog(false);
            return;
        }
        setIsConfirmeDialog(false);
        const result: any = await dispatch(
            orderSlice.completeOrder({
                orderId: order?.id,
                isOrderPriceOverried,
            })
        );
        if (result?.payload?.isError) {
            customToast(result?.payload?.message, "error");
        } else {
            customToast(result?.payload?.message, "success");
            await fetchOrder();
        }
        setLoading(false);
    };

    const handleClose = () => {
        setIsConfirmeDialog(false);
    };

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <ConfirmPopup
                isOpen={isConfirmeDialog}
                handleClose={handleClose}
                text={`Do you want to complete the order for Sort ID: ${order?.sort_order_id} ?`}
                heading={`Complete Order ?`}
                handleYes={handleOrderComplete}
            />
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        m: 2,
                    }}
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Contact Reciever
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 10,
                            }}
                        >
                            {order?.userData?.display_name}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 10,
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    order?.userData?.contact_number
                                );
                                customToast(
                                    `Copied number ${order?.userData?.contact_number}`,
                                    "info"
                                );
                            }}
                        >
                            {order?.userData?.contact_number}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                window.open(
                                    `tel:${order?.userData?.contact_number}`
                                );
                            }}
                        >
                            <PhoneIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Card>
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        m: 2,
                    }}
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Delivery Address
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            {order?.end_location?.address}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Chip
                            icon={<ShoppingBasketIcon />}
                            label={order?.no_of_bags ? order?.no_of_bags : 0}
                            color="success"
                        />
                    </Grid>
                </Grid>
            </Card>

            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        m: 2,
                    }}
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Payment
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            Net Amount: Rs {order?.total_price}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Chip
                            icon={<PaymentsIcon />}
                            label={order?.payment_status}
                            color={
                                order?.payment_status === PaymentStatus.Paid
                                    ? "success"
                                    : "warning"
                            }
                        />
                    </Grid>
                </Grid>
            </Card>
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        m: 2,
                    }}
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Get Directions
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            color="info"
                            variant="contained"
                            onClick={() => {
                                window.open(
                                    "https://maps.google.com?q=" +
                                        order?.end_location?.lat +
                                        "," +
                                        order?.end_location?.lng
                                );
                            }}
                        >
                            <MapIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Card>
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        m: 2,
                    }}
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Complete Order
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            Sort ID: <strong>{order?.sort_order_id}</strong>
                        </Typography>
                        <Typography
                            variant="caption"
                            color="text.primary"
                            sx={{
                                fontWeight: 10,
                            }}
                        >
                            Order ID: {order?.id}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {isLoading
                                ? null
                                : // <Switch
                                  //     checked={isOrderPriceOverried}
                                  //     disabled={
                                  //         true
                                  //         // order?.payment_method !==
                                  //         //     PaymentMethod.Cash ||
                                  //         // order?.order_status ===
                                  //         //     OrderStatus.Delivered
                                  //     }
                                  //     color="secondary"
                                  //     onChange={(event) => {
                                  //         setIsOrderPriceOverried(
                                  //             event?.target?.checked
                                  //         );
                                  //     }}
                                  // />
                                  null}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {isLoading ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                <Button
                                    color={
                                        order?.order_status ===
                                        OrderStatus.OutForDelivery
                                            ? "secondary"
                                            : "success"
                                    }
                                    disabled={
                                        order?.order_status ===
                                        OrderStatus.Delivered
                                    }
                                    variant="contained"
                                    onClick={() => {
                                        setIsConfirmeDialog(true);
                                    }}
                                    sx={{
                                        "&.Mui-disabled": {
                                            background: "#58705d",
                                            color: "white",
                                        },
                                    }}
                                >
                                    <CheckIcon />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Card
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 0,
                }}
                elevation={0}
            >
                <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button
                            color="secondary"
                            fullWidth
                            variant="contained"
                            disabled={
                                order?.order_status === OrderStatus.Delivered
                            }
                            onClick={() => {
                                if (minfyOrder) {
                                    console.log(minfyOrder);
                                    navigate("receipt", {
                                        state: {
                                            order: JSON.stringify(minfyOrder),
                                        },
                                    });
                                }
                            }}
                        >
                            <ReceiptIcon />
                            Receipt
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    m: 0,
                    pt: 2,
                    marginBottom: 10,
                }}
            >
                {items.length === 0 ? (
                    <Typography variant="h6" color={"secondary"}>
                        No items in cart !
                    </Typography>
                ) : (
                    ""
                )}
                <Accordion elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="info" />}
                        sx={{
                            backgroundColor: "background.default",
                        }}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        color="secondary"
                    >
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Location
                        </Typography>
                    </AccordionSummary>
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                            borderRadius: 0,
                        }}
                        elevation={0}
                    >
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        >
                            <Grid item xs={12}>
                                <MapStatic
                                    lat={order?.end_location?.lat}
                                    lng={order?.end_location?.lng}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Accordion>
                <Accordion elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="info" />}
                        sx={{
                            backgroundColor: "background.default",
                        }}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        color="secondary"
                    >
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Items
                        </Typography>
                    </AccordionSummary>
                    {items.map((item: any) => (
                        <Box key={generateSixDigitNumber()}>
                            <OrderItem item={item} />
                        </Box>
                    ))}
                </Accordion>
            </Box>
        </Box>
    );
};

export default DriverOrder;
