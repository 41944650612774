import { DatePicker } from "@mui/x-date-pickers";

export default function CustomDatePicker({
    label,
    minDate = new Date(),
    maxDate,
    onChange,
    slotProps,
    value,
}: any) {
    return (
        <DatePicker
            label={label}
            value={value}
            //@ts-ignore
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            slotProps={{
                openPickerButton: {
                    color: "secondary",
                },
                // Targets the `InputAdornment` component.
                inputAdornment: {
                    position: "start",
                },
                textField: { fullWidth: true },
                day: {
                    sx: {
                        "&.Mui-selected": {
                            backgroundColor: "green",
                            "&:hover": {
                                backgroundColor: "green",
                            },
                            "&:focus": {
                                backgroundColor: "green",
                            },
                        },
                    },
                },
                leftArrowIcon: {
                    sx: {
                        color: "white", // Replace with your color
                        "&.Mui-disabled": {
                            color: "red", // Replace with your desired disabled color
                        },
                    },
                },
                rightArrowIcon: {
                    sx: {
                        color: "white",
                    },
                },
                actionBar: {
                    actions: ["cancel", "accept"],
                    sx: {
                        "& .MuiButton-text": {
                            color: "white",
                        },
                    },
                },
                ...slotProps,
            }}
        />
    );
}
