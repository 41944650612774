import { Typography, Box } from "@mui/material";
import AddItemForm from "./AddItemForm";
import { useAppDispatch } from "../../../hooks";
import { actions } from "../../../store/slices/item/itemSlice";
import { useEffect } from "react";

// Todo: Update UI to match
const AddItem = () => {
    const dispatch = useAppDispatch();
    const addItem = async (newItem: any) => {
        console.log("Admin Add Item: ", newItem);
        await dispatch(actions.addItem(newItem));
    };

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
    }, []);

    return (
        <Box
            pt={[5, 5]}
            sx={{
                backgroundColor: "background.paper",
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 10,
                }}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    color={"secondary"}
                >
                    Item List
                </Typography>
                <AddItemForm addItem={addItem} />
                {/* Render your item list here */}
            </Box>{" "}
        </Box>
    );
};

export default AddItem;
