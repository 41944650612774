import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Chip from "@mui/material/Chip";
import { useAppDispatch } from "../../hooks";
import orderSlice from "../../store/slices/order";
import { useNavigate } from "react-router-dom";
import ongoing_logo from "../../assets/images/ongoing.png";
import completed_logo from "../../assets/images/completed.png";
import { OrderStatus } from "../../utils/orderUtils";

const CartItem = ({ item }: any) => {
    const navigate = useNavigate();
    const { id, total_price, order_status, created_at } = item;

    return (
        <Card
            onClick={() => {
                navigate(`/vendor/orders/${id}`);
            }}
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                }}
            >
                <Grid item>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {id}
                        </Typography>
                        <Typography
                            sx={{ pb: 2 }}
                            variant="caption"
                            color="text.secondary"
                        >
                            {created_at?.toDate()?.toLocaleString()}
                        </Typography>

                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                }}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Rs {parseFloat(total_price).toFixed(2)}
                            </Typography>
                            <Chip
                                sx={{ fontWeight: 700 }}
                                label={order_status}
                                color={
                                    order_status === OrderStatus.Pending
                                        ? "warning"
                                        : "success"
                                }
                                size="small"
                            />
                        </Box>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

const OrderTable = ({ data, isLoading }: any) => {
    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    m: 0,
                }}
            >
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <Grid item>
                        <CircularProgress color="secondary" />;
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    m: 0,
                }}
            >
                {data.map((item: any) => (
                    <Box key={item.id}>
                        <CartItem item={item} />
                        <Box sx={{ p: 1 }} />
                    </Box>
                ))}
            </Box>
        </>
    );
};

const Orders = () => {
    const dispatch = useAppDispatch();
    const [items, setItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [value, setValue] = useState<string>("ongoing");
    useEffect(() => {
        fetchOrders();
    }, [value]);

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "1");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    const fetchOrders = async () => {
        setIsLoading(true);
        let res: any = await dispatch(orderSlice.fetchUserBulkOrders(value));
        setItems(res.payload);
        setIsLoading(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box
                sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    minHeight: "90vh",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        textColor="secondary"
                        indicatorColor="secondary"
                        scrollButtons="auto"
                    >
                        <Tab
                            icon={
                                <img
                                    src={ongoing_logo}
                                    alt="logo"
                                    className="responsive-img"
                                />
                            }
                            label="Ongoing"
                            value="ongoing"
                        />
                        <Tab
                            icon={
                                <img
                                    src={completed_logo}
                                    alt="logo"
                                    className="responsive-img"
                                />
                            }
                            label="Delivered"
                            value="delivered"
                        />
                    </Tabs>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "center",
                        m: 2,
                    }}
                >
                    {items.length === 0 && !isLoading ? (
                        <Typography
                            variant="h6"
                            color={"secondary"}
                            textAlign={"center"}
                        >
                            No Orders!
                        </Typography>
                    ) : (
                        ""
                    )}
                    <OrderTable data={items} isLoading={isLoading} />
                </Box>
            </Box>
        </div>
    );
};

export default Orders;
