import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import state from "./state";
import reducers from "./reducers";
import selectors from "./selectors";
import { orderProvider } from "../../../provider/firebase";
import { PaymentMethod } from "../../../utils/orderUtils";

// Async action for saving user data
const asyncThunks = {
    addBulkOrder: createAsyncThunk(
        "order/addBulkOrder",
        async (_arg, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    cart: state.cart?.cart,
                    user: state.user?.currentUser,
                    orderPayment: state.order?.paymentMethod,
                    deliveryLocation:
                        state.user?.currentUser?.registered_location,
                    deliveryDate: new Date(),
                };
                const result = await orderProvider.addBulkOrder(payload);
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    addWorkerBulkOrder: createAsyncThunk(
        "order/addWorkerBulkOrder",
        async (payload: any) => {
            try {
                const finalPayload = {
                    cart: payload?.cart,
                    user: payload?.vendor,
                    orderPayment: PaymentMethod.Cash,
                    deliveryLocation: payload?.vendor?.registered_location,
                    deliveryDate: payload?.deliveryDate,
                };
                const result = await orderProvider.addBulkOrder(finalPayload);
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateWorkerBulkOrder: createAsyncThunk(
        "order/updateWorkerBulkOrder",
        async (payload: any, { getState }) => {
            try {
                const state: any = getState();
                const finalPayload = {
                    order: payload?.order?.id,
                    user: state.user?.currentUser,
                    cart: payload?.cart,
                };
                const result = await orderProvider.updateWorkerBulkOrder(
                    finalPayload
                );
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    fetchUserBulkOrders: createAsyncThunk(
        "order/fetchUserBulkOrders",
        async (orderType: any, { getState }) => {
            try {
                const state: any = getState();

                let result: any[] = [];

                if (orderType === "ongoing") {
                    result = await orderProvider.fetchUserOnGoingBulkOrders(
                        state.user.currentUser
                    );
                } else if (orderType === "shipped") {
                    result = await orderProvider.fetchUserShippedBulkOrders(
                        state.user.currentUser
                    );
                } else if (orderType === "delivered") {
                    result = await orderProvider.fetchUserDeliveredBulkOrders(
                        state.user.currentUser
                    );
                }

                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    subscribeWorkerToOrders: createAsyncThunk(
        "order/subscribeWorkerToOrders",
        async (payload: any, { getState, dispatch }) => {
            try {
                const state: any = getState();

                let result: any[] = [];
                await orderProvider.syncWorkerOrders(payload, dispatch);
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    subscribeDriverToOrders: createAsyncThunk(
        "order/subscribeDriverToOrders",
        async (orderType: any, { getState, dispatch }) => {
            try {
                let result: any[] = [];
                await orderProvider.syncDriverOrders(
                    orderType,
                    dispatch,
                    getState
                );
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateOrderItemSourcedWorker: createAsyncThunk(
        "order/updateOrderItemSourcedWorker",
        async (payload: any, { getState }) => {
            try {
                const state: any = getState();
                const payloadObject = {
                    ...payload,
                    user: state?.user?.currentUser,
                };
                let result: any[] = [];
                await orderProvider.updateOrderItemSourceCheckbox(
                    payloadObject
                );
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateOrderAllItemsSourceCheckbox: createAsyncThunk(
        "order/updateOrderAllItemsSourceCheckbox",
        async (payload: any, { getState }) => {
            try {
                const state: any = getState();
                const payloadObject = {
                    ...payload,
                    user: state?.user?.currentUser,
                };
                let result: any[] = [];
                await orderProvider.updateOrderAllItemsSourceCheckbox(
                    payloadObject
                );
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    syncWorkerSelectedOrder: createAsyncThunk(
        "order/syncWorkerSelectedOrder",
        async (orderId: any, { dispatch }) => {
            try {
                await orderProvider.syncSelectedOrder(orderId, dispatch);
                return { isError: false, message: "Synced" };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    unsubscribeWorkerSelectedOrder: createAsyncThunk(
        "order/unsubscribeWorkerSelectedOrder",
        async (_arg: any, { dispatch }) => {
            try {
                await orderProvider.unsubscribeSelectedOrder(dispatch);
                return { isError: false, message: "Synced" };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    fetchLoadingOrder: createAsyncThunk(
        "order/fetchLoadingOrder",
        async (_arg, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    user: state.user.currentUser,
                };
                const result = await orderProvider.fetchLoadingOrder(payload);
                return { isError: false, message: "Loading Order", result };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    setLoadedItem: createAsyncThunk(
        "order/setLoadedItem",
        async (loadingPayload: any, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    user: state.user.currentUser,
                    selectedDriver: loadingPayload?.selectedDriver,
                    updatedItems: loadingPayload?.updatedItems,
                };
                if (!payload.selectedDriver) {
                    return { isError: true, message: "Please select driver !" };
                }
                if (!payload.user) {
                    return { isError: true, message: "Error Occured !" };
                }

                const result = await orderProvider.setLoadedOrders(payload);
                return {
                    isError: false,
                    message: "Success Loading Order",
                    result,
                };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    fetchPurchaseItems: createAsyncThunk(
        "order/fetchPurchaseItems",
        async (deliveryDate: Date, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    user: state.user.currentUser,
                    deliveryDate: deliveryDate.toUTCString(),
                };
                const result = await orderProvider.fetchPurchaseItemsList(
                    payload
                );
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    fetchOrder: createAsyncThunk(
        "order/fetchOrder",
        async (orderId: string, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    user: state?.user?.currentUser,
                    orderId,
                };
                const result = await orderProvider.fetchOrder(payload);
                return { isError: false, message: "Successful!", result };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    clearVehicle: createAsyncThunk(
        "order/clearVehicle",
        async (driverId: string) => {
            try {
                await orderProvider.clearVehicle(driverId);
                return { isError: false, message: "Cleared Vehicle !" };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    completeOrder: createAsyncThunk(
        "order/completeOrder",
        async (payload: any, { getState }) => {
            try {
                await orderProvider.completeOrder(payload, getState);
                return { isError: false, message: "Order Completed !" };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateAdminOrderDetails: createAsyncThunk(
        "order/updateAdminOrderDetails",
        async (payload: any, { getState }) => {
            try {
                await orderProvider.updateAdminOrderDetails(payload, getState);
                return { isError: false, message: "Order Updated !" };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateAdminAdvanceUpdateOrderDetails: createAsyncThunk(
        "order/updateAdminAdvanceUpdateOrderDetails",
        async (payload: any, { getState }) => {
            try {
                await orderProvider.updateAdminAdvanceUpdateOrderDetails(
                    payload,
                    getState
                );
                return {
                    isError: false,
                    message: "Order Advance mode Updated !",
                };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    fetchOrderInvoice: createAsyncThunk(
        "order/fetchOrderInvoice",
        async (orderId: any) => {
            try {
                const result = await orderProvider.fetchOrderInvoice(orderId);

                return { isError: false, message: "Invoice", result };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
};

const orderSlice = createSlice({
    // Name of the slice
    name: "order",
    // State of the slice
    initialState: state,
    // Reducers of the slice
    reducers: reducers,
    selectors: selectors,
});

export const actions = { ...orderSlice.actions, ...asyncThunks };
export default orderSlice.reducer;
