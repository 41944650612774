const reducers = {
    setMasterCrateList: (state: any, action: any) => {
        state.crateList = action.payload;
    },
    setCrateStatistics: (state: any, action: any) => {
        state.crateStatistics = action.payload;
    },
};

export default { ...reducers };
