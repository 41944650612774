import selectors from "./selectors";
import state from "./state";
import { actions } from "./configSlice";

const configSlice = {
    ...selectors,
    ...actions,
    ...state,
};
export default configSlice;
