import {
    Badge,
    BadgeProps,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import itemSlice from "../../store/slices/item";
import { customToast } from "../public_components/Toast/CustomToast";

import SearchIcon from "@mui/icons-material/Search";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import userSlice from "../../store/slices/user";
import orderSlice from "../../store/slices/order";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";
import { ItemState } from "../../utils/itemUtils";
import CustomDatePicker from "../public_components/datePicker/CustomDatePicker";
import readXlsxFile from "read-excel-file";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const ItemBlock = ({ currentItem, startPrice, handleQuantity }: any) => {
    const [newPrice, setNewPrice] = useState<number>(startPrice);

    useEffect(() => {
        setNewPrice(startPrice);
    }, [startPrice]);

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        "& .MuiBadge-badge": {
            right: -20,
            top: 20,
            padding: "0 4px",
        },
    }));

    return (
        <Card
            sx={{
                display: "flex",
                m: 2,
                backgroundColor: "background.default",
            }}
            key={currentItem?.id}
        >
            <Box
                sx={{ display: "flex", flexDirection: "column" }}
                color={"secondary"}
            >
                <CardContent sx={{ pb: 3 }}>
                    <Typography variant="h6">
                        {currentItem?.name_si || "Item name Not Valid"}
                    </Typography>
                    <Typography variant="caption">
                        Bulk Price LKR {currentItem?.price}
                    </Typography>
                </CardContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        pl: 2,
                        pb: 2,
                        pr: 2,
                    }}
                >
                    <TextField
                        focused
                        size="small"
                        label="Quantity"
                        variant="outlined"
                        type="number"
                        color="info"
                        value={newPrice?.toString()}
                        onChange={(event) => {
                            if (+event.target.value < 0) {
                                setNewPrice(0);
                            } else {
                                setNewPrice(+event.target.value);
                                handleQuantity(
                                    currentItem,
                                    +event.target.value
                                );
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    {currentItem?.unit}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="caption">
                        Sub total{" "}
                        {Number.parseFloat(
                            (currentItem?.price * newPrice).toFixed(2)
                        )}
                    </Typography>
                </Box>
            </Box>
            <StyledBadge
                badgeContent={""}
                color={
                    currentItem?.available === ItemState.InStock
                        ? "success"
                        : "error"
                }
            ></StyledBadge>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={currentItem?.image}
                alt="Item image"
            />
        </Card>
    );
};

export function BasicTable({ rows }: any) {
    return (
        <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table
                sx={{ minWidth: 50, maxHeight: 50 }}
                aria-label="simple table"
                size="small"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Unit Price (LKR)</TableCell>
                        <TableCell align="right">Sub total (LKR)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any) => (
                        <TableRow
                            key={row?.item?.id}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row?.item?.name}
                            </TableCell>
                            <TableCell align="right">
                                {row?.cartQuantity + " " + row?.item?.unit}
                            </TableCell>
                            <TableCell align="right">
                                {row?.item?.price}
                            </TableCell>
                            <TableCell align="right">
                                {Number.parseFloat(
                                    (
                                        row?.item?.price * row?.cartQuantity
                                    ).toFixed(2)
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const WorkerPlaceOrder = () => {
    const dispatch = useAppDispatch();
    const [itemsArray, setItemsArray] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedVendor, setSelectedVendor] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cartItems, setCartItems] = useState<any[]>([]);
    const [finalOrder, setFinalOrder] = useState<any[]>([]);
    const [orderRows, setOrderRows] = useState<any[]>([]);
    const [isConfirmeDialog, setIsConfirmeDialog] = useState<boolean>(false);
    const [deliveryDate, setDeliveryDate] = useState<Date | null>(() => {
        const date = new Date();
        date.setDate(date.getDate() + 2);
        return date;
    });

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            const items: any = await dispatch(
                itemSlice.fetchMasterInventoryItem()
            );
            const currentItemArray = [];
            for (const i of items.payload) {
                let itemPrice = i?.unit_price?.bulk_price;
                if (selectedVendor?.is_premium) {
                    itemPrice = i?.unit_price?.bulk_premium_price || 0;
                }
                const itemObject = {
                    available: i?.status,
                    category: i?.category,
                    id: i?.id,
                    image: i?.item_image_url,
                    name: i?.display_name?.en,
                    name_si: i?.display_name?.si,
                    item_sku: i?.item_sku,
                    price: itemPrice,
                    quantity: 0,
                    unit: i?.unit_size,
                };
                currentItemArray.push(itemObject);
            }
            const filteredArray = currentItemArray;

            console.log(currentItemArray);
            setItemsArray(filteredArray);
            setIsLoading(false);
        };
        fetch();
    }, [selectedVendor]);

    const searchVendor = async () => {
        if (searchTerm === "") {
            return;
        }
        setCartItems([]);
        setFinalOrder([]);
        setSelectedVendor(null);
        const result: any = (
            await dispatch(userSlice.fetchUserByUsername(searchTerm))
        )?.payload;
        const vendor = result?.result;
        if (vendor?.role === "vendor") {
            setSelectedVendor(vendor);
        }
    };

    function handleQuantity(item: any, value: any): void {
        if (value < 0) return;

        const cartItemsCopy = [...cartItems];
        const updatedItemIndex = cartItemsCopy.findIndex((curr: any) => {
            return curr?.item?.id === item?.id;
        });
        if (updatedItemIndex === -1) {
            setCartItems([...cartItems, { item, cartQuantity: value }]);
        } else {
            const currentItem = cartItemsCopy[updatedItemIndex];
            const updatedItem = {
                item: currentItem.item,
                cartQuantity: +value,
            };
            const updatedCart: any = [];
            for (const currentCartItem of cartItemsCopy) {
                if (currentCartItem.item.id === item.id) {
                    updatedCart.push(updatedItem);
                } else {
                    updatedCart.push(currentCartItem);
                }
            }
            setCartItems(updatedCart);
        }
    }

    async function placeOrder() {
        const finalOrderTemp = [];
        for (const item of cartItems) {
            if (item?.cartQuantity > 0) {
                finalOrderTemp.push(item);
            }
        }
        if (!selectedVendor) {
            customToast("No vendor selected", "warning");
            return;
        }
        if (finalOrderTemp.length === 0) {
            customToast("Empty Cart", "warning");
            return;
        }

        setFinalOrder(finalOrderTemp);
        setIsConfirmeDialog(true);
    }

    const clearOrder = () => {
        setCartItems([]);
        setFinalOrder([]);
    };
    const handleClose = () => {
        setIsConfirmeDialog(false);
    };
    const getTotalPrice = () => {
        let totalPrice = 0;
        for (const i of finalOrder) {
            const t: number = i?.cartQuantity * i?.item?.price;
            const totalItemPriceRouded = Number.parseFloat(t.toFixed(2));
            totalPrice += totalItemPriceRouded;
        }
        return Number.parseFloat(totalPrice.toFixed(2));
    };

    function matchAndCreateNewArray(array1: any, array2: any) {
        // Create a map of item_sku to item from array1 for faster lookup
        const skuMap = new Map(
            array1.map((item: any) => [item.item_sku, item])
        );

        // Create the new array by matching and mapping
        const newArray = array2
            .filter(
                (item: any) => item[0] != null && skuMap.has(item[0].toString())
            ) // Filter out null/undefined and unmatched items
            .map((item: any) => {
                const matchedItem: any = skuMap.get(item[0].toString());
                const itemObject = {
                    ...matchedItem,
                };
                return { item: itemObject, cartQuantity: item[3] ?? 0 };
            });

        return newArray;
    }

    const uploadPrices = async () => {
        if (orderRows.length > 0) {
            const x = matchAndCreateNewArray(itemsArray, orderRows);
            setCartItems(x);
        }
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            readXlsxFile(file)
                .then((rows) => {
                    setOrderRows(rows);
                })
                .catch((error) => {
                    console.error("Error reading file:", error);
                });
        }
    };

    const handleOrderComplete = async () => {
        setIsLoading(true);
        setIsConfirmeDialog(false);
        const result = await dispatch(
            orderSlice.addWorkerBulkOrder({
                cart: finalOrder,
                vendor: selectedVendor,
                deliveryDate: deliveryDate?.toUTCString(),
            })
        );

        if (result.payload.isError === true) {
            customToast(
                `Error Occured! ${result.payload?.message?.message || ""}`,
                "error"
            );
            if (
                result.payload?.message?.message?.startsWith(
                    "Price Mismatch Reorder the item"
                )
            ) {
                clearOrder();
            }
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        customToast(result?.payload?.message?.text, "success");
        setCartItems([]);
        setFinalOrder([]);
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                Place Order
            </Typography>
            <ConfirmPopup
                isOpen={isConfirmeDialog}
                handleClose={handleClose}
                text={
                    <Grid container>
                        <Grid item xs={12}>
                            <BasicTable rows={finalOrder} />
                        </Grid>
                        <Grid item xs={12} textAlign={"right"} mt={3}>
                            <Typography
                                textAlign={"right"}
                                variant="body1"
                                color={"secondary"}
                            >
                                Total Price: LKR {getTotalPrice()}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                heading={
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                textAlign={"left"}
                                variant="body1"
                                color={"secondary"}
                            >
                                Place Order for User ?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                textAlign={"left"}
                                variant="body1"
                                color={"secondary"}
                            >
                                {selectedVendor?.username}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography textAlign={"left"} variant="body1">
                                Delivery Date
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography textAlign={"right"} variant="body1">
                                {deliveryDate?.toLocaleDateString()}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                handleYes={handleOrderComplete}
            />
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                >
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            m: 2,
                        }}
                    >
                        <TextField
                            fullWidth
                            value={searchTerm}
                            placeholder={"Enter Vendor Username"}
                            focused
                            label="Username"
                            variant="outlined"
                            color="info"
                            onChange={(event) => {
                                setSearchTerm(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            color="info"
                                            size="small"
                                            sx={{
                                                borderRadius: 500,
                                                bgcolor: "secondary.main",
                                            }}
                                            onClick={searchVendor}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {searchTerm != "" ? (
                                            <IconButton
                                                size="small"
                                                color="info"
                                                sx={{
                                                    borderRadius: 500,
                                                    bgcolor: "error.main",
                                                }}
                                                onClick={() => {
                                                    setSearchTerm("");
                                                    setSelectedVendor(null);
                                                    clearOrder();
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        ) : null}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {selectedVendor ? (
                        <>
                            <Grid
                                container
                                sx={{
                                    justifyContent: "center",
                                    p: 2,
                                }}
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        color="text.primary"
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                        textAlign={"center"}
                                    >
                                        {selectedVendor?.is_premium
                                            ? "Selected Premium Vendor"
                                            : "Selected Vendor"}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Username"
                                        value={selectedVendor?.username}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="User ID"
                                        value={selectedVendor?.id}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Display Name"
                                        value={selectedVendor?.display_name}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Sort ID"
                                        value={selectedVendor?.sort_order_id}
                                        disabled
                                        InputProps={
                                            selectedVendor?.is_premium
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment position="start">
                                                              <VerifiedIcon
                                                                  sx={{
                                                                      color: "#FFD700",
                                                                  }}
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : {
                                                      endAdornment: (
                                                          <InputAdornment position="start">
                                                              <VerifiedIcon
                                                                  sx={{
                                                                      color: "#1DA1F2",
                                                                  }}
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomDatePicker
                                        label={"Delivery Date"}
                                        value={deliveryDate}
                                        onChange={(newValue: Date | null) =>
                                            setDeliveryDate(newValue)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign={"center"}>
                                    <TextField
                                        type="file"
                                        id="input"
                                        color="info"
                                        helperText="ORDER INPUT FILE"
                                        onChange={handleFileChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={uploadPrices}
                                                    >
                                                        <UploadFileIcon color="secondary" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={placeOrder}
                                    >
                                        Checkout Order
                                    </Button>
                                </Grid>
                            </Grid>

                            {itemsArray?.map((currentItem: any) => {
                                let quantity = 0;
                                for (const item of cartItems) {
                                    if (currentItem?.id === item?.item?.id) {
                                        quantity = item?.cartQuantity;
                                    }
                                }
                                return (
                                    <ItemBlock
                                        key={currentItem?.id}
                                        currentItem={currentItem}
                                        handleQuantity={handleQuantity}
                                        startPrice={quantity}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <Grid
                            container
                            sx={{
                                justifyContent: "center",
                                p: 1,
                            }}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="text.primary"
                                    sx={{
                                        fontWeight: 600,
                                    }}
                                    textAlign={"center"}
                                >
                                    No Vendor Selected
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Box>
    );
};

export default WorkerPlaceOrder;
