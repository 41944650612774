export enum ItemState {
    InStock = "in-stock",
    OutOfStock = "out-of-stock",
    Deleted = "deleted",
}
export enum ItemCategory {
    vegetable = "vegetable",
    fruit = "fruit",
    fish = "fish",
    spices = "spices",
    meat = "meat",
    rice = "rice",
    other = "other",
}
export enum ItemUnits {
    kg = "kg",
    g_250 = "250g",
    pieces = "pieces",
}

export function calculateFinalPriceOnInventoryItem(
    isInheriteEnabled: boolean,
    isOffsetEnabled: boolean,
    systemRetailPrice: number,
    offsetValue: number,
    inventoryPrice: number
) {
    let finalPrice = inventoryPrice;
    if (isInheriteEnabled) {
        finalPrice = systemRetailPrice;
    }
    if (isOffsetEnabled) {
        finalPrice += offsetValue;
    }
    if (finalPrice <= 0) {
        finalPrice = 0;
    }
    return finalPrice;
}
