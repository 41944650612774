import React, { useEffect, useState } from "react";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../hooks";
import orderSlice from "../../store/slices/order";
import { useNavigate } from "react-router-dom";
import userSlice from "../../store/slices/user";
import { customToast } from "../public_components/Toast/CustomToast";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";

const OrderItem = ({
    item,
    isLoading,
    handleCheckBox,
    handleBagCount,
}: any) => {
    if (isLoading) {
        return <CircularProgress color="secondary" />;
    }

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
        >
            <Grid
                container
                sx={{
                    p: 2,
                }}
            >
                <Grid
                    item
                    xs={2}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <Checkbox
                        color="secondary"
                        checked={item.is_loaded}
                        disabled={item.no_of_bags <= 0}
                        onChange={(event) => {
                            handleCheckBox(item.id, event.target.checked);
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {item.sort_order_id}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>{item.distance.toFixed(2)}km</strong>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={5}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <TextField
                        focused
                        fullWidth
                        size="small"
                        color="secondary"
                        value={item.no_of_bags.toString()}
                        type="number"
                        onChange={(event) => {
                            handleBagCount(item.id, +event.target.value);
                        }}
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

// Todo: Add price Update on load
const WorkerLoadingPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const driverDocArray = useAppSelector(userSlice.selectDriverArray);
    const [items, setItems] = useState<any[]>([]);
    const [updatedItems, setUpdatedItems] = useState<any[]>([]);
    const [driverArray, setDriverArray] = useState<any[]>([]);
    const [driverArrayLabel, setDriverArrayLabel] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isConfirmeDialog, setIsConfirmeDialog] = useState<boolean>(false);
    const [selectedDriver, setSelectedDriver] = useState<any>(null);
    const [currentDriverLable, setCurrentDriverLable] = useState<any>(null);
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        if (driverDocArray) {
            const driverTempArray = driverDocArray.map((item: any) => {
                if (item?.vehicle_number) {
                    const arrayObject = {
                        ...item,
                        label: item?.vehicle_number,
                        is_ready: item?.is_ready,
                    };
                    return arrayObject;
                }
            });
            const driverTempArrayLabel = driverDocArray.map((item: any) => {
                if (item?.vehicle_number) {
                    const arrayObject = {
                        label: item?.vehicle_number,
                    };
                    return arrayObject;
                }
            });
            setDriverArray(driverTempArray);
            setDriverArrayLabel(driverTempArrayLabel);
        }
    }, [driverDocArray]);
    useEffect(() => {
        handleDriverSelect();
    }, [driverArray]);
    useEffect(() => {
        const loadArray = [];
        for (const item of items) {
            loadArray.push({
                id: item?.orderId,
                sort_order_id: item?.sort_order_id,
                distance: item?.distance,
                is_loaded: false,
                no_of_bags: 0,
            });
        }
        setUpdatedItems(loadArray);
    }, [items]);

    const loadData = async () => {
        setIsLoading(true);
        await fetchDrivers();
        await fetchOrders();
        setIsLoading(false);
    };

    const fetchDrivers = async () => {
        await dispatch(userSlice.fetchDrivers());
    };

    const fetchOrders = async () => {
        const result: any = await dispatch(orderSlice.fetchLoadingOrder());
        setItems(result?.payload?.result?.data.data);
    };

    const handleDriverSelect = () => {
        const filter = driverArray.filter((item: any) => {
            return item?.vehicle_number === currentDriverLable;
        });
        setSelectedDriver(filter[0]);
    };

    useEffect(() => {
        handleDriverSelect();
    }, [currentDriverLable]);

    function handleBagCount(id: string, value: any) {
        if (value < 0) {
            return;
        }
        const loadArray = [];
        for (const item of updatedItems) {
            if (item.id === id) {
                loadArray.push({
                    id: item?.id,
                    sort_order_id: item?.sort_order_id,
                    distance: item?.distance,
                    is_loaded: item?.is_loaded,
                    no_of_bags: value,
                });
            } else {
                loadArray.push(item);
            }
        }
        setUpdatedItems(loadArray);
    }
    function handleCheckBox(id: string, value: any) {
        const loadArray = [];
        for (const item of updatedItems) {
            if (item.id === id) {
                loadArray.push({
                    id: item?.id,
                    sort_order_id: item?.sort_order_id,
                    distance: item?.distance,
                    is_loaded: value,
                    no_of_bags: item?.no_of_bags,
                });
            } else {
                loadArray.push(item);
            }
        }
        setUpdatedItems(loadArray);
    }
    async function handleCompleteLoading() {
        setIsLoading(true);
        const result: any = await dispatch(
            orderSlice.setLoadedItem({ updatedItems, selectedDriver })
        );

        setIsLoading(false);
        if (result.payload.isError) {
            customToast(result.payload.message, "error");
            return;
        } else {
            // Navigate to worker orders
            customToast(result.payload.message, "success");
            navigate("/worker/orders");
        }
    }

    const handleClearVehicle = async () => {
        setIsConfirmeDialog(false);
        const result: any = await dispatch(
            orderSlice.clearVehicle(selectedDriver?.id)
        );
        if (result?.payload?.isError === false) {
            customToast(result?.payload?.message, "warning");
            await loadData();
        } else {
            customToast(result?.payload?.message, "error");
        }
    };

    const handleClose = () => {
        setIsConfirmeDialog(false);
    };

    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                {selectedDriver
                    ? `Now Loading ${selectedDriver?.vehicle_number}`
                    : "Loading Orders"}
            </Typography>
            <div>
                <Box
                    sx={{
                        backgroundColor: "background.paper",
                        minHeight: "90vh",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                            m: 3,
                            pb: 10,
                        }}
                    >
                        {selectedDriver?.is_ready === false ? (
                            <Alert
                                severity="warning"
                                variant="standard"
                                sx={{ mb: 3 }}
                                action={
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            setIsConfirmeDialog(true);
                                        }}
                                    >
                                        clear vehicle
                                    </Button>
                                }
                            >
                                This Vehicle is loaded !
                            </Alert>
                        ) : null}
                        <Button
                            color="success"
                            variant="contained"
                            onClick={handleCompleteLoading}
                        >
                            Complete Loading
                        </Button>
                        <Autocomplete
                            disablePortal
                            sx={{ mt: 3, mb: 3 }}
                            id="combo-box-demo"
                            options={driverArrayLabel}
                            onInputChange={(event, newInputValue) => {
                                setCurrentDriverLable(newInputValue);
                            }}
                            clearIcon={<CloseIcon color="secondary" />}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Driver"
                                        variant="outlined"
                                        color={"secondary"}
                                        fullWidth
                                    />
                                );
                            }}
                        />
                        <ConfirmPopup
                            isOpen={isConfirmeDialog}
                            handleClose={handleClose}
                            text={`Do you want to clear ${selectedDriver?.vehicle_number} loading from the system ?`}
                            heading={"Clear vehicle loading order ?"}
                            handleYes={handleClearVehicle}
                        />
                        {isLoading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    m: 3,
                                }}
                            >
                                <CircularProgress color="secondary" />
                            </Box>
                        ) : items.length === 0 && !isLoading ? (
                            <Typography
                                variant="h6"
                                color={"secondary"}
                                textAlign={"center"}
                            >
                                No Orders!
                            </Typography>
                        ) : (
                            updatedItems.map((row: any, index: any) => {
                                return (
                                    <OrderItem
                                        key={index}
                                        item={row}
                                        isLoading={isLoading}
                                        handleCheckBox={handleCheckBox}
                                        handleBagCount={handleBagCount}
                                    />
                                );
                            })
                        )}
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default WorkerLoadingPage;
