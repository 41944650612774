import selectors from "./selectors";
import state from "./state";
import { actions } from "./orderSlice";

const orderSlice = {
    ...selectors,
    ...actions,
    ...state,
};
export default orderSlice;
