import { doc, getDoc } from "firebase/firestore";
import { configCollection } from "../provider/firebase";

export enum OrderStatus {
    Pending = "pending",        // Status when order is placed
    Processing = "processing",  // Status when order Sourcing started
    Ready = "ready",            // Order Items are sourced and Sorted to be shipped
    shipped = "shipped",        // Shipped out from source
    Canceled = "canceled",      
    OutForDelivery = "out-for-delivery",    // Out for delivery from last-mile hub
    Delivered = "delivered",    // Order completed
    Deleted = "deleted",        // Order Deleted ( by the system )
}
export enum PaymentStatus {
    Paid = "paid",
    AdvancePaid = "advance-paid",
    Pending = "pending",
    Canceled = "canceled",
}
export enum PaymentMethod {
    Cash = "cash",
    Bank = "bank-transfer",
}
export function generateSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
}

export async function isValidOrderTime(): Promise<{
    isBlocked: boolean;
    endTime: string;
    startTime: string;
}> {
    const configRef = doc(configCollection, "order");
    const configDoc = await getDoc(configRef);
    const configData = configDoc.data();
    if (configData?.is_ordering_restricted) {
        return {
            isBlocked: true,
            endTime: "00:00",
            startTime: "00:00",
        };
    }

    const startTime: string = configData?.restrict_time_range?.start;
    const endTime: string = configData?.restrict_time_range?.end;
    const now: Date = new Date();
    const start: Date = new Date();
    const end: Date = new Date();

    // Parse the given times as HH:mm strings
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    start.setHours(startHours, startMinutes, 0, 0);
    end.setHours(endHours, endMinutes, 0, 0);

    return {
        isBlocked: now >= start && now <= end,
        endTime,
        startTime,
    };
}

export function createOrderReceiptObject(order: any) {
    const receiptItemArray = [];
    let receipientTotal = 0;
    for (const item of order?.items) {
        const receiptItem = {
            itemName: item?.name,
            quantity: item?.quantity,
            unit: item?.unit,
            unitPrice: item?.unit_price,
            totalPrice: item?.total_item_price,
        };
        receipientTotal += receiptItem.totalPrice;
        if (
            Math.round(receiptItem?.unitPrice * receiptItem?.quantity) !==
            receiptItem?.totalPrice
        ) {
            return {
                isError: true,
                message: "Receipt Error: Subtotal Mismatch",
            };
        }
        receiptItemArray.push(receiptItem);
    }
    const textOrderObject = {
        orderId: order?.id,
        orderCreatedAt: order?.created_at?.toDate()?.toLocaleString(),
        recipient: order?.userData?.display_name,
        contact: order?.userData?.contact_number,
        email: order?.userData?.email,
        address: order?.end_location?.address,
        userType: order?.userData?.role,
        userName: order?.userData?.username,
        paymentStatus: order?.payment_status,
        orderStatus: order?.order_status,
        noOfBags: order?.no_of_bags,
        totalPrice: order?.total_price,
        advancePayment: order?.paid_amount,
        paymentId: order?.payment_order_id,
        paymentMethod: order?.payment_method,
        items: receiptItemArray,
    };

    if (textOrderObject?.totalPrice !== receipientTotal) {
        return { isError: true, message: "Receipt Error: Total mismatch" };
    }
    return { isError: false, message: "Success", textOrderObject };
}
